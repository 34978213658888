import {
  GET_CANDIDATE_LIST_REQUEST,
  GET_CANDIDATE_LIST_SUCCESS,
  GET_CANDIDATE_LIST_FAILURE,
  GET_CANDIDATE_COUNT_SUCCESS,
  EXPORT_CANDIDATE_LIST_REQUEST,
  EXPORT_CANDIDATE_LIST_SUCCESS,
  BULK_CANDIDATES_STATUS_UPDATE_REQUEST,
  BULK_CANDIDATES_STATUS_UPDATE_SUCCESS
} from "./type"

const initialState = {
  candidateData: false,
  requesting: false,
  candidateCount: {},
  exportRequesting: false
}

const candidateListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CANDIDATE_LIST_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_CANDIDATE_LIST_SUCCESS:
      return {
        ...state,
        requesting: false,
        candidateData: action.data
      }
    case GET_CANDIDATE_LIST_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_CANDIDATE_COUNT_SUCCESS:
      return {
        ...state,
        candidateCount: action.data
      }

    case EXPORT_CANDIDATE_LIST_REQUEST:
      return {
        ...state,
        exportRequesting: true
      }

    case EXPORT_CANDIDATE_LIST_SUCCESS:
      return {
        ...state,
        exportRequesting: false
      }

    case BULK_CANDIDATES_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        bulkUpdateRequesting: true
      }

    case BULK_CANDIDATES_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        bulkUpdateRequesting: false
      }

    default:
      return state
  }
}

export default candidateListReducer
