export const SCRUM_CERTIFICATIONS = [
    {
        "title": "Certified ScrumMaster (CSM)",
        "id": "CSM",
        "acronym": "CSM"
    },
    {
        "title": "Certified Scrum Professional-ScrumMaster (CSP-SM)",
        "id": "CSP-SM",
        "acronym": "CSP-SM"
    },
    {
        "title": "Advanced Certified ScrumMaster (A-CSM)",
        "id": "A-CSM",
        "acronym": "A-CSM"
    },
    {
        "title": "Certified Scrum Product Owner (CSPO)",
        "id": "CSPO",
        "acronym": "CSPO"
    },
    {
        "title": "Advanced Certified Scrum Product Owner (A-CSPO)",
        "id": "A-CSPO",
        "acronym": "A-CSPO"
    },
    {
        "title": "Certified Scrum Professional-Product Owner (CSP-PO)",
        "id": "CSP-PO",
        "acronym": "CSP-PO"
    },
    {
        "title": "Certified Scrum Developer (CSD)",
        "id": "CSD",
        "acronym": "CSD"
    },
    {
        "title": "Advanced Certified Scrum Developer (A-CSD)",
        "id": "A-CSD",
        "acronym": "A-CSD"
    },
    {
        "title": "Certified Scrum Professional - Developer (CSP-D)",
        "id": "CSP-D",
        "acronym": "CSP-D"
    },
    {
        "title": "Certified Agile Facilitator (CAF)",
        "id": "CAF",
        "acronym": "CAF"
    },
    {
        "title": "Certified Agile Leader 1 (CAL 1)",
        "id": "CAL-1",
        "acronym": "CAL-1"
    },
    {
        "title": "Certified Agile Leader 2 (CAL 2)",
        "id": "CAL-2",
        "acronym": "CAL-2"
    },
    {
        "title": "Certified Agile Scaling Practitioner (CASP)",
        "id": "CASP",
        "acronym": "CASP"
    }
]