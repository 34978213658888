import { CISCO_CERTIFICATIONS } from "./certifications/cisco"
import { DELL_CERTIFICATIONS } from "./certifications/dell"
import { JUNIPER_CERTIFICATIONS } from "./certifications/juniper"
import { MICROSOFT_CERTIFICATIONS } from "./certifications/microsoft"
import { ORACLE_CERTIFICATIONS } from "./certifications/oracle"
import { AWS_CERTIFICATIONS } from "./certifications/aws"
import { SAP_CERTIFICATIONS } from './certifications/sap'
import { SCRUM_CERTIFICATIONS } from "./certifications/scrum-alliance"
import { ICAGILE_CERTIFICATIONS } from "./certifications/icagile"
import { PMI_CERTIFICATIONS } from "./certifications/pmi"
import { COMPTIA_CERTIFICATIONS } from "./certifications/comptia"
import { ISACA_CERTIFICATIONS } from "./certifications/isaca"
import { SCRUM_ORG_CERTIFICATIONS } from "./certifications/scrum"

const ISTQB_CERTIFICATIONS = [
    {
      "title": "Certified Tester Foundation Level",
      "acronym": "CTFL"
    },
    {
      "title": "Test Analyst",
      "acronym": "CTAL-AT"
    },
    {
      "title": "Test Manager",
      "acronym": "CTAL-TM"
    },
    {
      "title": "Technical Test Analyst",
      "acronym": "CTAL-TA"
    },
    {
      "title": "Agile Test Leadership at Scale",
      "acronym": "ATLS"
    },
    {
      "title": "AI Testing",
      "acronym": "AIT"
    },
    {
      "title": "Game Testing",
      "acronym": "GT"
    },
    {
      "title": "Agile Tester (Foundation)",
      "acronym": "ATF"
    },
    {
      "title": "Agile Technical Tester (Advanced)",
      "acronym": "ATTA"
    },
    {
      "title": "Assessing Test Processes",
      "acronym": "ATP"
    },
    {
      "title": "Implementing Test Process Improvement",
      "acronym": "TPI"
    },
    {
      "title": "Strategic Test Management",
      "acronym": "STM"
    },
    {
      "title": "Operational Test Management",
      "acronym": "OTM"
    },
    {
      "title": "Managing the Test Team",
      "acronym": "MTT"
    }
  ]

const APM_CERTIFICATIONS = [
    {
      "title": "APM Project Fundamentals Qualification (PFQ)",
      "acronym": "PFQ"
    },
    {
      "title": "APM Project Management Qualification (PMQ)",
      "acronym": "PMQ"
    },
    {
      "title": "APM Project Professional Qualification (PPQ)",
      "acronym": "PPQ"
    },
    {
      "title": "Project Management Chartered Standard (ChPP)",
      "acronym": "ChPP"
    }
  ]

const PRINCE2_CERTIFICATIONS = [
    {
      "title": "PRINCE2 Foundation",
      "acronym": "PRINCE2-F"
    },
    {
      "title": "PRINCE2 Agile Foundation",
      "acronym": "PRINCE2-AF"
    },
    {
      "title": "PRINCE2 Practitioner",
      "acronym": "PRINCE2-P"
    },
    {
      "title": "PRINCE2 Agile Practitioner",
      "acronym": "PRINCE2-AP"
    }
  ]
const IPM_CERTIFICATIONS = [
    {
      "title": "Certified Project Management Diploma",
      "acronym": "CPMD"
    },
    {
      "title": "Strategic Project Programme Management Diploma",
      "acronym": "SPPMD"
    },
    {
      "title": "Project Leadership & Management Diploma",
      "acronym": "PLMD"
    }
  ]

const IAPM_CERTIFICATIONS = [
    {
      "title": "Certified Projects Director (IAPM)",
      "acronym": "CPD"
    },
    {
      "title": "Certified Junior Project Manager (IAPM)",
      "acronym": "JCPM"
    },
    {
      "title": "Certified Project Manager (IAPM)",
      "acronym": "CPM"
    },
    {
        "title": "Certified Senior Project Manager (IAPM)",
        "acronym": "CSPM"
    },
    {
      "title": "Certified Project Management Associate (IAPM)",
      "acronym": "CPMA"
    },
    {
      "title": "Certified Junior Agile Project Manager (IAPM)",
      "acronym": "CJAPM"
    },
    {
        "title": "Certified Agile Project Manager (IAPM)",
        "acronym": "CAPM"
    },
    {
      "title": "Certified Senior Agile Project Manager (IAPM)",
      "acronym": "CSAPM"
    },
    {
      "title": "Certified International Project Manager (IAPM)",
      "acronym": "CIPM"
    },
    {
      "title": "Certified Project Management Trainer (IAPM)",
      "acronym": "CPMT"
    }
]

const LKU_CERTIFICATIONS = [
    {
        "title": "Lean Kanban University (LKU) Kanban Management Professional (KMP)",
        "acronym": "LKU-KMP"
    }
]

const APMG_CERTIFICATIONS = [
    {
        "title": "Agile Project Management (AgilePM) Certification",
        "acronym": "AgilePM"
    }
]

const AGILE_CONSORTIUM_CERTIFICATIONS = [
    {
        "title": "Agile Business Consortium Agile Practitioner",
        "acronym": "ABC-AP"
    }
]

export const CERTIFICATIONS_LIST = CISCO_CERTIFICATIONS
                .concat(AWS_CERTIFICATIONS)
                .concat(DELL_CERTIFICATIONS)
                .concat(MICROSOFT_CERTIFICATIONS)
                .concat(JUNIPER_CERTIFICATIONS)
                .concat(ORACLE_CERTIFICATIONS)
                .concat(IAPM_CERTIFICATIONS)
                .concat(ICAGILE_CERTIFICATIONS)
                .concat(PRINCE2_CERTIFICATIONS)
                .concat(PMI_CERTIFICATIONS)
                .concat(IPM_CERTIFICATIONS)
                .concat(APM_CERTIFICATIONS)
                .concat(ISACA_CERTIFICATIONS)
                .concat(ISTQB_CERTIFICATIONS)
                .concat(SAP_CERTIFICATIONS)
                .concat(SCRUM_CERTIFICATIONS)
                .concat(LKU_CERTIFICATIONS)
                .concat(APMG_CERTIFICATIONS)
                .concat(AGILE_CONSORTIUM_CERTIFICATIONS)
                .concat(COMPTIA_CERTIFICATIONS)
                .concat(SCRUM_ORG_CERTIFICATIONS)
