export const ISACA_CERTIFICATIONS = [
    {
      "title": "Certified Information Systems Auditor",
      "acronym": "CISA"
    },
    {
      "title": "Certified Information Security Manager",
      "acronym": "CISM"
    },
    {
      "title": "Certified in Risk and Information Systems Control",
      "acronym": "CRISC"
    },
    {
      "title": "Certified Data Privacy Solutions Engineer",
      "acronym": "CDPSE"
    },
    {
      "title": "Certified in the Governance of Enterprise IT",
      "acronym": "CGEIT"
    },
    {
      "title": "CSX Cybersecurity Practitioner",
      "acronym": "CSX-P"
    },
    {
      "title": "Information Technology Certified Associate",
      "acronym": "ITCA"
    },
    {
      "title": "Certified in Emerging Technology",
      "acronym": "CET"
    },
    {
      "title": "IT Audit Fundamentals Certificate",
      "acronym": "IAFC"
    },
    {
      "title": "Cloud Fundamentals Certificate",
      "acronym": "CFC"
    },
    {
      "title": "IT Risk Fundamentals Certificate",
      "acronym": "IRFC"
    },
    {
      "title": "IT Risk Fundamentals Certificate",
      "acronym": "IRFC"
    },
    {
      "title": "Blockchain Fundamentals Certificate",
      "acronym": "BFC"
    },
    {
      "title": "Certificate of Cloud Auditing Knowledge",
      "acronym": "CCAK"
    },
    {
      "title": "IoT Fundamentals Certificate",
      "acronym": "IFC"
    },
    {
      "title": "Cybersecurity Audit Certificate",
      "acronym": "CAC"
    },
    {
      "title": "Artificial Intelligence Fundamentals Certificate",
      "acronym": "AIFC"
    },
    {
      "title": "Computing Fundamentals Certificate",
      "acronym": "CFC"
    },
    {
      "title": "COBIT Design and Implementation",
      "acronym": "CDI"
    },
    {
      "title": "Networks and Infrastructure Fundamentals",
      "acronym": "NIF"
    },
    {
      "title": "Networks and Infrastructure Fundamentals Certificate",
      "acronym": "NIFFC"
    },
    {
      "title": "Implementing the NIST Cybersecurity Framework Using COBIT 2019",
      "acronym": "ICFC"
    },
    {
      "title": "Cybersecurity Fundamentals Certificate",
      "acronym": "CFC"
    },
    {
      "title": "COBIT Foundation",
      "acronym": "CFR"
    },
    {
      "title": "Software Development Fundamentals Certificate",
      "acronym": "SDFC"
    },
    {
      "title": "COBIT 5 Certificates",
      "acronym": "COBIT 5"
    },
    {
      "title": "Data Science Fundamentals Certificate",
      "acronym": "DSFC"
    }
  ]