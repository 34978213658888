/*

Object.values(document.getElementsByClassName('z-30')).map((item) => {
    const h2s = item.getElementsByTagName('h2')
    if (h2s) {
        return {
            "title": h2s[1].textContent.replace('®', ''),
            "id": h2s[0].textContent.replace('®', '')
        }
    }
})

*/

export const PMI_CERTIFICATIONS = [
    {
        "title": "Certified Associate in Project Management (CAPM)",
        "id": "CAPM",
        "acronym": "CAPM"
    },
    {
        "title": "Project Management Professional (PMP)",
        "id": "PMP",
        "acronym": "PMP"
    },
    {
        "title": "Program Management Professional (PgMP)",
        "id": "PgMP",
        "acronym": "PgMP"
    },
    {
        "title": "Portfolio Management Professional (PfMP)",
        "id": "PfMP",
        "acronym": "PfMP"
    },
    {
        "title": "Certified Associate in Project Management (CAPM)",
        "id": "CAPM",
        "acronym": "CAPM"
    },
    {
        "title": "Project Management Professional (PMP)",
        "id": "PMP",
        "acronym": "PMP"
    },
    {
        "title": "Program Management Professional (PgMP)",
        "id": "PgMP",
        "acronym": "PgMP"
    },
    {
        "title": "Portfolio Management Professional (PfMP)",
        "id": "PfMP",
        "acronym": "PfMP"
    },
    {
        "title": "PMO Certified Practitioner (PMO-CP)™",
        "id": "PMO-CP™",
        "acronym": "PMO-CP™"
    },
    {
        "title": "PMI Agile Certified Practitioner (PMI-ACP)",
        "id": "PMI-ACP",
        "acronym": "PMI-ACP"
    },
    {
        "title": "PMI Risk Management Professional (PMI-RMP)",
        "id": "PMI-RMP",
        "acronym": "PMI-RMP"
    },
    {
        "title": "PMI Professional in Business Analysis (PMI-PBA)",
        "id": "PMI-PBA",
        "acronym": "PMI-PBA"
    },
    {
        "title": "PMI Construction Professional (PMI-CP)™",
        "id": "PMI-CP™",
        "acronym": "PMI-CP™"
    },
    {
        "title": "PMO Certified Practitioner (PMO-CP)™",
        "id": "PMO-CP™",
        "acronym": "PMO-CP™"
    },
    {
        "title": "PMI Agile Certified Practitioner (PMI-ACP)",
        "id": "PMI-ACP",
        "acronym": "PMI-ACP"
    },
    {
        "title": "PMI Risk Management Professional (PMI-RMP)",
        "id": "PMI-RMP",
        "acronym": "PMI-RMP"
    },
    {
        "title": "PMI Professional in Business Analysis (PMI-PBA)",
        "id": "PMI-PBA",
        "acronym": "PMI-PBA"
    },
    {
        "title": "PMI Construction Professional (PMI-CP)™",
        "id": "PMI-CP™",
        "acronym": "PMI-CP™"
    },
    {
        "title": "Disciplined Agile Scrum Master (DASM)",
        "acronym": "Disciplined Agile Scrum Master (DASM)"
    },
    {
        "title": "Disciplined Agile Scrum Master (DASM)",
        "acronym": "Disciplined Agile Scrum Master (DASM)"
    },
    {
        "title": "Disciplined Agile Value Stream Consultant (DAVSC) Certification",
        "acronym": "Disciplined Agile Value Stream Consultant (DAVSC) Certification"
    },
    {
        "title": "Disciplined Agile Coach (DAC) Certification",
        "acronym": "Disciplined Agile Coach (DAC) Certification"
    },
    {
        "title": "Agile Hybrid Project Pro",
        "acronym": "Agile Hybrid Project Pro"
    },
    {
        "title": "Citizen Developer Practitioner",
        "acronym": "Citizen Developer Practitioner"
    },
    {
        "title": "Citizen Developer Business Architect",
        "acronym": "Citizen Developer Business Architect"
    },
    {
        "title": "Organizational Transformation Foundation",
        "acronym": "Organizational Transformation Foundation"
    },
    {
        "title": "Organizational Transformation Implementation",
        "acronym": "Organizational Transformation Implementation"
    },
    {
        "title": "Organizational Transformation Orchestration",
        "acronym": "Organizational Transformation Orchestration"
    },
    {
        "title": "Agile Metrics Micro-Credential",
        "acronym": "Agile Metrics Micro-Credential"
    },
    {
        "title": "Built Environment Project Communication Pro",
        "acronym": "Built Environment Project Communication Pro"
    },
    {
        "title": "Built Environment Performance and Materials Management Pro",
        "acronym": "Built Environment Performance and Materials Management Pro"
    },
    {
        "title": "Built Environment Technology and Innovation Pro",
        "acronym": "Built Environment Technology and Innovation Pro"
    },
    {
        "title": "Value Stream Management Micro-Credential",
        "acronym": "Value Stream Management Micro-Credential"
    }
]