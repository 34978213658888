/*

from https://www.comptia.org/certifications

Object.values(document.getElementsByClassName('featured-certification_name')).map((item) => {
    return {
        title: item.textContent
    }
})

*/

export const COMPTIA_CERTIFICATIONS = [
    {
        "title": "CompTIA ITF+",
        "acronym": "ITF+"
    },
    {
        "title": "CompTIA Tech+",
        "acronym": "Tech+"
    },
    {
        "title": "CompTIA A+",
        "acronym": "A+"
    },
    {
        "title": "CompTIA Network+",
        "acronym": "Network+"
    },
    {
        "title": "CompTIA Security+",
        "acronym": "Security+"
    },
    {
        "title": "CompTIA Cloud+",
        "acronym": "Cloud+"
    },
    {
        "title": "CompTIA Linux+",
        "acronym": "Linux+"
    },
    {
        "title": "CompTIA Server+",
        "acronym": "Server+"
    },
    {
        "title": "CompTIA CySA+",
        "acronym": "CySA+"
    },
    {
        "title": "CompTIA CASP+",
        "acronym": "CASP+"
    },
    {
        "title": "CompTIA PenTest+",
        "acronym": "PenTest+"
    },
    {
        "title": "CompTIA Data+",
        "acronym": "Data+"
    },
    {
        "title": "CompTIA DataSys+",
        "acronym": "DataSys+"
    },
    {
        "title": "CompTIA DataX",
        "acronym": "DataX"
    },
    {
        "title": "CompTIA Project+",
        "acronym": "Project+"
    },
    {
        "title": "CompTIA Cloud Essentials+",
        "acronym": "Eseentials+"
    }
]