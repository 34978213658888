import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Popover from "@mui/material/Popover"
import React, { useEffect, useCallback, useState } from "react"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

import {
  getSelectedProfileRequest,
  postJobStatusRequest,
  getDownloadProfileRequest,
  getCandidateApplicationRequest
} from "./redux/action"
import { isArray } from "../../../utils/commonFunction"
import { jobStatus } from "../../../utils/constants"
import AvatarBadge from "../../../components/AvatarBadge"
import CandidateProfile from "./CandidateProfile"
import IconButton from "../../../components/IconButton"
import JobsApplied from "./JobsApplied"
import ConfirmModal from "../../../components/Modals/ConfirmModal"

import "./style.scss"
import { isRecruiter, isAdmin } from "../../../utils/helper"
import CallNotes from "./CallNotes"
import { useParams } from "react-router-dom/cjs/react-router-dom"

const SelectedProfile = props => {
  const {
    candidateInfo,
    getCandidateProfileInfo,
    postJobStatusDispatch,
    requesting,
    downloadProfileDispatch,
    downloadRequesting,
    userType,
    applicationInfo,
    getApplicationInfo
  } = props
  const { profileIdStr, applicationIdStr } = useParams()
  const personalInfo = isArray(candidateInfo?.profile_data)
    ? candidateInfo?.profile_data[0]
    : candidateInfo?.profile_data
  const history = useHistory()
  const { location } = history
  const jobId = location?.state?.jobId
  const jobData = location?.state?.jobData
  const [selectedItem, setSelectedItem] = useState("profile")
  const [showModal, setShowModal] = useState(false)

  const jobStatusKeyToLabel = {}
  jobStatus.forEach((status) => {
    jobStatusKeyToLabel[status?.key] = status?.label
  })

  const loadProfileData = useCallback(() => {
    getCandidateProfileInfo({candidate_id: profileIdStr})
  }, [getCandidateProfileInfo, profileIdStr])

  useEffect(() => loadProfileData(), [loadProfileData])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopUpClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "application-status-popover" : undefined

  const downloadProfile = () => {
    downloadProfileDispatch({candidate_id: profileIdStr})
  }

  const selectMenuItem = item => () => {
    setSelectedItem(item)
  }

  const updateApplicationStatus = actionData => () => {
    postJobStatusDispatch(actionData, updateStatusCb(actionData))
  }

  const downloadApplicationData = useCallback(() => {
    if (applicationIdStr) {
      getApplicationInfo({ application_id: applicationIdStr})
    }
  }, [applicationIdStr])

  useEffect(() => downloadApplicationData(), [downloadApplicationData])

  const updateStatusCb = (actionData) => () => {
    closeModal()
    applicationInfo.candidate_application_status = actionData.status
  }

  const handleStatusChange = status => () => {
    handlePopUpClose()
    setShowModal({
      show: true,
      action: updateApplicationStatus({
        status: status?.toLowerCase(),
        id: applicationInfo?._id
      }),
      confirmMessage: `Are you sure you want to update application status to "${status}"?`
    })
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const goBack = () => {
    history.goBack()
  }

  const canViewNotes = (userType) => isRecruiter(userType) || isAdmin(userType)
  return (
    <section className="selected-profile-section">
      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={downloadRequesting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="section-header">
        <div className="back-option" onClick={goBack}>
          <ChevronLeftIcon />
          &nbsp;Back
        </div>
        <div className="heading-div">
          <h3>
            <AvatarBadge
              name={
                applicationInfo?.candidate_name ||
                personalInfo?.name ||
                personalInfo?.email
              }
            />
            <span>
              {applicationInfo?.candidate_name ||
                personalInfo?.name ||
                personalInfo?.email}
            </span>
          </h3>
          <div className="action-btn-section">
            {selectedItem !== "jobs-applied" && Boolean(jobId) && (
              <>
                <div className="section-title">Application Status</div>
                <div className="profile-action">
                  <IconButton
                    className="border-button profile-action-btn"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClick}
                    label={`${applicationInfo?.candidate_application_status?? 'Action'}`}
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopUpClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                  >
                    {jobStatus.slice(1).map(status => {
                      return (
                        <div
                          key={status?.key}
                          className="option"
                          onClick={handleStatusChange(status?.key)}
                        >
                          {status?.label}
                        </div>
                      )
                    })}
                  </Popover>
                </div>
              </>
            )}
            <div className="download-profile">
              <IconButton
                className="border-button download-profile-btn"
                icon={<CloudDownloadOutlinedIcon />}
                onClick={downloadProfile}
                label="Download Resume"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-body">
        <div className="menu-section">
          <ul className="menu-list">
            <li
              onClick={selectMenuItem("profile")}
              className={selectedItem === "profile" ? "active" : ""}
            >
              Profile Information
            </li>
            <li
              onClick={selectMenuItem("jobs-applied")}
              className={selectedItem === "jobs-applied" ? "active" : ""}
            >
              Jobs Applied
            </li>
            {canViewNotes(userType) && (
              <li
                onClick={selectMenuItem("call-notes")}
                className={selectedItem === "call-notes" ? "active" : ""}
              >
                Call Notes
              </li>
            )}
          </ul>
        </div>

        <div className="content-section">
          {selectedItem === "profile" && <CandidateProfile jobData={jobData} />}
          {selectedItem === "jobs-applied" && (
            <JobsApplied applicationInfo={applicationInfo} />
          )}
          {canViewNotes(userType) && selectedItem === "call-notes" && (
            <CallNotes candidateId={profileIdStr} jobId={applicationInfo?.job_id} />
          )}
        </div>
      </div>
      <ConfirmModal
        showModal={showModal?.show}
        confirmText={showModal?.confirmMessage}
        loading={requesting}
        onCancel={closeModal}
        onConfirm={showModal?.action}
        handleOnClose={closeModal}
      />
    </section>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  userType: state.login.userType,
  candidateInfo: state.selectedProfile.candidateInfo,
  requesting: state.selectedProfile.requestingStatusUpdate,
  downloadRequesting: state.selectedProfile.downloadRequesting,
  applicationInfo: state.selectedProfile.applicationInfo
})

const mapDispatchToProps = dispatch => ({
  getCandidateProfileInfo: data => dispatch(getSelectedProfileRequest(data)),
  postJobStatusDispatch: (data, callback) =>
    dispatch(postJobStatusRequest(data, callback)),
  downloadProfileDispatch: data => dispatch(getDownloadProfileRequest(data)),
  getApplicationInfo: data => dispatch(getCandidateApplicationRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectedProfile)
