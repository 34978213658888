import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import React, { useEffect, useState } from "react"

import DataTable from "../../../components/DataTable"
import IconButton from "../../../components/IconButton"
import USearch from "../../../components/USearch"
import {
  getAllTeamMembers,
  displayAddMemberModal,
  deleteTeamMember
} from "./redux/action"
import AdminMemberModal from "../../../components/AuthModals/AdminMemberModal"
import DeleteConfirmModal from "../../../components/Modals/DeleteConfirmModal"
import dayjs from "dayjs"

import "./style.scss"
import { sortByKey } from "../../../utils/commonFunction"

const AdminTeamMembers = props => {
  const {
    getAllTeamMembers,
    toggleAddMemberModal,
    displayModal,
    usersData,
    requesting,
    deleteRequesting,
    deleteUserDispatch
  } = props
  const history = useHistory()
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [userToDelete, setUserToDelete] = useState({})
  const [searchParam, setSearchParam] = useState("")

  useEffect(() => {
    getAllTeamMembers({
      team_id: history?.location?.state?.id
    })
  }, [getAllTeamMembers, history])

  const columns = [
    {
      id: "email",
      label: "Email",
      width: "50%",
      sortable: true
    },
    {
      id: "date_joined",
      label: "Date",
      width: "30%",
      sortable: true,
      renderColumn: rowData => {
        return (
          <div className="date">
            {dayjs(rowData?.date_joined).format("DD/MM/YYYY")}
          </div>
        )
      }
    },
    {
      id: "actions",
      label: "Action",
      width: "20%",
      sortable: false,
      renderColumn: rowData => {
        return (
          <div className="job-actions">
            <DeleteOutlinedIcon
              onClick={handleUserDelete(rowData)}
              className="action-delete"
            />
          </div>
        )
      }
    }
  ]

  const handleAddMember = () => {
    toggleAddMemberModal(true)
  }

  const handleUserDelete = userData => () => {
    let data = {
      team_id: history?.location?.state?.id,
      email: userData?.email,
      team_name: history?.location?.state?.name
    }
    setDeleteModal(true)
    setUserToDelete(data)
  }

  const closeDeleteModal = () => {
    setDeleteModal(false)
    setUserToDelete({})
  }

  useEffect(() => {
    if (!deleteRequesting) {
      closeDeleteModal()
    }
  }, [deleteRequesting])

  const deleteUser = () => {
    deleteUserDispatch(userToDelete)
  }

  const handleSearch = searchTerm => {
    setSearchParam(searchTerm)
  }

  const filterUserData = data => {
    return (
      data?.email?.toLowerCase()?.includes(searchParam?.toLowerCase()) ||
      data?.username?.toLowerCase()?.includes(searchParam?.toLowerCase())
    )
  }

  const clientSideSort = ({ data, sortBy, sortDir }) => {
    return sortByKey(
      sortBy,
      sortDir === "asc" ? 1 : -1,
      data,
      sortBy === "date_joined" ? "date" : ""
    )
  }

  const userList = searchParam
    ? usersData?.data?.filter(filterUserData)
    : usersData?.data

  return (
    <>
      <div className="members-container">
        <div className="header">
          <div className="heading-div">
            <h3>{history?.location?.state?.name}</h3>
            <div className="button-wrapper">
              <IconButton
                label="Add Member"
                icon={<AddIcon />}
                className="primary-button"
                onClick={handleAddMember}
              />
            </div>
          </div>
          <USearch
            placeholder="Search for a member"
            onClick={handleSearch}
            initialValue={searchParam}
          />
        </div>
        <div className="member-section">
          <DataTable
            columns={columns}
            isClientSidePagination={true}
            data={requesting ? [] : userList}
            loading={requesting}
            totalRows={userList?.length}
            searchTerm={searchParam}
            setSearchTerm={setSearchParam}
            clientSideSort={clientSideSort}
          />
        </div>
      </div>
      <AdminMemberModal showMemberModal={displayModal} history={history} />
      <DeleteConfirmModal
        showModal={showDeleteModal}
        handleOnClose={closeDeleteModal}
        onCancel={closeDeleteModal}
        onConfirm={deleteUser}
        confirmText="Are you sure you want to delete this member?"
        itemName={`"${userToDelete?.email}"`}
        loading={deleteRequesting}
      />
    </>
  )
}

const mapStateToProps = state => ({
  usersData: state.adminTeamMembers.membersInfo,
  requesting: state.adminTeamMembers.requesting,
  error: state.adminTeamMembers.error,
  displayModal: state.adminTeamMembers.displayModal,
  deleteRequesting: state.adminTeamMembers.deleteRequesting
})
const mapDispatchToProps = dispatch => ({
  getAllTeamMembers: data => dispatch(getAllTeamMembers(data)),
  toggleAddMemberModal: data => dispatch(displayAddMemberModal(data)),
  deleteUserDispatch: data => dispatch(deleteTeamMember(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminTeamMembers)
