export const CISCO_CERTIFICATIONS = [
    {
        "title": "Cisco Certified Entry Networking Technician",
        "description": "The CCENT certification validates the ability to install, operate and troubleshoot a small enterprise branch network, including basic network security.\n\nThe CCENT certification validates the skills required for entry-level network support positions, the starting point for many successful careers in networking. CCENT certified professionals have the knowledge and skill to install, operate, and troubleshoot a small enterprise branch network, including basic network security.\n\nIt opens doors to a career in networking. Having your CCENT means you have what it takes to manage a small, enterprise branch network. A CCENT is your first step toward CCNA certification and will help you stand out from the crowd in entry-level positions.",
        "acronym": "CCENT",
        "company": "Cisco"
    },
    {
        "title": "Cisco Certified Technician (CCT)",
        "description": "Cisco Certified Technicians have the skills to diagnose, restore, repair, and replace critical Cisco networking and system devices at customer sites.\n\nCisco Certified Technicians have the skills to diagnose, restore, repair, and replace critical Cisco networking and system devices at customer sites. Technicians work closely with the Cisco Technical Assistance Center (TAC) to quickly and efficiently resolve support incidents. Cisco authorized training is available online and can be completed in multiple short sessions, enabling technicians to stay productive in the field. Cisco Certified Technician (CCT) certification is available in multiple technology tracks, providing an opportunity for Cisco support technicians to expand their area of expertise.",
        "acronym": "CCT",
        "company": "Cisco"
    },
    {
        "title": "CCT Data Center",
        "description": "The CCT Data Center certification focuses on the skills required for onsite support and maintenance of Cisco Unified Computing Systems and servers.\n\nCisco Certified Technician Data Center (CCT Data Center) certification focuses on the skills required for onsite support and maintenance of Cisco Unified Computing Systems and servers. Technicians in this area must be able to identify Cisco Unified Computing System components and servers, accessories, cabling and interfaces; understand the Cisco UCS and NX-OS operating modes and identify commonly-found software; and be able to use the Cisco Graphical User Interface to connect and service product components.",
        "acronym": "CCT Data Center",
        "company": "Cisco"
    },
    {
        "title": "CCT Routing & Switching",
        "description": "CCT Routing and Switching certification focuses on the skills required for onsite support and maintenance of Cisco routers, switches, and operating environments.\n\nCisco Certified Technican Routing and Switching (CCT Routing and Switching) certification focuses on the skills required for onsite support and maintenance of Cisco routers, switches, and operating environments. Technicians in this area must be able to identify Cisco router and switch models, accessories, cabling, and interfaces; understand the Cisco IOS Software operating modes and identify commonly found software; and be able to use the Cisco Command Line Interface (CLI) to connect and service products. Achieving CCT Routing and Switching certification is considered the best foundation for supporting other Cisco devices and systems.",
        "acronym": "CCT Routing & Switching",
        "company": "Cisco"
    },
    {
        "title": "CCDA",
        "company": "Cisco",
        "prerequisites": [
            "CCENT",
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "200-310 DESGN"
        ],
        "description": "Enterprise environments require networks designed for performance, availability, and scalability with the flexibility to meet rapidly evolving demands. To meet these challenges head on, skilled IT professionals are needed with up-to-date, fundamental network design skills. For network design engineers, system engineers, and sales engineers and individuals looking to build and validate Cisco network design fundamental knowledge the Cisco CCDA certification program focuses on design methodologies and objectives, addressing and routing protocols, and network expansion considerations within basic campus, data center, security, voice, and wireless networks.",
        "acronym": "CCDA"
    },
    {
        "title": "CCNA Cloud",
        "exams": [
            "210-451 CLDFND",
            "210-455 CLDADM"
        ],
        "description": "\nMany companies are embracing the Cloud to help them to be more agile, flexible, and effective at delivering better business outcomes. Today, the majority of companies are already using XaaS offerings, and by 2018, it's estimated that 78% of workloads will be processed through the cloud.\n\nThe CCNA Cloud certification is a job role focused certification and training program that helps Cloud engineers, Cloud Administrators, and Network Engineers to develop, advance, and validate their cloud skill set, and enables them to help their IT organization meet changing business demands from technology transitions.\n\nWith a CCNA Cloud certification, you will obtain the skills to perform entry-level provisioning and support of Cisco cloud solutions. Learn from the only company that has an end-to-end Cloud and Intercloud story.",
        "acronym": "CCNA Cloud",
        "company": "Cisco"
    },
    {
        "title": "CCNA Collaboration",
        "exams": [
            "210-060 CICD",
            "210-065 CIVND"
        ],
        "description": "CCNA Collaboration certification is for network video engineers, IP telephony and IP network engineers who want to develop and advance their collaboration and video skills in line with the convergence of voice, video, data and mobile applications.\n\nFor network video engineers, collaboration engineers, IP telephony and IP network engineers who want to develop and advance their collaboration and video skills in line with the convergence of voice, video, data and mobile applications, the Cisco CCNA Collaboration certification is a job-role focused training and certification program. It will allow you to maximize your investment in your education, and increase your professional value by giving you the skills to help your IT organization meet increased business demands resulting from these technology transitions.",
        "acronym": "CCNA Collaboration",
        "company": "Cisco"
    },
    {
        "title": "CCNA Cyber Ops",
        "exams": [
            "210-250 SECFND",
            "210-255 SECOPS"
        ],
        "description": "Today's organizations are challenged with rapidly detecting cybersecurity breaches and effectively responding to security incidents. Teams of people in Security Operations Centers (SOC’s) keep a vigilant eye on security systems, protecting their organizations by detecting and responding to cybersecurity threats.\n\nThe CCNA Cyber Ops certification prepares candidates to begin a career working with associate-level cybersecurity analysts within security operations centers.",
        "acronym": "CCNA Cyber Ops",
        "company": "Cisco"
    },
    {
        "title": "CCNA Data Center",
        "exams": [
            "200-150 DCICN",
            "200-155 DCICT"
        ],
        "description": "The data center has come a long way from its hardware- and database-centric roots. Data center professionals today need to understand how their field intersects with automation, cloud, software-defined technologies, and virtualization. Are you ready?\n\nAgility is the hallmark of today's successful data center. Built for rapid application deployment and supported by a highly elastic infrastructure, the data center has become core to businesses competing in our digital era. CCNA Data Center certification provides the confidence and nimbleness you need to install, configure, and maintain data center technology. Gain grounding in data center infrastructure, data center networking concepts and technologies, storage networking, unified computing, network virtualization, data center automation and orchestration, and Cisco Application Centric Infrastructure (ACI).\n\n",
        "acronym": "CCNA Data Center",
        "company": "Cisco"
    },
    {
        "title": "CCNA Industrial",
        "company": "Cisco",
        "prerequisites": [
            "Industrial Networking Specialist",
            "CCENT",
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "200-601 IMINS2"
        ],
        "description": "The Cisco Certified Network Associate Industrial (CCNA Industrial) certification is for plant administrators, control system engineers and traditional network engineers in the manufacturing, process control, and oil and gas industries, who will be involved with the convergence of IT and Industrial networks. This certification provides candidates the necessary skills to successfully implement and troubleshoot the most common industry standard protocols while leveraging best practices needed for todays connected networks.\n\nCombining theoretic knowledge with practical lab exercises, this curriculum provides the real-world skills that allow information technology (IT) and operational technology (OT) professionals to ensure that their current infrastructures are maximized while developing a converged platform for flexibility to support future business outcomes.\n\n",
        "acronym": "CCNA Industrial"
    },
    {
        "title": "CCNA Routing and Switching",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "200-125 CCNA",
            "100-105 ICND1",
            "200-105 ICND2"
        ],
        "description": "As Enterprises migrate toward controller based architectures, the role and skills required of a core network engineer are evolving and more vital than ever. To prepare for this network transition, the CCNA Routing and Switching certification will not only prepare you with the knowledge of foundational technologies, but ensure you stay relevant with skill sets needed for the adoption of next generation technologies.\n\n\n",
        "acronym": "CCNA Routing and Switching"
    },
    {
        "title": "CCNA Security",
        "company": "Cisco",
        "prerequisites": [
            "CCENT",
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "210-260 IINS"
        ],
        "description": "Cisco Certified Network Associate Security (CCNA Security) validates associate-level knowledge and skills required to secure Cisco networks. With a CCNA Security certification, a network professional demonstrates the skills required to develop a security infrastructure, recognize threats and vulnerabilities to networks, and mitigate security threats. The CCNA Security curriculum emphasizes core security technologies, the installation, troubleshooting and monitoring of network devices to maintain integrity, confidentiality and availability of data and devices, and competency in the technologies that Cisco uses in its security structure.",
        "acronym": "CCNA Security"
    },
    {
        "title": "CCNA Service Provider",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "640-875 SPNGN1",
            "640-878 SPNGN2"
        ],
        "description": "The CCNA Service Provider certification is for service provider network engineers, technicians, and support personnel who want to configure and implement robust baseline Cisco Service Provider IP next-generation networks.\n\nCisco Certified Network Associate Service Provider (CCNA SP) certification is for service provider network engineers, technicians and designers who focus on the latest in Service Provider industry core networking technologies and trends. With the ability to configure, implement, and troubleshoot baseline Cisco Service Provider Next-Generation networks, CCNA SP certified individuals deploy, maintain and improve carrier-grade network infrastructures.",
        "acronym": "CCNA Service Provider"
    },
    {
        "title": "CCNA Wireless",
        "company": "Cisco",
        "prerequisites": [
            "CCENT",
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "200-355 WIFUND"
        ],
        "description": "Cisco Wireless technology growth places increased demands on networks and the professionals that support them. Ensuring this technology is optimally configured, monitored, and supported is paramount to achieving business outcomes and requires a workforce of skilled wireless professionals. Earn the CCNA Wireless certification and amplify your basic Cisco Wireless LAN's configuration, monitoring, troubleshooting and support skills for optimal performance of Cisco Wireless networks.",
        "acronym": "CCNA Wireless"
    },
    {
        "title": "Cisco Certified Design Professional (CCDP)",
        "company": "Cisco",
        "prerequisites": [
            "CCIE",
            "CCDE",
            "CCDA",
            "CCNA Routing and Switching",
            "CCNP Routing and Switching"
        ],
        "exams": [
            "300-101 ROUTE",
            "300-115 SWITCH",
            "300-320 ARCH"
        ],
        "description": "The CCDP certification builds advanced knowledge of the network design concepts and principles required to develop multi-layer enterprise architectures and network components.\n\nEnterprise environments require networks designed for performance, availability and scalability to achieve outcomes. Seasoned IT professionals with progressive end-to-end network design expertise are crucial to ensure networks deliver to today's requirements while future proofing investments. For Senior Network Design Engineers, Principle System Engineer, Network/Solution Architects and CCDA professionals looking to build upon your fundamental Cisco network design expertise the Cisco CCDP certification program focuses on advanced addressing and routing protocols, WANs, services virtualization, and integration strategies for multi-layered Enterprise architectures.",
        "acronym": "CCDP"
    },
    {
        "title": "CCNP Cloud",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Cloud",
            "CCIE"
        ],
        "exams": [
            "300-460 CLDINF",
            "300-465 CLDDES",
            "300-470 CLDAUT",
            "300-475 CLDACI"
        ],
        "description": "Cloud adoption is driving new roles and responsibilities. Cloud engineers need the skills to work with private, public and hybrid cloud models, and leverage Intercloud solutions. The CCNP Cloud certification is a lab based training and certification program that is targeted at Cloud engineers, Cloud Administrators, Cloud Designers, and Architects working in Data Centers. This program delivers the knowledge and skills necessary to design, provision, automate and manage Cloud and Infrastructure-as-a-Service deployments. Learn from the only company that has an end-to-end Cloud and Intercloud story.",
        "acronym": "CCNP Cloud"
    },
    {
        "title": "CCNP Collaboration",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Collaboration",
            "CCIE",
            "CCDE",
            "CCIE Collaboration"
        ],
        "exams": [
            "300-070 CIPTV1",
            "300-075 CIPTV2",
            "300-080 CTCOLLAB",
            "300-085 CAPPS"
        ],
        "description": "For collaboration and unified communications network engineers who want develop advanced collaboration skills designing, deploying, configuring, and troubleshooting Cisco Collaboration and Unified communications applications, devices and networks, the Cisco CCNP Collaboration certification is a job-role focused training and certification program that will expand your skills and ability to deliver business value.\n\nCollaboration is becoming a critical necessity for business success and innovation. You can use your knowledge to lead the transformation and increase the effectiveness of your organizations collaboration experience.",
        "acronym": "CCNP Collaboration"
    },
    {
        "title": "CCNP Data Center",
        "company": "Cisco",
        "prerequisites": [
            "CCIE",
            "CCNA Data Center"
        ],
        "exams": [
            "300-175 DCUCI",
            "300-165 DCII",
            "300-170 DCVAI",
            "300-160 DCID",
            "300-180 DCIT"
        ],
        "description": "The CCNP Data Center certification and training program offers comprehensive certification and Professional-level skills focused on the data center solutions, technologies and best practices to design, implement, and manage a modern data center infrastructure.\n\nIT practitioners who are Cisco trained and certified are uniquely qualified for key roles in complex data center environments, with expertise utilizing technologies including policy-driven infrastructure, virtualization, automation and orchestration, unified computing, data center security, and integration of cloud initiatives. CCNP Data Center certified professionals are highly qualified for senior roles chartered with enabling digital business transformation initiatives.",
        "acronym": "CCNP Data Center"
    },
    {
        "title": "CCNP Routing and Switching",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "300-101 ROUTE",
            "300-115 SWITCH",
            "300-135 TSHOOT"
        ],
        "description": "Cisco Certified Network Professional (CCNP) Routing and Switching certification validates the ability to plan, implement, verify and troubleshoot local and wide-area enterprise networks and work collaboratively with specialists on advanced security, voice, wireless and video solutions. The CCNP Routing and Switching certification is appropriate for those with at least one year of networking experience who are ready to advance their skills and work independently on complex network solutions. Those who achieve CCNP Routing and Switching have demonstrated the skills required in enterprise roles such as network engineer, support engineer, systems engineer or network technician. The routing and switching protocol knowledge from this certification will provide a lasting foundation as these skills are equally relevant in the physical networks of today and the virtualized network functions of tomorrow.\n\n",
        "acronym": "CCNP Routing & Switching"
    },
    {
        "title": "CCNP Security",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Security",
            "CCIE"
        ],
        "exams": [
            "300-208 SISAS",
            "300-206 SENSS",
            "300-209 SIMOS",
            "300-210 SITCS"
        ],
        "description": "Cisco Certified Network Professional Security (CCNP Security) certification program is aligned specifically to the job role of the Cisco Network Security Engineer responsible for Security in Routers, Switches, Networking devices and appliances, as well as choosing, deploying, supporting and troubleshooting Firewalls, VPNS, and IDS/IPS solutions for their networking environments.\n\n",
        "acronym": "CCNP Security"
    },
    {
        "title": "CCNP Service Provider",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Service Provider",
            "CCIP",
            "CCIE"
        ],
        "exams": [
            "642-883 SPROUTE",
            "642-885 SPADVROUTE",
            "642-887 SPCORE",
            "642-889 SPEDGE"
        ],
        "description": "CCNP Service Provider is a certification for engineers required to deliver carrier-grade infrastructure and new managed services.\n\nThe Cisco Certified Network Professional Service Provider (CCNP Service Provider) certification is for service provider network engineers, systems engineers, and network specialists who are responsible for delivering a scalable carrier-grade infrastructure capable of rapid expansion to support ongoing introduction of new managed services and other customer requirements.\n",
        "acronym": "CCNP Service Provider"
    },
    {
        "title": "CCNP Wireless",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Wireless",
            "CCIE"
        ],
        "exams": [
            "300-360 WIDESIGN",
            "300-365 WIDEPLOY",
            "300-370 WITSHOOT",
            "300-375 WISECURE"
        ],
        "description": "CCNP Wireless certification addresses the need for designing, implementing, and securing Cisco Wireless networks for optimal performance. CCNP Wireless certification emphasizes wireless networking principles and theory. It also recognizes the expertise and technical acumen of wireless professionals who can assess and translate network business requirements into technical specifications that in turn, result in successful business outcomes.\n\n",
        "acronym": "CCNP Wireless"
    },
    {
        "title": "Cisco Certified Design Expert (CCDE)",
        "exams": [
            "CCDE Written Exam",
            "CCDE Practical Exam"
        ],
        "description": "The CCDE certification identifies networking professionals with expert-level knowledge and skills in network design.\n\nThe Cisco Certified Design Expert (CCDE) is for expert-level network design engineers, expert-level network leads of IT infrastructure teams, and expert-level network leads of architecture teams working in job roles that require them to translate business needs, budget, and operational constraints into the design of a converged solution. The CCDE curriculum prepares designers to develop design solutions at the infrastructure level for large customer networks. Network engineers holding an active CCDE certification are recognized for their expert-level knowledge and skills in network infrastructure design. The deep technical networking knowledge that a CCDE brings ensures that they are well qualified to address the most technically challenging network infrastructure design assignments.\n\n",
        "acronym": "CCDE",
        "company": "Cisco"
    },
    {
        "title": "CCIE Collaboration Training",
        "exams": [
            "CCIE Collaboration Written Exam",
            "CCIE Collaboration Lab Exam"
        ],
        "description": "The CCIE Collaboration certification is for Collaboration Architects, Unified Communications Architects, or Voice and Video Network Managers who are responsible for the design, implementation, and troubleshooting of complex collaboration solutions. The certification is an expert-level certification that ensures that you gain knowledge and experience in collaboration solutions integration and interoperation, configuration, and troubleshooting in complex networks and the challenges of video, mobility, and presence as the foundation for workplace collaboration solutions.\n\nOur expertise in providing voice, video, IM, presence, and call center solutions and training will prepare you to deploy collaboration systems and services that increase user productivity improve the experience for you customers and are adaptable and provide a seamless user experience.",
        "acronym": "CCIE Collaboration Training",
        "company": "Cisco"
    },
    {
        "title": "CCIE Data Center",
        "exams": [
            "CCIE Data Center Written Exam",
            "CCIE Data Center Lab Exam"
        ],
        "description": "Cisco CCIE Data Center certification validates the expert-level skills required for the planning, design, implementation and management of complex modern IT data center infrastructure.\n\nAdvanced skills are focused on Layer 2 and Layer 3 connectivity, fabric infrastructure, storage networking and compute, network services, and automation and orchestration. Knowledge of evolving technologies, and their impact on the architectural framework, deployment models, implementation, and operations–are included in CCIE certification.\n\nThe CCIE Data Center practitioner is uniquely qualified to play a leadership role in harnessing evolving technologies and deep domain expertise to meet business requirements that depend on an agile IT infrastructure. Professionals who achieve CCIE Data Center certification have demonstrated their technical skills at the highest level, using industry best practices.",
        "acronym": "CCIE Data Center",
        "company": "Cisco"
    },
    {
        "title": "CCIE Routing and Switching",
        "exams": [
            "CCIE Routing and Switching Written Exam",
            "CCIE Routing and Switching Lab Exam"
        ],
        "description": "Cisco Certified Internetwork Expert Routing and Switching (CCIE Routing and Switching) certifies the skills required of expert-level network engineers to plan, operate and troubleshoot complex, converged network infrastructure.",
        "acronym": "CCIE Routing and Switching",
        "company": "Cisco"
    },
    {
        "title": "CCIE Security",
        "exams": [
            "CCIE Security Written Exam",
            "CCIE Security Lab Exam"
        ],
        "description": "The Cisco Certified Internetwork Expert Security (CCIE Security) program recognizes security experts who have the knowledge and skills to architect, engineer, implement, troubleshoot, and support the full suite of Cisco security technologies and solutions using the latest industry best practices to secure systems and environments against modern security risks, threats, vulnerabilities, and requirements.\n\n",
        "acronym": "CCIE Security",
        "company": "Cisco"
    },
    {
        "title": "CCIE Service Provider",
        "exams": [
            "CCIE Service Provider Written Exam",
            "CCIE Service Provider Lab Exam"
        ],
        "description": "CCIE Service Provider certification is for expert-level network engineers who have the skills to build an extensible Service Provider infrastructure.\n",
        "acronym": "CCIE Service Provider",
        "company": "Cisco"
    },
    {
        "title": "CCIE Wireless",
        "exams": [
            "CCIE Wireless Written Exam",
            "CCIE Wireless Lab Exam"
        ],
        "description": "The Cisco Certified Internetwork Expert Wireless (CCIE Wireless) certification assesses and validates wireless expertise. Candidates who pass the CCIE Wireless certification exams demonstrate broad theoretical knowledge of wireless networking and a solid understanding of wireless local area networking (WLAN) technologies from Cisco, the market leader in WLAN technology.",
        "acronym": "CCIE Wireless",
        "company": "Cisco"
    },
    {
        "title": "The Cisco Certified Architect (CCAr)",
        "company": "Cisco",
        "prerequisites": [
            "CCDE"
        ],
        "exams": [
            "CCAr Board Exam"
        ],
        "description": "CCAr is the highest level of accreditation achievable within the Cisco certification program.\n\nThe Cisco Certified Architect (CCAr) certification is for senior network infrastructure architects who produce technical specifications for the network to support business objectives. The curriculum focuses on understanding the business strategy and translating it into technical infrastructure requirements.",
        "acronym": "CCAr"
    },
    {
        "title": "Cisco Unified Contact Center Enterprise Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "600-455 UCCED",
            "600-460 UCCEIS"
        ],
        "description": "Enterprise-level contact centers utilizing the Cisco Unified Contact Center Enterprise (CCE) solution require highly skilled support engineers to design, deploy, configure, and troubleshoot for maximum performance and minimal downtime. The Cisco Unified Contact Center Enterprise Specialist certification ensures that you have the expertise needed to enable optimal solution performance.\n\n",
        "acronym": "Cisco Unified Contact Center Enterprise Specialist"
    },
    {
        "title": "Cisco TelePresence Solutions Specialist",
        "company": "Cisco",
        "prerequisites": [
            "CCNA"
        ],
        "exams": [
            "700-070 IX5K"
        ],
        "description": "The Cisco TelePresence Solutions Specialist certification focuses on the job-performance needs of a midcareer voice specialist or networking engineer who seeks to specialize in the planning, design, implementation (PDI) and maintenance of Cisco TelePresence deployments. Tasks include assessing network paths for rich media, evaluating call-control design options and configuring interoperability functions.\n\n\n",
        "acronym": "Cisco TelePresence Solutions Specialist"
    },
    {
        "title": "Cisco Video Network Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [],
        "description": "The Cisco Video Network Specialist program enables traditional analog audio-visual professionals to extend their careers into the growing Video-over-IP network job role.\n\nProfessionals, who need Video-over-IP knowledge and skills, can benefit from Cisco's expertise in the field.\n\nCandidates will learn to deploy video end-points, set-up new users, and operate video networks. This certification validates an individual's knowledge of installing, operating and supporting networked video solutions and how to deploy and optimize the product features and benefits.\n\n",
        "acronym": "Cisco Video Network Specialist"
    },
    {
        "title": "Cisco Industrial Networking Specialist",
        "description": "The Cisco Industrial Networking Specialist certification is for information technology (IT) and operational technology (OT) professionals in the manufacturing, process control, and oil and gas industries, who will be involved with the implementation, operation, and support of networked industrial products and solutions. This certification ensures candidates have the foundational skills to manage and administer networked industrial control systems. It provides plant administrators, control system engineers and traditional network engineers with an understanding of the networking technologies needed in today's connected plants and enterprises.\n\nUnlike other industrial networking programs, this certification tests the hands-on skills through simulations as well as testing theoretical knowledge. This approach ensures that candidates are ready for real-world implementations.",
        "acronym": "Cisco Industrial Networking Specialist",
        "company": "Cisco"
    },
    {
        "title": "Cisco Network Programmability Developer Specialist",
        "exams": [
            "300-560 NPDEV"
        ],
        "description": "The Cisco Network Programmability Developer Specialist certification is for software programmers who focus on the development of the network applications layer, which can live in any of the Cisco provided programmable components, and will enable service provider, campus, and data center use cases. This certification and course develop the foundation skills needed to develop network applications in programmable environments such as Cisco's One Platform Kit (onePK), Open Daylight Controller (ODL), and Application Policy Infrastructure Controllers (APICs). Unlike SDN courses that focus on network theory, this certification adds to your practical networking understanding and utilizes Cisco's experience in WAN, data center, and campus environments to provide you with the skills you will need to become a network developer.\n\nCandidates should be able to program in Python, C, or other languages in an open networking environment prior to this certification.",
        "acronym": "Cisco Network Programmability Developer Specialist",
        "company": "Cisco"
    },
    {
        "title": "Cisco Network Programmability Design and Implementation Specialist",
        "company": "Cisco",
        "prerequisites": [
            "CCNP",
            "CCDP",
            "CCIE",
            "CCDE"
        ],
        "exams": [
            "300-550 NPDESI"
        ],
        "description": "The Cisco Network Programmability Design and Implementation Specialist addresses the evolving role of network engineers towards more programmability, automation and orchestration, enabling them to leverage the powerful level of abstraction provided by controller based architectures to create real added value.",
        "acronym": "Cisco Network Programmability Design and Implementation Specialist"
    },
    {
        "title": "Cisco IOS XR Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "644-906 IMTXR"
        ],
        "description": "The Cisco IOS XR Specialist certification is a certification for network engineers, who wish to obtain prescriptive information and experience using Cisco IOS XR. This certification validates the skills to implement, verify, and maintain core and edge technologies on Cisco carrier-class platforms running Cisco IOS XR.\n\nUnlike competitive offerings, the Cisco IOS XR Specialist certification provides service providers, enterprise companies, and partners a way to differentiate themselves and to show their prospective customer base the value in possessing staff with knowledge of Cisco IOS XR, e.g. the ability to utilize more advanced features, more successful and higher performing IT deployments, lower cost for network maintenance.",
        "acronym": "Cisco IOS XR Specialist"
    },
    {
        "title": "Cisco and NetApp FlexPod Design Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "500-170 FPDESIGN"
        ],
        "description": "The Cisco and NetApp FlexPod Design Specialist is for storage and data management professionals including systems engineers, field engineers, professional services consultants, and channel partners who are focused on FlexPod solution enablement.\n\nThis FlexPod certification is the first jointly-developed, global, multi-vendor technical certification that delivers in-depth knowledge of the tools and standards to assess performance characteristics and requirements of the FlexPod integrated solution.",
        "acronym": "Cisco and NetApp FlexPod Design Specialist"
    },
    {
        "title": "Cisco and NetApp FlexPod Implementation and Administration Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "500-171 FPIMPADM"
        ],
        "description": "The Cisco and NetApp FlexPod Implementation and Administration Specialist is for storage and data management professionals including systems engineers, field engineers, professional services consultants, and channel partners who are focused on FlexPod solution enablement.\n\nThis FlexPod certification is the first jointly-developed, global, multi-vendor technical certification that delivers in-depth knowledge of the tools and standards to assess performance characteristics and requirements of the FlexPod integrated solution.",
        "acronym": "Cisco and NetApp FlexPod Implementation and Administration Specialist"
    },
    {
        "title": "Cisco Cybersecurity Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "600-199 SCYBER"
        ],
        "description": "The Cisco Cybersecurity Specialist certification recognizes security professionals who have attained specialized in-depth expertise and proven knowledge in the essential areas of proactive cyber threat detection and mitigation.\n\nDesigned for professional security analysts and leveraging the features of Cisco and other network security products used today, the Cisco Cybersecurity Specialist certification focuses on the topics of event monitoring, security event/alarm/traffic analysis, and incident response.",
        "acronym": "Cisco Cybersecurity Specialist"
    },
    {
        "title": "Cisco Service Provider Mobility CDMA to LTE Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "600-211",
            "600-212"
        ],
        "description": "The Cisco Service Provider Mobility CDMA to LTE Specialist certification validates a Service Provider Network engineer's knowledge and skills required to understand technologies, components, architectures, and products that are found in Code Division Multiple Access (CDMA) packet core networks, specifically in the migration to Long-Term Evolution (LTE) Evolved Packet Systems (EPS), including Evolved Packet Core (EPC) networks and radio access networks (RANs).",
        "acronym": "Cisco Service Provider Mobility CDMA to LTE Specialist"
    },
    {
        "title": "Cisco Service Provider Mobility UMTS to LTE Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "600-210",
            "600-212"
        ],
        "description": "The Cisco Service Provider Mobility UMTS to LTE Specialist certification validates a Service Provider Network engineer's knowledge and skills required to understand technologies, components, architectures, and products that are found in Universal Mobile Telecommunications System (UMTS) packet core networks, specifically in the migration to Long-Term Evolution (LTE) Evolved Packet Systems (EPS), including Evolved Packet Core (EPC) networks and radio access networks (RANs).",
        "acronym": "Cisco Service Provider Mobility UMTS to LTE Specialist"
    },
    {
        "title": "Cisco Business Architecture Analyst",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "810-440 DTBAA"
        ],
        "description": "\nThe Cisco Business Architecture Analyst is a certification program that prepares IT and line-of-business professionals to build and strengthen the bridge between technology solutions and critical business needs. As the digital transformation of business accelerates, the discipline of Business Architecture becomes an important enabler and much-needed skill set of the IT professional. This certification is the first step in preparing candidates to serve as trusted advisors who can engage customers and lead them through their digital transformation.\n\nThis job role based certification will help candidates to:\n\nUnderstand the concepts of the four pillars of Business Architecture\nIdentify the roles of the transformative team, and how they work to a solution\nDefine and understand the business, priority, requirements, and capability\nFamiliarity with the concept of Enterprise Architecture, and the use of frameworks in context",
        "acronym": "Cisco Business Architecture Analyst"
    },
    {
        "title": "Cisco Business Architecture Specialist",
        "company": "Cisco",
        "prerequisites": [
            "Cisco Business Architecture Analyst"
        ],
        "exams": [
            "820-445 DTBAS"
        ],
        "description": "The Cisco Business Architecture Specialist is a certification program that picks up from the Business Architecture Analyst certification to continue your journey to becoming a full-fledged Business Architect. Beyond reinforcing foundational knowledge, this program builds the explicit skills and tools to model the business, empower stakeholders, and achieve the desired outcomes to further the digital transformation of the customer. Practice building rapport and credibility, fostering entrepreneurship, and implementing consultative approaches that help develop critical customer skills. This certification continues the candidate's journey toward Business Architect.\n\nThis job role based certification will help candidates to:\n\nAdopt a consultative and need-driven approach\nUnderstand and employ the Cisco Business Architecture Methodology\nDefine and determine the maturity level for the business\nBuild a business model that accurately describes a business\nIdentify internal and external business drivers",
        "acronym": "Cisco Business Architecture Specialist"
    },
    {
        "title": "Cisco Business Architecture Practitioner",
        "company": "Cisco",
        "prerequisites": [
            "Cisco Business Architecture Specialist"
        ],
        "exams": [
            "840-450 DTBAD"
        ],
        "description": "The Cisco Business Architecture Practitioner is a certification program that builds and extends the skills inherent in the Cisco Business Architect. Those who achieve this level of expertise will be able to fully lead a business engagement, developing a comprehensive roadmap, managing engagement artifacts, and effortlessly participating at all levels within an organization to build rapport and credibility. Business Architecture Practitioners will demonstrate mastery of the tools and techniques that are essential to successful transformative business engagements. This certification is the ultimate destination of the Cisco Business Architect.\n\nThis job role based certification will help candidates to:\n\nIdentify, define, and fulfill strategies to transform businesses\nProvide full architectures, solutions, and deliver on the vision and outcomes that are promised\nCreate, refine, and document business cases, roadmaps, and other plan artifacts that deliver on the desired outcomes\nBuild and lead a team to both develop and deliver on the promise of digital transformation of business outcomes",
        "acronym": "Cisco Business Architecture Practitioner"
    },
    {
        "title": "Cisco Business IT Roadmap Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "820-432 BTROAD"
        ],
        "description": "\nThe Cisco Business IT Roadmap Specialist is a certification program for sales professionals that engage in customer conversations designed to pinpoint a strategic view of customer needs, lead stakeholders to consensus decisions, and produce cross-architectural roadmaps. This certification will help candidates to become and succeed as a trusted advisor, to engage in and lead customers through comprehensive, relevant business discussions.\n\nThis job role based certification will help candidates to:\n\nUnderstand IT/business strategy and priorities\nProvide architectures, solutions, software and services\nDeliver cross-architecture initiative roadmap",
        "acronym": "Cisco Business IT Roadmap Specialist"
    },
    {
        "title": "Cisco Customer Success Manager Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "820-602 DTCSM"
        ],
        "description": "The Cisco Customer Success Manager Specialist certification provides a solid framework, and a core set of proven fundamental skills and knowledge to deliver immediate value to the vendor/customer relationship.\n\nTo help customers achieve the value and utility that they expect, and to realize the outcomes, it is increasingly crucial for a customer advocate to take responsibility and ensure that the deployment, adoption, and follow on use of the product/service deliver on the up front, agreed upon and purchased business outcome.",
        "acronym": "Cisco Customer Success Manager Specialist"
    }
]