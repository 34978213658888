import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import React, { useEffect, useState } from "react"

import { getAllTeamsData } from "./redux/action"
import TeamsModal from "../../../components/AuthModals/TeamsModal"
import USearch from "../../../components/USearch"
import {
  closeModal,
  teamRecordToBeDeleted,
  editTeamRecord
} from "./redux/action"
import DataTable from "../../../components/DataTable"
import IconButton from "../../../components/IconButton"

import "./style.scss"
import { sortByKey } from "../../../utils/commonFunction"

const AdminProfile = props => {
  const {
    requesting,
    teamsInfo,
    getAllTeamsData,
    showModal,
    dispatchToggleModal,
    teamRecordToBeDeleted,
    teamRecordToBeEdited,
    dispatchDelete,
    dispatchEdit
  } = props
  const [deleteFlag, setDeleteFlag] = useState(false)
  const [addFlag, setAddFlag] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const [searchParam, setSearchParam] = useState("")
  const history = useHistory()

  useEffect(() => {
    getAllTeamsData({})
  }, [getAllTeamsData])

  const columns = [
    {
      id: "name",
      label: "Team Name",
      width: "25%",
      sortable: true,
      renderColumn: rowData => {
        return (
          <span
            onClick={() => {
              handleTeamClick(rowData)
            }}
            className={`team-title-label`}
          >
            {rowData?.name}
          </span>
        )
      }
    },
    {
      id: "description",
      label: "Description",
      width: "20%",
      sortable: true,
      renderColumn: rowData => {
        return (
          <span onClick={() => {}} className={`team-description`}>
            {rowData?.description}
          </span>
        )
      }
    },
    {
      id: "industry",
      label: "Industry",
      width: "10%",
      sortable: true
    },
    {
      id: "total_members",
      label: "Members",
      width: "10%",
      sortable: true
    },
    {
      id: "actions",
      label: "Action",
      width: "10%",
      sortable: false,
      renderColumn: rowData => {
        return (
          <div className="job-actions">
            <EditOutlinedIcon
              onClick={() => editRecord(rowData)}
              className="action-edit"
            />
            <DeleteOutlinedIcon
              onClick={() => deleteRecord(rowData)}
              className="action-delete"
            />
          </div>
        )
      }
    }
  ]

  const clientSideSort = ({ data, sortBy, sortDir }) => {
    return sortByKey(sortBy, sortDir === "asc" ? 1 : -1, data)
  }

  const displayModal = () => {
    dispatchToggleModal(true)
    setDeleteFlag(false)
    setEditFlag(false)
    setAddFlag(true)
  }

  const deleteRecord = record => {
    dispatchToggleModal(true)
    setDeleteFlag(true)
    setAddFlag(false)
    setEditFlag(false)
    dispatchDelete(record)
  }

  const editRecord = record => {
    dispatchToggleModal(true)
    setDeleteFlag(false)
    setEditFlag(true)
    setAddFlag(false)
    dispatchEdit(record)
  }

  const handleTeamClick = record => {
    if (record) {
      history.push("/admin/teams/members", record)
    }
  }

  const handleSearch = searchTerm => {
    setSearchParam(searchTerm)
  }

  const filterTeam = team => {
    return searchParam
      ? team?.assignee &&
          team?.name?.toLowerCase()?.includes(searchParam?.toLowerCase())
      : team?.assignee
  }
  const teamList = teamsInfo?.data?.filter(filterTeam)

  return (
    <div className="teams-list-container">
      <div className="header">
        <div className="heading-div">
          <h3>Teams</h3>
          <div className="button-wrapper">
            <IconButton
              label="Add Team"
              icon={<AddIcon />}
              className="primary-button"
              onClick={displayModal}
            />
          </div>
        </div>
        <USearch
          placeholder="Search team"
          onClick={handleSearch}
          searchRequesting={searchParam && requesting}
          initialValue={searchParam}
        />
      </div>
      <div className="admin-table-main">
        {teamsInfo && (
          <div className="add-team-table">
            <DataTable
              columns={columns}
              data={requesting ? [] : teamList}
              loading={requesting}
              totalRows={teamList?.length}
              defaultRowsPerPage={10}
              isClientSidePagination={true}
              searchTerm={searchParam}
              setSearchTerm={setSearchParam}
              clientSideSort={clientSideSort}
            />
          </div>
        )}
      </div>
      <TeamsModal
        showTeamsModal={showModal}
        deleteTeamInfo={teamRecordToBeDeleted}
        editTeamInfo={editFlag ? teamRecordToBeEdited : ""}
        deleteFlag={deleteFlag}
        editFlag={editFlag}
        addFlag={addFlag}
        requesting={requesting}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  teamsInfo: state.adminTeams.teamsInfo,
  requesting: state.adminTeams.requesting,
  showModal: state.adminTeams.displayModal,
  teamRecordToBeDeleted: state.adminTeams.deleteTeam,
  teamRecordToBeEdited: state.adminTeams.editTeamRecord
})

const mapDispatchToProps = dispatch => ({
  getAllTeamsData: data => dispatch(getAllTeamsData(data)),
  dispatchToggleModal: data => dispatch(closeModal(data)),
  dispatchDelete: data => dispatch(teamRecordToBeDeleted(data)),
  dispatchEdit: data => dispatch(editTeamRecord(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile)
