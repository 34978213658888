export const ORACLE_CERTIFICATIONS = [
    {
        "exam": "Oracle CRM On Demand Essentials 1Z0-527",
        "title": "Oracle CRM On Demand",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:10",
        "acronym": "p_id:10"
    },
    {
        "exam": "Oracle Value Chain Planning: Demantra Demand Management 7 Essentials 1Z0-558",
        "title": "Demantra",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:11",
        "acronym": "p_id:11"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 General Ledger Essentials 1Z0-516",
        "title": "E-Business Suite Financial Management",
        "description": "\n\n\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:13",
        "acronym": "p_id:13"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Payables Essentials 1Z0-517",
        "title": "E-Business Suite Financial Management",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:14",
        "acronym": "p_id:14"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Receivables Essentials 1Z0-518",
        "title": "E-Business Suite Financial Management",
        "description": "\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:15",
        "acronym": "p_id:15"
    },
    {
        "exam": "Oracle E-Business Suite (EBS) R12 Human Capital Management Essentials 1Z0-548",
        "title": "E-Business Suite Human Capital Management",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:16",
        "acronym": "p_id:16"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Inventory Essentials 1Z0-519",
        "title": "E-Business Suite Manufacturing",
        "description": "\n\n\t\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork who are focused on selling and implementing Oracle E-Business Suite Supply Chain Management modules.  This exam targets the intermediate-level implementation team member. Up-to-date training and field experience are recommended.\n\n\tThe Oracle E-Business Suite 12 Supply Chain Certified Implementation Specialist: Oracle Inventory certification validates that you possess a functional foundation in E-Business Essentials. You'll develop the skills to access and navigate within the R12 E-Business Suite, enter data, retrieve information in the form of a query and access online help. Through preparation for the exam, you will also gain a basic understanding of the features, functions and benefits of Oracle Inventory.\n\tYou will be able to add items, enable lot control, enable serial control, enable dual unit of measure control, perform inventory transfers, perform inventory replenishment, perform a cycle, view on-hand quantities, create reservations and setup material statuses.\n\tOPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle E-Business Suite Supply Chain Management Specialization. Gain an edge in the job market by certifying your Oracle Inventory expertise.\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:23",
        "acronym": "p_id:23"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Order Management Essentials 1Z0-521",
        "title": "E-Business Suite Order Fulfillment",
        "description": "\n\t \n\tThe Oracle E-Business Suite 12 Supply Chain Certified Implementation Specialist: Oracle Order Management certification indicates a functional foundation in E-Business Essentials including skills such as how to access and navigate within the R12 E-Business Suite, how to enter data, retrieve information in the form of a query and access online help. Through exam preparation, you will gain the skills necessary to set up and manage the Oracle Order Management process – from sales order creation to autoinvoice process in Receivables. This exam targets the intermediate-level implementation team member. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork who are focused on selling and implementing Oracle E-Business Suite Supply Chain Management modules. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle E-Business Suite Supply Chain Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:24",
        "acronym": "p_id:24"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Purchasing Essentials 1Z0-520",
        "title": "E-Business Suite Procurement",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:17",
        "acronym": "p_id:17"
    },
    {
        "exam": "Oracle E-Business Suite (EBS) R12 Project Essentials 1Z0-511",
        "title": "E-Business Suite Project Management",
        "description": "\n\n\tThe Oracle E-Business Suite Project Certified Implementation Specialist is designed for individuals who possess a strong foundation and expertise in implementing Oracle E-Business Suite Project solutions. This certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists\n\n\tThis certification covers topics such as the fundamentals and implementation options of Project Foundation, Project Costing and Project Billing. You'll become certified to use a solution that supports the full lifecycle of project and portfolio management with a single, accurate view of all project-related activities. Industry leading functionality allows users to select the best portfolio of initiatives, execute projects in adherence with methodologies, assign the right global resources, proactively streamline project delivery and track profitability via accurate budgeting, forecasting and billings/chargebacks.\n\n\tThe Oracle E-Business Suite Project Specialization recognizes partner organizations that are proficient in selling, implementing and/or developing the Oracle EBS Projects modules. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.\n\tUp-to-date training and field experience are recommended. This certification qualifies as competency criteria for the Oracle EBS R12 Project Specialization. \n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:18",
        "acronym": "p_id:18"
    },
    {
        "exam": "Oracle EBS R12: Advanced Supply Chain Planning 1Z0-244",
        "title": "E-Business Suite Supply Chain Management",
        "description": "\n\t \n\n\n\n\tOracle EBS R12 Advanced Supply Chain Planning Certified Expert Consultants can help an organization evaluate, plan, configure and introduce the new planning environment into the organization. The ability to execute a plan is the difference between success and failure. Moving to an Advanced Planning System such as ASCP often enables a change in the planning process and allows a company to drive greater disciplines in other departments to get the most out of their planning process. \n\tOracle EBS R12 Advanced Supply Chain Planning Certified Expert Consultants can present an integrated set of services that can assist the customer in driving real value from the medium term decision making and make the best use of the supply chain resources. By adding a well-respected Oracle certification to your resume, you'll help yourself stand out in a competitive job market.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:19",
        "acronym": "p_id:19"
    },
    {
        "exam": "Oracle EBS R12: Install, Patch and Maintain Applications 1Z0-238",
        "title": "E-Business Suite Tools and Technology",
        "description": "\n\n\tThe Oracle E-Business Suite R12 Applications Database Administrator Certified Professional Certification is designed for individuals who possess a strong foundation and expertise in implementing Oracle E-Business Suite solutions.\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:21",
        "prerequisites": [
            "\n"
        ],
        "level": "Professional Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:21"
    },
    {
        "exam": "Oracle EBS R12: Install, Patch and Maintain Applications 1Z0-238",
        "title": "E-Business Suite Tools and Technology",
        "description": "\n\n\tThe Oracle E-Business Suite R12 Applications Database Administrator Certified Professional Certification is designed for individuals who possess a strong foundation and expertise in implementing Oracle E-Business Suite solutions.\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:21#tabs-2",
        "prerequisites": [
            "\n"
        ],
        "level": "Professional Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:21#tabs-2"
    },
    {
        "exam": "Oracle Advanced Controls Applications 2014 Essentials 1Z0-487",
        "title": "Oracle Fusion Governance, Risk and Compliance",
        "description": "\n\tThe Oracle Advanced Controls Applications 2014 Certified Implementation Specialist certification recognizes candidates that are proficient in selling, implementing and developing Oracle Advanced Controls solutions. As organizations strive to deal with increased regulatory and oversight pressures, and attempt to minimize the cost impact on daily business activities, they must align their approach and tools to address compliance and risk management in the most efficient and repeatable way.\n\tTopics covered in this exam include: Fusion GRC Set Up and Administration, Application Access Controls Governor, Transaction Controls Governor, Configuration Controls Governor and Preventive Controls Governor. Candidates who achieve this certification are differentiated in the marketplace through proven expertise.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification qualifies as competency criteria for the Oracle Fusion Governance, Risk, and Compliance 11g Applications Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:247",
        "acronym": "p_id:247"
    },
    {
        "exam": "Oracle Hyperion Data Relationship Management Essentials 1Z0-588",
        "title": "Data Relationship Management",
        "description": "\n\t \n\tThe Oracle Hyperion Data Relationship Management Specialization is for individuals focused on selling and implementing a Master Data Management software solution to build consistency within master data assets despite endless changes within the underlying transactional and analytical systems. The certification will cover such topics as: creating applications, working with hierarchies and nodes, defining properties, querying and comparing data, blending versions, formulas for derived properties and managing security.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification helps the OPN member's company qualify for the Oracle Master Data Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:35",
        "acronym": "p_id:35"
    },
    {
        "exam": "Oracle Hyperion Financial Management 11 Essentials 1Z0-532",
        "title": "Hyperion Financial Management",
        "description": "\n\t \n\tThe Oracle Hyperion Financial Management 11 Specialist Certification identifies professionals that are skilled in implementing solutions based on Oracle Hyperion Financial Management. The certification covers skills such as: creating applications using and EPMA and via the classic method, loading data, defining rules, working with shared services and defining reports. The exam targets the intermediate-level implementation team member. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on selling and implementing Oracle Business Intelligence technology. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle Business Intelligence Foundation Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:37",
        "acronym": "p_id:37"
    },
    {
        "exam": "Oracle Hyperion Planning 11 Essentials 1Z0-533",
        "title": "Planning",
        "description": "\n\t \n\tThe Oracle Hyperion Planning 11 Specialist Certification identifies professionals that are skilled in implementing solutions based on Oracle Hyperion Financial Management. The certification covers skills such as: creating Planning applications, setting up dimensions and account hierarchies, loading data, working with EPMA, defining forms, defining business rules and defining security. The exam targets the intermediate-level implementation team member. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on selling and implementing Oracle Business Intelligence technology. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle Business Intelligence Foundation Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:38",
        "acronym": "p_id:38"
    },
    {
        "exam": "JD Edwards EnterpriseOne Financial Management 9.2 Implementation Essentials 1Z0-342",
        "title": "JD Edwards Financial Management",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:478",
        "acronym": "p_id:478"
    },
    {
        "exam": "JD Edwards EnterpriseOne Distribution 9.2 Implementation Essentials 1Z0-343",
        "title": "JD Edwards Supply Chain Execution (Logistics)",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:475",
        "acronym": "p_id:475"
    },
    {
        "exam": "JD Edwards EnterpriseOne Configurable Network Computing 9.2 Implementation Essentials 1Z0-344",
        "title": "JD Edwards Tools and Technology",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:506",
        "acronym": "p_id:506"
    },
    {
        "exam": "Oracle Commerce 11 Guided Search Implementation Essentials 1Z0-446",
        "title": "Oracle Commerce Developer",
        "description": "\n\tOracle Commerce 11 Guided Search Certified Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in selling and implementing Oracle Commerce 11 Guided Search solutions. This certification covers topics such as: Architecture and Components, Guided Search Application Configuration, Record Design in Oracle Commerce 11.1 Guided Search, Pipeline Development, Search Features, Oracle Commerce 11 Experience Manager Concepts or Oracle Commerce 11 Experience Manager Implementation.\n\tThis certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification qualifies as competency criteria for the Oracle Commerce 11 Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:353",
        "acronym": "p_id:353"
    },
    {
        "exam": "Oracle Commerce 11 Platform Development Implementation Essentials 1Z0-437",
        "title": "Oracle Commerce Developer",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:340",
        "acronym": "p_id:340"
    },
    {
        "exam": "Oracle Policy Automation 10 Rule Developer Essentials 1Z0-534",
        "title": "Oracle Policy Modeling",
        "description": "\n\t \n\tThe Oracle Policy Automation 10 Implementation Specialist exam is primarily intended for implementation consultants with at least one year experience, who will be authoring rules in Oracle Policy Modeling, and who have completed the Oracle Policy Automation 10 Implementation Specialist Guided Learning Path or equivalent knowledge acquired through alternate training or on-the-job experience.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle Policy Automation Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:67",
        "acronym": "p_id:67"
    },
    {
        "exam": "Oracle Unified Method 5 Essentials 1Z0-568",
        "title": "Oracle Unified Method",
        "description": "\n\t \n\tThe Oracle Unified Method Certified Implementation Specialist Certification identifies professionals who are skilled in Oracle’s all inclusive methodology. The certification covers the core features the Oracle Unified Method suite, including but not limited to, Focus Areas, Uses Cases, and Requirements Gathering. The certification proves a baseline of the consultant’s knowledge and allows the implementation team to work as a cohesive team from day 1. Up-to-date training and field experience are highly recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on selling and implementing this technology. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle Unified Method Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:69",
        "acronym": "p_id:69"
    },
    {
        "exam": "Oracle Unified Method with Cloud Application Services 2017 Implementation Essentials 1Z0-633",
        "title": "Oracle Unified Method",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:488",
        "acronym": "p_id:488"
    },
    {
        "exam": "PeopleSoft Campus Solutions 9 Student Enrollment Implementation Essentials 1Z0-494",
        "title": "PeopleSoft Campus Solutions",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:317",
        "acronym": "p_id:317"
    },
    {
        "exam": "PeopleSoft Payables 9.1 Essentials 1Z0-573",
        "title": "PeopleSoft Financial Management",
        "description": "\n\t \n\tThe PeopleSoft Payables 9.1 certification is designed for individuals with expertise implementing Oracle’s PeopleSoft Accounts Payable solutions. This certification covers topics such as: Defining Core Tables, Setting Up Payables Business Units, Processing Vouchers, and Creating and Managing Payments.\n\tSuccessfully completing the requirements of this certification track earns the designation of PeopleSoft 9.1 Financial Management: Payables Certified Implementation Specialist. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification qualifies as competency criteria for the PeopleSoft Enterprise Financial Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:75",
        "acronym": "p_id:75"
    },
    {
        "exam": "PeopleSoft Receivables 9.1 Essentials 1Z0-575",
        "title": "PeopleSoft Financial Management",
        "description": "\n\t \n\tThe PeopleSoft Receivables 9.1 certification is designed for individuals with expertise in implementing Oracle’s PeopleSoft Accounts Receivable solutions. This certification covers topics such as: Defining Receivables Tables, Defining Payment Processing, Entering Payments, and Processing Drafts.\n\tSuccessfully completing the requirements of this certification track earns the designation of PeopleSoft Enterprise 9.1 Financial Management: Receivables Certified Implementation Specialist.\n\tThis certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date training and field experience are recommended. This certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification qualifies as competency criteria for the PeopleSoft Enterprise Financial Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:73",
        "acronym": "p_id:73"
    },
    {
        "exam": "PeopleSoft 9.2 Financials Implementation Essentials 1Z0-631",
        "title": "PeopleSoft Financial Management",
        "description": "\n\tA PeopleSoft 9.2 Financials Certified Implementation Specialist has demonstrated the knowledge required to reduce period end close processing time, to maximize cash from financial operations, and to lower the cost of compliance and controls.  Individuals who earn this certification are able to assist in the implementation of modules such as General Ledger, Payables, Receivables, Billing, and eSettlements.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:505",
        "acronym": "p_id:505"
    },
    {
        "exam": "PeopleSoft General Ledger 9 1Z0-228",
        "title": "PeopleSoft Financial Management",
        "description": "\n\t \n\tUsing next-generation technologies and numerous functional enhancements, Oracle’s PeopleSoft helps you get more value from your existing applications, streamline and improve key business processes, and increase workforce efficiency while lowering costs.\n\tMaximizing your return on investment requires you to fully utilize the capabilities of all your existing applications. The PeopleSoft Enterprise 9 Financial Management, General Ledger Certified Consultant has proficiency and knowledge in key areas of implementation such as Oracle general ledger financial controls, journal entry creation and setup, period close and financial reporting.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:74",
        "acronym": "p_id:74"
    },
    {
        "exam": "PeopleSoft 9.2 Human Resources Essentials 1Z0-416",
        "title": "PeopleSoft Human Capital Management",
        "description": "\n\tPeopleSoft 9.2 Human Resources Certified Implementation Specialist\n\tThe PeopleSoft 9.2 Human Resources Certified Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in selling and implementing PeopleSoft Human Resources solutions.  This certification covers topics such as: Core Foundation Tables, Administering Data Permission Security, Adding People, Managing Worker Data, Managing Person and Job Data, and Managing Positions. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.  Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork.  OPN members earning this certification will be recognized as OPN Certified Specialists.  This certification qualifies as competency criteria for the PeopleSoft 9.2 Human Capital Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:354,p_new_design:Y",
        "acronym": "p_id:354,p_new_design:Y"
    },
    {
        "exam": "PeopleSoft Human Resources 9 Fundamentals 1Z0-218",
        "title": "PeopleSoft Human Capital Management",
        "description": "\n\t \n\tOracle's PeopleSoft Human Capital Management (HCM) is a complete solution that enables you to maximize the potential of your organization's workforce. Streamline and enhance performance in Human Resources processes, including recruitment, payroll and compensation, performance appraisal and talent management.\n\tThe PeopleSoft Human Resources 9 Consultant Certified Expert is recognized for proficiency and knowledge in implementing, configuring and customizing the PeopleSoft Enterprise Human Resources Management System.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:70",
        "acronym": "p_id:70"
    },
    {
        "exam": "PeopleSoft PeopleTools 8.5x Implementation Essentials 1Z0-632",
        "title": "PeopleTools",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:493",
        "acronym": "p_id:493"
    },
    {
        "exam": "PeopleSoft Application Developer II: Application Engine & Integration 1Z0-242",
        "title": "PeopleTools",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:72",
        "acronym": "p_id:72"
    },
    {
        "exam": "PeopleSoft Application Developer I: PeopleTools & PeopleCode 1Z0-241",
        "title": "PeopleTools",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:71",
        "acronym": "p_id:71"
    },
    {
        "exam": "Project Lifecycle Management (PLM) Essentials 1Z0-466",
        "title": "Lifecycle Management",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:130",
        "acronym": "p_id:130"
    },
    {
        "title": "CRM Applications",
        "exam": "Siebel 8 Consultant Exam 1Z0-202",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:118",
        "prerequisites": [
            "\n",
            "Show Courses",
            "\n\tCourses can be instructor-led in-class (ILT), a live virtual class (LVC), training on demand (TOD), or Unlimited Learning Subscription. Self-study CD and online courses are great preparation tools, but do not fulfill the training requirement."
        ],
        "description": "\n",
        "acronym": "p_id:118"
    },
    {
        "title": "CRM Applications",
        "exam": "Siebel 8 Consultant Exam 1Z0-202",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:118#tabs-2",
        "prerequisites": [
            "\n",
            "Show Courses",
            "\n\tCourses can be instructor-led in-class (ILT), a live virtual class (LVC), training on demand (TOD), or Unlimited Learning Subscription. Self-study CD and online courses are great preparation tools, but do not fulfill the training requirement."
        ],
        "description": "\n",
        "acronym": "p_id:118#tabs-2"
    },
    {
        "exam": "Oracle Customer Hub and Oracle Data Quality Essentials 1Z0-587",
        "title": "Oracle Customer Hub",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:56",
        "acronym": "p_id:56"
    },
    {
        "exam": "Oracle iStore 12 Essentials 1Z0-555",
        "title": "Oracle iStore",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:55",
        "acronym": "p_id:55"
    },
    {
        "exam": "Oracle Database SQL 1Z0-071",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:228#tabs-2-2",
        "level": "Associate Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:228#tabs-2-2"
    },
    {
        "exam": "Oracle Database 12c: Advanced PL/SQL 1Z0-148",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:228#tabs-2-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:228#tabs-2-2"
    },
    {
        "exam": "Oracle Database SQL 1Z0-071",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:472#tabs-2-1",
        "level": "Associate Level Certification",
        "acronym": "p_id:472#tabs-2-1"
    },
    {
        "exam": "Oracle Database 12c: Advanced PL/SQL 1Z0-148",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:472#tabs-2-1",
        "level": "Professional Level Certification",
        "acronym": "p_id:472#tabs-2-1"
    },
    {
        "exam": "Oracle Database SQL 1Z0-071",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:457#tabs-1-1",
        "level": "Associate Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:457#tabs-1-1"
    },
    {
        "exam": "Oracle Database 12c: Advanced PL/SQL 1Z0-148",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:457#tabs-1-1",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:457#tabs-1-1"
    },
    {
        "exam": "Oracle Database SQL 1Z0-071",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:162#tabs-1-2",
        "level": "Associate Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:162#tabs-1-2"
    },
    {
        "exam": "Oracle Database 12c: Advanced PL/SQL 1Z0-148",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:162#tabs-1-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:162#tabs-1-2"
    },
    {
        "exam": "MySQL 5.6 Database Administrator 1Z0-883",
        "title": "MySQL Database Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:260",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:260"
    },
    {
        "exam": "MySQL 5.6 Database Administrator 1Z0-883",
        "title": "MySQL Database Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:260#tabs-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:260#tabs-2"
    },
    {
        "exam": "MySQL 5.6 Developer 1Z0-882",
        "title": "MySQL Developer",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:259#tabs-1",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:259#tabs-1"
    },
    {
        "exam": "MySQL 5.6 Developer 1Z0-882",
        "title": "MySQL Developer",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:259#tabs-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:259#tabs-2"
    },
    {
        "exam": "Oracle Real Application Clusters (RAC) 11g Release 2 and Grid Infrastructure Administration (Retiring August 31, 2018) 1Z0-058",
        "title": "Oracle Database 11g",
        "description": "\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:207#tabs-2",
        "prerequisites": [
            "\n"
        ],
        "company": "Oracle",
        "acronym": "p_id:207#tabs-2"
    },
    {
        "exam": "Oracle Real Application Clusters (RAC) 11g Release 2 and Grid Infrastructure Administration (Retiring August 31, 2018) 1Z0-058",
        "title": "Oracle Database 11g",
        "description": "\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:207",
        "prerequisites": [
            "\n"
        ],
        "company": "Oracle",
        "acronym": "p_id:207"
    },
    {
        "title": "Oracle Database 11g",
        "exam": "Oracle Database 11g: Administration I 1Z0-052",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:50#tabs-1",
        "prerequisites": [
            "Pass one SQL Exam:"
        ],
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:50#tabs-1"
    },
    {
        "exam": "Oracle Database 11g: Administration II 1Z0-053",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:50#tabs-1",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:50#tabs-1"
    },
    {
        "exam": "Oracle Database 11g Certified Master Exam 11GOCM",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:50#tabs-1",
        "level": "Master Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:50#tabs-1"
    },
    {
        "title": "Oracle Database 11g",
        "exam": "Oracle Database 11g: Administration I 1Z0-052",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-3",
        "prerequisites": [
            "Pass one SQL Exam:"
        ],
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-3"
    },
    {
        "exam": "Oracle Database 11g: Administration II 1Z0-053",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-3",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-3"
    },
    {
        "exam": "Oracle Database 11g Certified Master Exam 11GOCM",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-3",
        "level": "Master Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-3"
    },
    {
        "title": "Oracle Database 11g",
        "exam": "Oracle Database 11g: Administration I 1Z0-052",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-4",
        "prerequisites": [
            "Pass one SQL Exam:"
        ],
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-4"
    },
    {
        "exam": "Oracle Database 11g: Administration II 1Z0-053",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-4",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-4"
    },
    {
        "exam": "Oracle Database 11g Certified Master Exam 11GOCM",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-4",
        "level": "Master Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-4"
    },
    {
        "title": "Oracle Database 11g",
        "exam": "Oracle Database 11g: Administration I 1Z0-052",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:51#tabs-2",
        "prerequisites": [
            "Pass one SQL Exam:"
        ],
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:51#tabs-2"
    },
    {
        "exam": "Oracle Database 11g: Administration II 1Z0-053",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:51#tabs-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:51#tabs-2"
    },
    {
        "exam": "Oracle Database 11g Certified Master Exam 11GOCM",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:51#tabs-2",
        "level": "Master Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:51#tabs-2"
    },
    {
        "exam": "Oracle Enterprise Manager 11g Essentials (Retiring May 31st, 2018) 1Z0-530",
        "title": "Enterprise Manager",
        "description": "\n\t\tThis certification retires May 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:108",
        "acronym": "p_id:108"
    },
    {
        "exam": "Oracle Enterprise Manager 12c Essentials 1Z0-457",
        "title": "Enterprise Manager",
        "description": "\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:110",
        "acronym": "p_id:110"
    },
    {
        "exam": "Primavera Contract Management Essentials 1Z0-582",
        "title": "Primavera Contract Management",
        "description": "\n\t \n\tThe Primavera Contract Management certification is designed for individuals who possess a strong foundation and expertise implementing Project Controls solutions. This certification covers topics on core elements such as: System Set-up and Project Information, Project Configuration, Control and Communication, Contract Information, Logs, Change Management, Administrative Functions, in the context of typical use case implementations. Up-to-date training and field experience are recommended. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:76",
        "acronym": "p_id:76"
    },
    {
        "exam": "Primavera P6 Enterprise Project Portfolio Management 8 Essentials 1Z0-567",
        "title": "Primavera P6 Enterprise Project Portfolio Management",
        "description": "\n\t \n\tThe Primavera P6 Enterprise Project Portfolio Management 8 certification is designed for individuals who possess a strong foundation and expertise implementing Project Portfolio Management solutions. This certification covers topics on core elements such as: Enterprise Data Structures, Administrative Functions, Portfolio and Project Configuration, in the context of typical use caseimplementations. Up-to-date training and field experience are recommended\n\tSuccessfully completing the requirements of this certification track earns the designation of Primavera P6 Enterprise Project Portfolio Management Certified Implementation Specialist. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date training and field experience are recommended\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork.OPN members earning this certification will be recognized as OPN Certified Specialists. This certification helps the OPN member’s partner organization qualify for the Primavera P6 Enterprise Project Portfolio Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:77",
        "acronym": "p_id:77"
    },
    {
        "exam": "Primavera Portfolio Management Essentials 1Z0-544",
        "title": "Primavera Portfolio Management",
        "description": "\n\t \n\tThe Primavera Portfolio Management 9 certification is designed for individuals who possess a strong foundation and expertise implementing Portfolio Management solutions. This certification exam covers topics on core elements such as: System Set-up, Portfolio Analytics, Administrative Capabilities, and Advanced Calculative Functions, in the context of typical use case implementations. Up-to-date training and field experience are recommended.\n\tSuccessfully completing the requirements of this certification track earns the designation of Primavera Portfolio Management Certified Implementation Specialist. This certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification helps the OPN member’s partner organization qualify for the Primavera Portfolio Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:79",
        "acronym": "p_id:79"
    },
    {
        "exam": "Primavera Unifier Cloud Service 2016 Implementation Essentials 1Z0-439",
        "title": "Primavera Unifier (Skire)",
        "description": "\n\tPrimavera Unifier 10 Implementation Specialist\n\tThe Primavera Unifier 10 Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in selling and implementing Primavera Unifier 10 solutions.  This certification covers topics such as: Solution Product Overview and Positioning, Product Features and Functions - Case Processing and Workflow, Architechture, Product Installations, Configuration and integration.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:358",
        "acronym": "p_id:358"
    },
    {
        "exam": "Oracle Communications BRM Elastic Charging Engine 2017 Implementation Essentials 1Z0-321",
        "title": "Billing and Revenue Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:466",
        "acronym": "p_id:466"
    },
    {
        "exam": "Oracle Communications Billing and Revenue Management 7 Advanced Implementation Essentials 1Z0-509",
        "title": "Billing and Revenue Management",
        "description": "\n\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:111",
        "acronym": "p_id:111"
    },
    {
        "exam": "Oracle Argus Enterprise Edition 8 Implementation Essentials 1Z0-328",
        "title": "Oracle Argus",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:415",
        "acronym": "p_id:415"
    },
    {
        "exam": "Oracle Utilities Customer Care and Billing 2 Essentials 1Z0-562",
        "title": "Customer Care and Billing",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:99",
        "acronym": "p_id:99"
    },
    {
        "exam": "Oracle Utilities Meter Data Management 2 Essentials 1Z0-598",
        "title": "Meter Data Management",
        "description": "\n\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:101",
        "acronym": "p_id:101"
    },
    {
        "exam": "Oracle Utilities Mobile Workforce Management 2 Implementation Essentials 1Z0-458",
        "title": "Mobile Workforce Management",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:256",
        "acronym": "p_id:256"
    },
    {
        "exam": "Oracle Utilities Smart Grid Gateway 2 for Advanced Metering Infrastructure Implementation Essentials 1Z0-492",
        "title": "Smart Grid Gateway",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:257",
        "acronym": "p_id:257"
    },
    {
        "exam": "Oracle Business Intelligence (OBI) Applications 7 for CRM Essentials (retiring May 31st, 2018) 1Z0-524",
        "title": "BI Applications",
        "description": "\n\t\tPlease be advised that this exam will be retired on May 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:185",
        "acronym": "p_id:185"
    },
    {
        "exam": "Oracle Business Intelligence (OBI) Applications 7 for ERP Essentials (retiring May 31st, 2018) 1Z0-525",
        "title": "BI Applications",
        "description": "\n\t\tPlease be advised that this exam will be retired on May 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:184",
        "acronym": "p_id:184"
    },
    {
        "exam": "Oracle Business Intelligence (OBI) Foundation 10 Essentials (retiring May 31st, 2018) 1Z0-526",
        "title": "BI Enterprise Edition (BI EE)",
        "description": "\n\t\tPlease be advised that this exam will be retired on May 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:165",
        "acronym": "p_id:165"
    },
    {
        "exam": "Oracle Business Intelligence (OBI) Foundation Suite 11g Essentials 1Z0-591",
        "title": "BI Enterprise Edition (BI EE)",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:166",
        "acronym": "p_id:166"
    },
    {
        "exam": "Oracle Endeca Information Discovery  2.3 Essentials (retiring May 31st, 2018) 1Z0-461",
        "title": "Endeca Information Discovery",
        "description": "\n\t\tPlease be advised that this exam will be retired on May 31st, 2018. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:183#tabs-2",
        "acronym": "p_id:183#tabs-2"
    },
    {
        "exam": "Oracle Endeca Information Discovery 3.1 Essentials 1Z0-428",
        "title": "Endeca Information Discovery",
        "description": "\n\tThe Oracle Endeca Information Discovery 3.1 Certified Implementation Specialist certification is intended for implementation consultants specializing in Oracle Endeca Information Discovery 3.1. The recommended online and/or in class training sessions provide consultants with technical product information as they work with customers to implement solutions based on Oracle products. Passing the required exam enables you to become an Oracle Endeca Information Discovery 3.1 Certified Implementation Specialist.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:332#tabs-1-2",
        "acronym": "p_id:332#tabs-1-2"
    },
    {
        "exam": "Oracle Essbase 11 Essentials 1Z0-531",
        "title": "Essbase",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:182",
        "acronym": "p_id:182"
    },
    {
        "exam": "Oracle Application Grid 11g Essentials 1Z0-523",
        "title": "Coherence",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:137",
        "acronym": "p_id:137"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Administration I 1Z0-133",
        "title": "WebLogic Server",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-2",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-2"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Advanced  Administrator II 1Z0-134",
        "title": "WebLogic Server",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-2"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Administration I 1Z0-133",
        "title": "WebLogic Server",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-1",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-1"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Advanced  Administrator II 1Z0-134",
        "title": "WebLogic Server",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-1",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-1"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Administration I 1Z0-133",
        "title": "WebLogic Server",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:347#tabs-2",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:347#tabs-2"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Advanced  Administrator II 1Z0-134",
        "title": "WebLogic Server",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:347#tabs-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:347#tabs-2"
    },
    {
        "exam": "Oracle Cloud Application Foundation Essentials 1Z0-468",
        "title": "WebLogic Server",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:86",
        "acronym": "p_id:86"
    },
    {
        "exam": "Oracle WebLogic Server 12c Essentials 1Z0-599",
        "title": "WebLogic Server",
        "description": "\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:136",
        "acronym": "p_id:136"
    },
    {
        "exam": "Oracle Data Integrator 11g Certified Implementation Essentials 1Z0-482",
        "title": "Data Integrator (ODI)",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:262",
        "acronym": "p_id:262"
    },
    {
        "exam": "Oracle Data Integrator 12c Essentials 1Z0-448",
        "title": "Data Integrator (ODI)",
        "description": "\n\tThe Oracle Data Integration 12c Essentials (1Z0-448) exam is designed for individuals who possess a strong foundation and expertise in selling or implementing oracle Data Integration 12c solutions.  This certification exam covers topics such as: Oracle Data Integration 12c Architecture; Oracle Data Integration 12c Project Development; Oracle Data Integration 12c Models and Datastores and more. Up-to-date training and field experience are recommended.\n\tThe Oracle Data Integration 12c Implementation Specialist certification recognizes OPN members as OPN Certified Specialists.  This certification differentiates OPN members in the marketplace by providing a competitive edge through proven expertise.  This certification helps the OPN member’s partner organization qualify for the Data Integration 12c.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:379#tabs-1-2",
        "acronym": "p_id:379#tabs-1-2"
    },
    {
        "exam": "Oracle GoldenGate 12c Implementation Essentials 1Z0-447",
        "title": "GoldenGate",
        "description": "\n\tThe Oracle GoldenGate 12c Essentials (1Zx-xxx) exam is designed for individuals who possess a strong foundation and expertise in selling or implementing oracle GoldenGate 12c solutions.  This certification exam covers topics such as: Oracle Goldengate 12c Architecture; Oracle GoldenGate 12c Parametres; Oracle Goldengate 12c Mapping and Transformation Overview and more. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:389",
        "acronym": "p_id:389"
    },
    {
        "exam": "Oracle Access Management Suite Plus 11g Essentials 1Z0-479",
        "title": "Access Manager",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:246",
        "acronym": "p_id:246"
    },
    {
        "exam": "Oracle Identity Governance Suite 11g PS3 Implementation Essentials 1Z0-339",
        "title": "Identity Manager",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:417",
        "acronym": "p_id:417"
    },
    {
        "exam": "Java EE 6 Java Persistence API Developer Certified Expert 1Z0-898",
        "title": "Java EE and Web Services",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:160",
        "acronym": "p_id:160"
    },
    {
        "exam": "Java EE 6 Enterprise JavaBeans Developer Certified Expert 1Z0-895",
        "title": "Java EE and Web Services",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:159",
        "acronym": "p_id:159"
    },
    {
        "exam": "Java EE 6 JavaServer Faces Developer Certified Expert 1Z0-896",
        "title": "Java EE and Web Services",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:281",
        "acronym": "p_id:281"
    },
    {
        "exam": "Java EE 6 Web Component Developer Certified Expert 1Z0-899",
        "title": "Java EE and Web Services",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:168",
        "acronym": "p_id:168"
    },
    {
        "exam": "Java EE 6 Web Services Developer Certified Expert 1Z0-897",
        "title": "Java EE and Web Services",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:161",
        "acronym": "p_id:161"
    },
    {
        "exam": "Java EE 6 Enterprise Architect Certified Master 1Z0-807",
        "title": "Java EE and Web Services",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:212#tabs-1",
        "level": "Master Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:212#tabs-1"
    },
    {
        "exam": "Java EE 6 Enterprise Architect Certified Master 1Z0-807",
        "title": "Java EE and Web Services",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:212#tabs-2",
        "level": "Master Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:212#tabs-2"
    },
    {
        "exam": "Java Foundations (novice-level exam) 1Z0-811",
        "title": "Java SE",
        "description": "\n\tPosition yourself to enter the workforce, or get the internship of your dreams with marketable Java skills.\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:372",
        "acronym": "p_id:372"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:158#tabs-1-3",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:158#tabs-1-3"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:158#tabs-1-3",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:158#tabs-1-3"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-1-2",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-1-2"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-1-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-1-2"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-1-1",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-1-1"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-1-1",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-1-1"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-2-3",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-2-3"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-2-3",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-2-3"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-4",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-4"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-4",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-4"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:155#tabs-2-2",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:155#tabs-2-2"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:155#tabs-2-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:155#tabs-2-2"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-4",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-4"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-4",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-4"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-2-1",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-2-1"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-2-1",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-2-1"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-4",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-4"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-4",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-4"
    },
    {
        "exam": "Oracle Application Development Framework (ADF) 11g Essentials (retiring July 31, 2018) 1Z0-554",
        "title": "Application Development Framework",
        "description": "\n\tThis certification retires July 31, 2018. \n\tThe Oracle Application Development Framework 11\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on implementing Oracle Fusion applications with ADF Business Components.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:84",
        "acronym": "p_id:84"
    },
    {
        "exam": "Oracle Application Development Framework 12c Essentials 1Z0-419",
        "title": "Application Development Framework",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:316",
        "acronym": "p_id:316"
    },
    {
        "exam": "Oracle Fusion Middleware 11g: Build Applications with Oracle Forms 1Z0-151",
        "title": "Forms and Reports",
        "description": "\n\n\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:177#tabs-1-3",
        "level": "Professional Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:177#tabs-1-3"
    },
    {
        "exam": "Oracle Fusion Middleware 11g: Build Applications with Oracle Forms 1Z0-151",
        "title": "Forms and Reports",
        "description": "\n\n\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:177#tabs-2",
        "level": "Professional Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:177#tabs-2"
    },
    {
        "exam": "Oracle Mobile Development 2015 Essentials 1Z0-441",
        "title": "Mobile Application Framework",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:360",
        "acronym": "p_id:360"
    },
    {
        "exam": "Oracle IT Architecture Release 3 Essentials 1Z0-574",
        "title": "Oracle Technical Architecture",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:131",
        "acronym": "p_id:131"
    },
    {
        "exam": "Oracle Application Integration Architecture 11g Essentials 1Z0-543",
        "title": "AIA",
        "description": "\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:140",
        "acronym": "p_id:140"
    },
    {
        "exam": "Oracle Business Process Management Suite 12c Essentials 1Z0-435",
        "title": "BPM",
        "description": "\n\tOracle Business Process Management Suite 12c Implementation Specialist\n\tThe Oracle Business Process Management Suite 12c Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in implementing Oracle Business Process Management Suite 12c solutions.  This certification covers topics such as: BPMN process modeling, adaptive case management, simulation, business rules, human workflow, human task forms, process analytics, BPM Workspace, Security and Administration.  This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.  Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:356",
        "acronym": "p_id:356"
    },
    {
        "exam": "Oracle IT Architecture BPM 2013 Essentials 1Z0-476",
        "title": "BPM",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:273",
        "acronym": "p_id:273"
    },
    {
        "exam": "Oracle Unified Business Process Management Suite 11g Essentials (retiring July 31, 2018) 1Z0-560",
        "title": "BPM",
        "description": "\n\t\tThis certification retires July 31, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:139",
        "acronym": "p_id:139"
    },
    {
        "exam": "Oracle IT Architecture SOA 2013 Essentials 1Z0-475",
        "title": "SOA Suite",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:253",
        "acronym": "p_id:253"
    },
    {
        "exam": "Oracle SOA Suite 11g Essentials (retiring July 31st, 2018) 1Z0-478",
        "title": "SOA Suite",
        "description": "\n\t\tThis certification retires July 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:138",
        "acronym": "p_id:138"
    },
    {
        "exam": "Oracle SOA Suite 12c Essentials 1Z0-434",
        "title": "SOA Suite",
        "description": "\n\tOracle SOA Suite 12c Implementation Specialist\n\tThe Oracle SOA Suite 12c Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in implementing Oracle Soute Suite 12c solutions.  This certification covers topics such as: BPEL modeling, adapters, business rules, human task services, service mediation, event processing, business activity monitoring, securing services, deployment, troubleshooting, installation and configuration. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.  Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:355",
        "acronym": "p_id:355"
    },
    {
        "exam": "Oracle WebCenter Content 11g Essentials 1Z0-542",
        "title": "WebCenter Content",
        "description": "\n\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:141",
        "acronym": "p_id:141"
    },
    {
        "exam": "Oracle WebCenter Portal 11.1.1.8 Essentials 1Z0-430",
        "title": "WebCenter Portal",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:337",
        "acronym": "p_id:337"
    },
    {
        "exam": "Oracle WebCenter Sites 11g Essentials 1Z0-462",
        "title": "WebCenter Sites",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:241",
        "acronym": "p_id:241"
    },
    {
        "exam": "Oracle Linux 5 and 6 System Administration 1Z0-100",
        "title": "Oracle Linux Administration",
        "description": "\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-1",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-1"
    },
    {
        "exam": "Oracle Linux 6 Advanced System Administration 1Z0-105",
        "title": "Oracle Linux Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-1",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-1"
    },
    {
        "exam": "Oracle Linux 5 and 6 System Administration 1Z0-100",
        "title": "Oracle Linux Administration",
        "description": "\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-2",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-2"
    },
    {
        "exam": "Oracle Linux 6 Advanced System Administration 1Z0-105",
        "title": "Oracle Linux Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-2"
    },
    {
        "exam": "Oracle Linux 6 Implementation Essentials 1Z0-460",
        "title": "Oracle Linux Administration",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:88",
        "acronym": "p_id:88"
    },
    {
        "exam": "Oracle Solaris 11 System Administration 1Z0-821",
        "title": "Solaris 11 Administration",
        "description": "\n\tThe OCA and OCP, Solaris 11 System Administrator certifications are designed to begin with individuals who possess a strong foundation in the administration of the Oracle Solaris 11 Operating System at the OCA level and who, at the OCP level have expanded their mastery of the administration of the Oracle Solaris 11 Operating System from the base-level of skills and have a fundamental understanding of the UNIX operating system, commands, and utilities.\n\tThe OCA certification covers essential system administration skills such as managing local disk devices, managing file systems, installing and removing Solaris packages and patches, performing system boot procedures and system processes. The OCP certification covers system administration skills such as configuring network interfaces, managing swap configurations, crash dumps, and core files. Certification provides a solid foundation on which to base continued learning. Up-to-date training and field experience are recommended.\n\tOracle Certifications differentiate candidates in the marketplace by providing a competitive edge through proven expertise.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:202#tabs-1",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:202#tabs-1"
    },
    {
        "exam": "Oracle Solaris 11 Advanced System Administration 1Z0-822",
        "title": "Solaris 11 Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:202#tabs-1",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:202#tabs-1"
    },
    {
        "exam": "Oracle Solaris 11 System Administration 1Z0-821",
        "title": "Solaris 11 Administration",
        "description": "\n\tThe OCA and OCP, Solaris 11 System Administrator certifications are designed to begin with individuals who possess a strong foundation in the administration of the Oracle Solaris 11 Operating System at the OCA level and who, at the OCP level have expanded their mastery of the administration of the Oracle Solaris 11 Operating System from the base-level of skills and have a fundamental understanding of the UNIX operating system, commands, and utilities.\n\tThe OCA certification covers essential system administration skills such as managing local disk devices, managing file systems, installing and removing Solaris packages and patches, performing system boot procedures and system processes. The OCP certification covers system administration skills such as configuring network interfaces, managing swap configurations, crash dumps, and core files. Certification provides a solid foundation on which to base continued learning. Up-to-date training and field experience are recommended.\n\tOracle Certifications differentiate candidates in the marketplace by providing a competitive edge through proven expertise.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-2",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-2"
    },
    {
        "exam": "Oracle Solaris 11 Advanced System Administration 1Z0-822",
        "title": "Solaris 11 Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-2"
    },
    {
        "exam": "Oracle Solaris 11 System Administration 1Z0-821",
        "title": "Solaris 11 Administration",
        "description": "\n\tThe OCA and OCP, Solaris 11 System Administrator certifications are designed to begin with individuals who possess a strong foundation in the administration of the Oracle Solaris 11 Operating System at the OCA level and who, at the OCP level have expanded their mastery of the administration of the Oracle Solaris 11 Operating System from the base-level of skills and have a fundamental understanding of the UNIX operating system, commands, and utilities.\n\tThe OCA certification covers essential system administration skills such as managing local disk devices, managing file systems, installing and removing Solaris packages and patches, performing system boot procedures and system processes. The OCP certification covers system administration skills such as configuring network interfaces, managing swap configurations, crash dumps, and core files. Certification provides a solid foundation on which to base continued learning. Up-to-date training and field experience are recommended.\n\tOracle Certifications differentiate candidates in the marketplace by providing a competitive edge through proven expertise.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-3",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-3"
    },
    {
        "exam": "Oracle Solaris 11 Advanced System Administration 1Z0-822",
        "title": "Solaris 11 Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-3",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-3"
    },
    {
        "exam": "Oracle Solaris 11 Installation and Configuration Essentials 1Z0-580",
        "title": "Solaris 11 Administration",
        "description": "\n\t \n\tOracle's Solaris 11 Certified Implementation Specialist Certification identifies professionals that are skilled in implementing solutions based on the Oracle Solaris 11 operating system. The certification covers the installation and configuration of Oracle Solaris 11. The exam targets the intermediate-level implementation team member. Up-to-date training and field experience are highly recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on selling and implementing Oracle Solaris 11 solutions. OPN Members earning this certification will be recognized as OPN Certified Implementation Specialists, which helps their companies qualify for the Oracle Solaris 11 Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:89",
        "acronym": "p_id:89"
    },
    {
        "exam": "Oracle Cloud Infrastructure 2018 Architect Associate 1Z0-932",
        "title": "Infrastructure as a Service (IaaS)",
        "description": "\n\tThe Oracle Cloud Infrastructure Architect Associate exam is designed for individuals who possess a strong foundation knowledge in architecting infrastructure using Oracle Cloud Infrastructure services. This certification covers topics such as: Cloud computing concepts (HA, DR, Security), regions, availability domains, OCI terminology and services, networking, databases, load balancing, IAM, DNS, FASTCONNECT, VPN, Compartments, tagging, Terraform, with focus on how to use it with OCI and Exadata. This certification validates deep understanding of OCI services to spin up infrastructure and provides a competitive edge in the industry. Up-to-date OCI training and hands-on experience are recommended. This certification is available to all candidates. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:538",
        "acronym": "p_id:538"
    },
    {
        "exam": "Oracle Cloud Infrastructure Classic 2018 Associate Architect 1Z0-337",
        "title": "Infrastructure as a Service (IaaS)",
        "description": "\n\tAn Oracle Cloud Infrastructure Classic Associate Architect has demonstrated the knowledge required to  specify, architect, implement applicable Oracle Infrastructure as a Service services/solutions.  Individuals who earn this certification are able to explain, deploy, configure, and utilize Oracle Infrastructure as a Service.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:474",
        "acronym": "p_id:474"
    },
    {
        "exam": "Oracle Java Cloud Service Certified Associate 1Z0-161",
        "title": "Application Development",
        "description": "\n\tAdministrators and developers learn the key areas of functionality of the Java Cloud Service and how to use them as they develop, deploy and migrate Java EE applications. Also learn how to administer, secure, tune and troubleshoot Java Cloud Service.  ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:548#tabs-1-2",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "p_id:548#tabs-1-2"
    },
    {
        "exam": "Oracle Java Cloud Service Certified Associate 1Z0-161",
        "title": "Application Development",
        "description": "\n\tAdministrators and developers learn the key areas of functionality of the Java Cloud Service and how to use them as they develop, deploy and migrate Java EE applications. Also learn how to administer, secure, tune and troubleshoot Java Cloud Service.  ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:546#tabs-1-3",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "p_id:546#tabs-1-3"
    },
    {
        "exam": "Oracle Java Cloud Service Certified Associate 1Z0-161",
        "title": "Application Development",
        "description": "\n\tAdministrators and developers learn the key areas of functionality of the Java Cloud Service and how to use them as they develop, deploy and migrate Java EE applications. Also learn how to administer, secure, tune and troubleshoot Java Cloud Service.  ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:411",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:411"
    },
    {
        "exam": "Oracle Cloud Platform Enterprise Analytics 2018 Associate 1Z0-936",
        "title": "Business Analytics",
        "description": "An Oracle Analytics Cloud Associate is responsible for implementing Oracle Analytics Cloud. They have the knowledge required to perform provisioning, build dimensional modelling and create data visualizations. They can use Advanced Analytics capabilities, create a machine learning model and configure Oracle Analytics Cloud Essbase.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:543",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "p_id:543"
    },
    {
        "exam": "MySQL Cloud Service 2018 Implementation Essentials 1Z0-320",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:489",
        "acronym": "p_id:489"
    },
    {
        "exam": "Oracle Cloud Platform Big Data Management 2018 Associate 1Z0-928",
        "title": "Data Management",
        "description": "The Oracle Big Data Cloud Service 2018 Associate exam is designed for individuals who possess a strong foundational knowledge in using and configuring Oracle Big Data services. This certification covers topics such as: Fundamentals of Big Data, Oracle Solutions and Services for Big Data Needs (BDC and (BDCS), Data Streaming Platform, Oracle Big Data Security, and Big Data Cloud Integration.  This certification validates understanding of Oracle Big Data to implement the service and provides a competitive edge in the industry. Up-to-date training and hands-on experience supporting or leading PaaS implementations, or equivalent practice is recommended. This certification is available to all candidates.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:539",
        "acronym": "p_id:539"
    },
    {
        "exam": "Oracle Cloud Platform Data Management 2018 Associate 1Z0-950",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:537",
        "acronym": "p_id:537"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:468#tabs-1-1",
        "prerequisites": [],
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:468#tabs-1-1"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:468#tabs-1-1",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:468#tabs-1-1"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:412#tabs-2",
        "prerequisites": [],
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:412#tabs-2"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:412#tabs-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:412#tabs-2"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:463#tabs-1-2",
        "prerequisites": [],
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:463#tabs-1-2"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:463#tabs-1-2",
        "level": "Professional Level Certification",
        "acronym": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:463#tabs-1-2"
    },
    {
        "exam": "Oracle Cloud Platform Application Integration 2018 Associate 1Z0-934",
        "title": "Integration",
        "description": "The Oracle Cloud Platform Application Integration 2018 Associate exam is designed for individuals who possess a strong foundational knowledge in using and configuring Application Integration services. This certification covers topics such as: Oracle Cloud Application Integration basics, Application Integration: Oracle Integration Cloud (OIC), Service-Oriented Architecture Cloud Service (SOACS), Integration API Platform Cloud Service, Internet of Things - Cloud Service (IOTCS), and Oracle's Process Cloud Service. This certification validates understanding of Oracle Application Integration to implement the service. Up-to-date training and hands-on experience supporting or leading Application Integration implementations, or equivalent practice is recommended. This certification is available to all candidates.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:549",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "p_id:549"
    },
    {
        "exam": "Oracle Cloud Platform Application Integration 2018 Associate 1Z0-934",
        "title": "Integration",
        "description": "The Oracle Cloud Platform Application Integration 2018 Associate exam is designed for individuals who possess a strong foundational knowledge in using and configuring Application Integration services. This certification covers topics such as: Oracle Cloud Application Integration basics, Application Integration: Oracle Integration Cloud (OIC), Service-Oriented Architecture Cloud Service (SOACS), Integration API Platform Cloud Service, Internet of Things - Cloud Service (IOTCS), and Oracle's Process Cloud Service. This certification validates understanding of Oracle Application Integration to implement the service. Up-to-date training and hands-on experience supporting or leading Application Integration implementations, or equivalent practice is recommended. This certification is available to all candidates.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:545",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "p_id:545"
    },
    {
        "exam": "Oracle Mobile Cloud Service 2016 Developer Essentials 1Z0-346",
        "title": "Mobile Cloud Service",
        "description": "\n\tAn Oracle Certified Specialist, Mobile Cloud Service 2016 Devleoper has demonstrated the knowledge and skills required to begin using Mobile Cloud Service (MCS) in a project by navigating the design-time architecture, creating mobile backends, using platform APIs, configuring connectors and implementing custom APIs.  In addition, he/she is capable of using the analytics dashboard, controlling mobile user access and administering environments.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:469",
        "acronym": "p_id:469"
    },
    {
        "exam": "Oracle Cloud Platform Systems Management 2018 Associate 1Z0-930",
        "title": "Oracle Management Cloud",
        "description": "An Oracle Management Cloud 2018 Certified Associate has demonstrated the skills and knowledge to architect and implement Oracle Management Cloud. This individual can configure Application Performance Monitoring, Oracle Infrastructure Monitoring, Oracle Log Analytics, Oracle IT Analytics, Oracle Orchestration, Oracle Security Monitoring and Analytics and Oracle Configuration and Compliance.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:547",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "p_id:547"
    },
    {
        "exam": "Oracle Management Cloud 2017 Implementation Essentials 1Z0-500",
        "title": "Oracle Management Cloud",
        "description": "\n\tAn Oracle Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to specify, architect, implement applicable  Oracle Management Cloud solution/service(s).  Individuals who earn this certification are able to explain, deploy, configure, utilize Oracle Management Cloud services.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:473",
        "acronym": "p_id:473"
    },
    {
        "exam": " Oracle Cloud Platform Identity and Security Management 2018 Associate 1Z0-933",
        "title": "Security",
        "description": "The Oracle Cloud Security 2018 Associate exam is designed for security professionals who possess expertise implementing Cloud solutions. This certification covers topics such as: Identity Security Operations Center Framework, Identity Cloud Service, CASB Cloud Service, Security Monitoring and Analytics Cloud Service, Configuration and Compliance Service, and services Architecture and Deployment. This certification validates understanding of Oracle Cloud Security portfolio and capacity to configure the services. Up-to-date training and hands-on experience supporting or leading Cloud Security implementations in an administrator role, or equivalent practice is recommended. This certification is available to all candidates.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:544",
        "level": "Associate Level Certification",
        "company": "Oracle",
        "acronym": "p_id:544"
    },
    {
        "exam": "Oracle CPQ Cloud Service 2016 Implementation Essentials (Retiring May 31st, 2018) 1Z0-436",
        "title": "Oracle CPQ Cloud",
        "description": "\n\tThis certification retires May 31st, 2018. \n\tThe Oracle CPQ Cloud Service 2016 Implementation Essentials recognizes partners that are proficient in implementing and/or developing BigMachines Configure Price Quote (CPQ) Cloud solution.\n\tThis certification is for individuals focused on implementing and designing  a solution for dramatically improving the way project-driven organizations and project professionals work using Oracle BigMachines CPQ Cloud Service. \n\tThis exam will cover topics such as designing product and pricing structure, implementing pricing functions, designing commerce pricing, designing workflow approval and notifications, integrating with CRM and ERP applications using webservices and planning environment and testing strategies.\n\tOracle partners who achieve this Certification are differentiated in the marketplace through proven expertise in Oracle BigMachines CPQ Cloud Service.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:362",
        "acronym": "p_id:362"
    },
    {
        "exam": "Oracle CPQ Cloud Service 2017 Implementation Essentials 1Z0-976",
        "title": "Oracle CPQ Cloud",
        "description": "\n\tAn Oracle CPQ Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to Design and integrate CPQ Cloud with ERP and other backend systems, install and configure CPQ Cloud Managed Packages, and design commerce pricing, rules, and workflows.  Individuals who earn this certification are able to write and design BML scripts and configure subscription ordering and implement BOM mapping.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:516",
        "acronym": "p_id:516"
    },
    {
        "exam": "Oracle Data Management Platform Cloud 2017 Implementation Essentials 1Z0-952",
        "title": "Oracle Marketing Cloud",
        "description": "\n\tAn Oracle Marketing Cloud,Data Management Platform 2018 Certified Implementation Specialist has demonstrated the knowledge required to apply data scoping and strategies, implement BlueKai Core Tags for first-party data ingest, and configure ad vendors and campaigns for data delivery.  Individuals who earn this certification are able to facilitate media tagging and analytics, configure site side optimization, server data transfer and API methods for data delivery.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:515",
        "acronym": "p_id:515"
    },
    {
        "exam": "Oracle Eloqua Marketing Cloud Service 2017 Implementation Essentials 1Z0-349",
        "title": "Oracle Marketing Cloud",
        "description": "\n\tAn Oracle Eloqua Marketing Cloud Service Certified Implementation Specialist has demonstrated the knowledge required to complete a full implementation of Eloqua, provide consultation on project deliverables to the client and apply knowledge of client’s business, marketing and sales processes to make recommendations when integrating Eloqua with third-party systems. Individuals who earn this certification are able to describe the different components of the Eloqua application, Implement and configure each Branding and Deliverability package, integrate CRM custom objects with Eloqua and leverage the data for segmentation, personalization, and scoring and much more.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:471",
        "acronym": "p_id:471"
    },
    {
        "exam": "Oracle Responsys Marketing Platform Cloud Service 2017 Implementation Essentials 1Z0-477",
        "title": "Oracle Marketing Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:470",
        "acronym": "p_id:470"
    },
    {
        "exam": "Oracle Customer Data Management Cloud Service 2017 Implementation Essentials 1Z0-978",
        "title": "Oracle Sales Cloud",
        "description": "\n\tThe Oracle Customer Data Management Cloud 2017 Certified Implementation Specialist is designed for individuals who possess as strong foundation and expertise implementing Customer Data Management solutions. This certification covers topics such as: Setup and Customer Data Configuration, Data Modelling, Architecture, Addressing Cleansing and Verification, Duplicate Identification and Resolution, Data Enrichment, and Extensibility and Integrations. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.  Up-to-date Customer Data Management training and field experience are recommended. This certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork.  OPN members earning this certification will be recognized as OPN Certified Specialists.  ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:527",
        "acronym": "p_id:527"
    },
    {
        "exam": "Oracle Incentive Compensation ​Cloud 2017 Implementation ​Essentials 1Z0-971",
        "title": "Oracle Sales Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:503",
        "acronym": "p_id:503"
    },
    {
        "exam": "Oracle Partner Relationship Management Cloud 2017 Implementation Essentials 1Z0-977",
        "title": "Oracle Sales Cloud",
        "description": "\n\tThe Oracle Partner Relationship Management Cloud 2017 Certified Implementation Specialist is designed for individuals who possess a strong foundation and expertise implementing Partner Relationship Management solutions. This certification covers topics such as: Configuration of Channel Sales, Deal Registration, Deal Management, Partner Program Management, Business Plan and Objectives, Marketing Development Funds, Partner Recruitment and On-Boarding, PRM Reporting and Extensibility. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date Partner Relationship Management training and field experience are recommended. This certification is available to all candidates but is geared toward members of the Oracle Partner Network.  OPN members earning this certification will be recognized as OPN Certified Specialists.  ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:536",
        "acronym": "p_id:536"
    },
    {
        "exam": "Oracle Sales Cloud 2017 Implementation Essentials 1Z0-970",
        "title": "Oracle Sales Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:499",
        "acronym": "p_id:499"
    },
    {
        "exam": "Oracle Sales Performance Management Cloud Service 2017 Implementation Essentials 1Z0-979",
        "title": "Oracle Sales Cloud",
        "description": "\n\tAn Oracle Sales Performance Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to implement, configure and use Oracle Sales Performance Management Cloud. Individuals who earn this certification are able to identify key concepts and configuration components of sales performance management, Quota management, Sales Forecasting, Territory management, configure territory and resource assignment, configure compensation plans, design crediting and roll up processes and build advanced plan structures and calculations.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:519",
        "acronym": "p_id:519"
    },
    {
        "exam": "Oracle Cross-Channel Contact Center Cloud 2017 Implementation Essentials 1Z0-981",
        "title": "Oracle Service Cloud",
        "description": "\n\tAn Oracle Cross-Channel Contact Center Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to create queues and assignments, implement automation and customization, set up process between Customers and Agents and manage Reports. Individuals who earn this certification  are able to integrate Cross-Channel Contact Center with Connect-SOAP and Connect-REST based web services and configure add-ins.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:521",
        "acronym": "p_id:521"
    },
    {
        "exam": "Oracle Field Service Cloud Service 2017 Implementation Essentials 1Z0-628",
        "title": "Oracle Service Cloud",
        "description": "\n\tAn Oracle Field Service Cloud Service 2017 Certified Implementation Specialist has demonstrated the skills and knowledge required  to implement and use Oracle Field Service Cloud. Individuals who posses this certification are able to configure users and activity types, configure business rules settings and work zones, manage routing and quota and configure service requests.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:513",
        "acronym": "p_id:513"
    },
    {
        "exam": "Oracle Knowledge Management Cloud 2017 Implementation Essentials 1Z0-980",
        "title": "Oracle Service Cloud",
        "description": "\n\tAn Oracle Knowledge Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to use and modify answers, set up reports and configure queues. Individuals who earn this certification are able to integrate with Connect Web Services for SOAP API and use Knowledge Advanced.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:520",
        "acronym": "p_id:520"
    },
    {
        "exam": "Oracle Policy Automation Cloud Service 2017 Implementation Essentials 1Z0-345",
        "title": "Oracle Service Cloud",
        "description": "\n\tAn Oracle Policy Automation Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to get started with rules / user Interface, use attributes and attribute text, write rules and negations, group operators and intermediate attributes. Individuals who earn this certification are able to use comparisons and calculations in rules, know when to use entities and reasoning with entities, test rules, create and enhance interviews, validate data and plan a Policy Automation project.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:481",
        "acronym": "p_id:481"
    },
    {
        "exam": "Oracle RightNow Cloud Service 2016 Implementation Essentials 1Z0-325",
        "title": "Oracle Service Cloud",
        "description": "\n\tThe Oracle RightNow Cloud Service 2016 Implementation Essentials (1Z1-325) exam is designed for individuals who possess a strong foundation and expertise in selling or implementing Oracle RightNow CX Cloud Service solutions.  This certification exam covers topics such as: Application Appearance, Staff Management, Answer Management Admin, Site Configuration Admin, Analytics, Surveys, Customer Portal, Chat & Co-browse. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:404",
        "acronym": "p_id:404"
    },
    {
        "exam": "Oracle RightNow Cloud Service 2016 Technical Implementation Essentials 1Z0-326",
        "title": "Oracle Service Cloud",
        "description": "\n\tThe Oracle RightNow Cloud Service 2016 Technical Implementation Essentials (1Z1-326) exam is designed for individuals who possess a strong foundation and expertise in selling or implementing Oracle RightNow CX Cloud Service solutions.  This certification exam covers topics such as: CP Tools, CP - Basic Customizations,  CP - Widgets and Scripting, CP - Advanced Customizations, Integration & Customization - Agent Desktop, Connect Web Services for PHP\\SOAP\\REST, Desktop Integration and General Integration & Customization. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:410",
        "acronym": "p_id:410"
    },
    {
        "exam": "Oracle Social Relationship Management Cloud Service 2014 Essentials 1Z0-426",
        "title": "Oracle Service Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:324",
        "acronym": "p_id:324"
    },
    {
        "exam": "Oracle Enterprise Performance Reporting Cloud Service 2017 Implementation Essentials 1Z0-985",
        "title": "Enterprise Performance Reporting Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:528",
        "acronym": "p_id:528"
    },
    {
        "exam": "Oracle Enterprise Planning and Budgeting Cloud Service 2017 Implementation Essentials 1Z0-982",
        "title": "Enterprise Planning and Budgeting Cloud",
        "description": "\n\tThe Oracle Enterprise Planning and Budgeting Cloud 2017 Certified Implementation Specialist has demonstrated the knowldege and expertise in implementing Enterprise Performance Management and Enterprise Planning and Budgeting Cloud solutions. Inviduals with this certification can set up and configure Cloud security, build EPM Cloud automation routines and integrations across systems and services, set up a Planning application, diagnose enabled business process features for impact on application element, and configure Financials/Workforce/Capital business process elements, and designing Reports and Documents.  ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:529",
        "acronym": "p_id:529"
    },
    {
        "exam": "Oracle Financial Consolidation and Close Cloud Service 2017 Implementation Essentials 1Z0-983",
        "title": "Financial Consolidation and Close Cloud",
        "description": "\n\tThe Oracle Financial Consolidation and Close 2017 Certified Implementation Specialist has demonstrated the knowledge and skills in implementing Enterprise Performance Management and Oracle Financial Consolidation and Close Cloud solutions. Inviduals holding this certification are able to set up and configure Cloud security, build EPM Cloud automation routines, creating a FCCS Application, vreate data sets and dimension attributes, set up a close process, and design reports using Financial Reporting Studio. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:530",
        "acronym": "p_id:530"
    },
    {
        "exam": "Oracle Profitability and Cost Management Cloud Service 2017 Implementation Essentials 1Z0-984",
        "title": "Profitability and Cost Management Cloud",
        "description": "\n\tAn Oracle Profitability and Cost Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge and skills of implementing Enterprise Performance Management and Oracle Profitability and Cost Management Cloud solutions.  This individual understands Profitability and Cost Management Cloud system architecture and requirements, can set up and configure Cloud security, build EPM Cloud automation routines, perform lifecycle management for different scenarios, create a PCMCS application, create and manage Allocations,and design reports using Financial Reporting Studio.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:534",
        "acronym": "p_id:534"
    },
    {
        "exam": "Oracle Accounting Hub Cloud 2017 Implementation Essentials 1Z0-975",
        "title": "Oracle Financials Cloud",
        "description": "\n\tAn Oracle Accounting Hub Cloud Service 2017 Certified Implementation Specialist demonstrated a strong foundation and expertise implementing  Enterprise Resource Planning (ERP) and Fincancials Cloud  solutions in Accounting Hub Cloud Services. Individuals who earn this certification can analyze transaction type life cycle, analyze accounting/reporting/audit/reconciliation requirements for accounting transactions, model considerations, configure Accounting Rules, Register Source System and PaaS (ICS) integration, update the Registered Source System, perform automation with web services,  adjust journal entries, and perform diagnostics.  This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:523",
        "acronym": "p_id:523"
    },
    {
        "exam": "Oracle Financials Cloud: General Ledger 2017 Implementation Essentials 1Z0-960",
        "title": "Oracle Financials Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:501",
        "acronym": "p_id:501"
    },
    {
        "exam": "Oracle Financials Cloud: Payables 2017 Implementation Essentials 1Z0-961",
        "title": "Oracle Financials Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:498",
        "acronym": "p_id:498"
    },
    {
        "exam": "Oracle Financials Cloud: Receivables 2017 Implementation Essentials 1Z0-962",
        "title": "Oracle Financials Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:502",
        "acronym": "p_id:502"
    },
    {
        "exam": "Oracle Revenue Management Cloud Service 2017 Implementation Essentials 1Z0-974",
        "title": "Oracle Financials Cloud",
        "description": "\n\tAn Oracle Revenue Management Cloud 2017 Certified Implementation Specialist possesses a strong foundation and expertise implementing  Enterprise Resource Planning (ERP) and Financials Cloud  solutions in Revenue Management. Individuals earning this certification can configureRevenue Management Cloud services, implement Revenue Management Cloud, set up Revenue Management and configuring standalone pricing, and manage contracts and Revenue. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:535",
        "acronym": "p_id:535"
    },
    {
        "exam": "Oracle Procurement Cloud 2017 Implementation Essentials 1Z0-963",
        "title": "Oracle Procurement Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:497",
        "acronym": "p_id:497"
    },
    {
        "exam": "Oracle Project Portfolio Management Cloud 2017 Implementation Essentials 1Z0-964",
        "title": "Oracle Project Portfolio Management Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:500",
        "acronym": "p_id:500"
    },
    {
        "exam": "Oracle Financial Reporting Compliance Cloud 2017 Implementation Essentials 1Z0-951",
        "title": "Oracle Risk Management Cloud",
        "description": "\n\tAn Oracle Financial Reporting Compliance Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to gather implementation requirements for Financial Reporting Compliance Implementation, perform initial configuration and data migration. Individuals who earn this certification are able to manage Assessments, Issues and Reports in Oracle Risk Management Cloud.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:514",
        "acronym": "p_id:514"
    },
    {
        "exam": "Oracle Absence Management Cloud 2017 Implementation Essentials 1Z0-967",
        "title": "Oracle Global Human Resources Cloud",
        "description": "\n\tAn Oracle Absence Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to implement, configure and use Oracle Absence Management Cloud. Individuals who earn this certification are able to identify key concepts of absence management, identify the setup components of absence management and create accrual and qualification absence plans.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:511",
        "acronym": "p_id:511"
    },
    {
        "exam": "Oracle Global Human Resources Cloud 2017 Implementation Essentials 1Z0-965",
        "title": "Oracle Global Human Resources Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:494",
        "acronym": "p_id:494"
    },
    {
        "exam": "Oracle Time and Labor Cloud 2017 Implementation Essentials 1Z0-968",
        "title": "Oracle Global Human Resources Cloud",
        "description": "\n\tAn Oracle Time and Labor Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to implement, configure and use Oracle Time and Labor Cloud. Individuals who earn this certification are able to use Oracle Functional Setup Manager to implement Time and Labor, perform common Time and Labor processes, and perform transactional tasks such as reporting, approving and tranferring time.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:512",
        "acronym": "p_id:512"
    },
    {
        "exam": "Oracle Workforce Compensation Cloud 2017 Implementation Essentials 1Z0-330",
        "title": "Oracle Global Human Resources Cloud",
        "description": "\n\tThe Oracle Workforce Rewards Cloud 2016 Implementation Essentials exam is designed for individuals who possess a strong foundation and expertise in selling and implementing Oracle HCM Cloud Service solutions. This certification exam covers topics such as: Base Pay, Individual Compensation, Workforce Compensation and Total Compensation Statements. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:406",
        "acronym": "p_id:406"
    },
    {
        "exam": "Oracle Talent Acquisition Cloud 2016 Implementation Essentials 1Z0-324",
        "title": "Oracle Talent Management Cloud",
        "description": "\n\tThe Oracle Talent Acquisition Cloud 2016 Certified Implementation Essentials exam is designed for individuals who possess a strong foundation and expertise in selling and implementing Oracle Talent Management Cloud Service solutions. This certification exam covers topics such as: SmartOrg Configuration, Career Site Configuration, ACE Prescreening and Workflow design. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:401",
        "acronym": "p_id:401"
    },
    {
        "exam": "Oracle Talent Management Cloud 2017 Implementation Essentials 1Z0-966",
        "title": "Oracle Talent Management Cloud",
        "description": "\n\tThe Oracle Talent Management Cloud 2017 Implementation Essentials (1Z0-966) exam is designed for individuals who possess a strong foundation and expertise in selling and implementing Oracle HCM Cloud Service solutions. This certification exam covers topics such as: Goals, Questionnaires, Performance, Fusion Succession Plans and Talent Pools, Talent Review. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:496",
        "acronym": "p_id:496"
    },
    {
        "exam": "Oracle Taleo Learn Cloud Service 2016 Implementation Essentials 1Z0-443",
        "title": "Oracle Talent Management Cloud",
        "description": "\n\tOracle Taleo Learn Cloud Service 2016 Certified Implementation Specialist",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:387",
        "acronym": "p_id:387"
    },
    {
        "exam": "Oracle Payroll Cloud 2017 Implementation Essentials 1Z0-969",
        "title": "Payroll",
        "description": "\n\tAn Oracle Payroll Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to set up Oracle Payroll Cloud using Workforce Deployment tasks offered in the functional setup manager.  Individuals who earn this certification are able to identify the key concepts of Oracle Payroll Cloud that determine a successful implementation, test your setup by entering data and performing common payroll processes, use Oracle Payroll Cloud Functional Setup Manager (FSM) to implement payroll and much more.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:510",
        "acronym": "p_id:510"
    },
    {
        "exam": "Oracle Benefits Cloud 2017 Implementation Essentials 1Z0-973",
        "title": "Workforce Rewards Cloud",
        "description": "\n\tAn Oracle Benefits Cloud 2017 Certified Implementation Specialist has demonstrated the konwledge and skills required to configure and implement Oracle HCM Cloud Benefits Service solutions. Individuals who earn this certification are able to configure and administer Benefits, set up benefits extracts and extract and transmit data to plan carriers.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:524",
        "acronym": "p_id:524"
    },
    {
        "exam": "Oracle Transportation Management Cloud Service 2017 Implementation Essentials 1Z0-988",
        "title": "Logistics Cloud",
        "description": "\n\tAn Oracle Transportation Management Cloud Service 2017 Certified Implementation Specialist has demonstrated the knwledge required to explain Oracle Transportation Management core functionalities and configure the User Interface. Individuals who earn this certification are able to perform Freight Payment and Data Management, configure Automation Agents and the Shipment Planning process and create Order bases and Order Releases.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:522",
        "acronym": "p_id:522"
    },
    {
        "exam": "Oracle Warehouse Management Cloud 2018 Implementation Essentials 1Z0-956",
        "title": "Logistics Cloud",
        "description": "\n\tDigital badge description: An Oracle Warehouse Management Cloud 2018 Certified Implementation Specialist has demonstrated the knowledge required to Setup Parent-Child Company Hierarchy, Inbound process, execute Outbound orders and manage Inventory. Individuals who earn this certification are able to create Web reports, Perform Picking, Packing, Cycle Count, Check-in and receive Loads, Appointments.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:540",
        "acronym": "p_id:540"
    },
    {
        "exam": "Oracle Cost Management Cloud 2017 Implementation Essentials 1Z0-987",
        "title": "Oracle Inventory Management Cloud",
        "description": "\n\tAn Oracle Cost Management 2017 Certified Implementation Specialist has demonstrated the knowledge required to manage landed costs, manage subledger accounting, manage cost scenarios, and configure costing methods.  Individuals who earn this certification are able to perform receipt accounting processes and analyze and resolve standard cost variances.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:526",
        "acronym": "p_id:526"
    },
    {
        "exam": "Oracle Inventory Cloud 2017 Implementation Essentials 1Z0-986",
        "title": "Oracle Inventory Management Cloud",
        "description": "\n\tAn Oracle Inventory Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to configure an enterprise structure, design an enterprise configuration, model a financial reporting structure, and manage advanced inventory transactions.  Individuals who earn this certification are able to analyze and manage the material request flow within Supply Chain Orchestration, and import on-hand balances, sub-inventories, and transfer orders via ADF Desktop Integration.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:517",
        "acronym": "p_id:517"
    },
    {
        "exam": "Oracle Manufacturing Cloud 2017 Implementation Essentials 1Z0-348",
        "title": "Oracle Manufacturing Cloud",
        "description": "\n\tAn Oracle Manufacturing Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to use Manufacturing Cloud on a project, utilize manufacturing functionalities, create work order, manage work definitions, and execute production.  Individuals who earn this certification are able to perform manufacturing set-up, manage items and item structures, and understand  the complexities of manufacturing integrations.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:490",
        "acronym": "p_id:490"
    },
    {
        "exam": "Oracle Innovation Management Cloud 2017 Implementation Essentials 1Z0-991",
        "title": "Oracle Product Lifecycle Management Cloud",
        "description": "\n\tAn Oracle Innovation Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge and skills required to implementing Oracle Innovation Management Cloud and customize it as per customer requirements. Individuals who earn this certification are able to create Proposals, Portfolios, custom Idea class and custom attributes for Requirements, as well as to configure integration with external systems.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:532",
        "acronym": "p_id:532"
    },
    {
        "exam": "Oracle Product Hub Cloud 2016 Implementation Essentials (Retiring May 31st, 2018) 1Z0-634",
        "title": "Oracle Product Master Data Management Cloud",
        "description": "\n\t This certification retires May 31st, 2018. \n\tOracle Product Hub Cloud enables organizations to take control of their product master data across their entire portfolio of applications. With Oracle Fusion Product Hub, product information is shared across systems and users, resulting in improved data accuracy, better decisions, and accelerated time to market.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:388",
        "acronym": "p_id:388"
    },
    {
        "exam": "Oracle Product Hub Cloud 2017 Implementation Essentials 1Z0-972",
        "title": "Oracle Product Master Data Management Cloud",
        "description": "\n\tAn Oracle Product Hub Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to configure and invoke data validation, transformation, and map features and rules.  Individuals who earn this certification are able to configure and manage supplier data and manage security for transactional business intelligence.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:531",
        "acronym": "p_id:531"
    },
    {
        "exam": "Oracle Planning Central Cloud Service 2017 Implementation Essentials 1Z0-992",
        "title": "Oracle Supply Chain Planning Cloud",
        "description": "\n\tAn Oracle Planning Central Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to configure and manage a demand plan, configure and manage a supply plan, and manage data collection and analytics.  Individuals who earn this certification are well-versed in managing exceptions, working with advanced fulfillment, and using simulation sets.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:533",
        "acronym": "p_id:533"
    },
    {
        "exam": "Oracle Configurator Cloud 2017 Implementation Essentials 1Z0-989",
        "title": "Order Management Cloud",
        "description": "\n\tAn Oracle Configurator Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to work with Configurator Models, Structures, Snapshots, and Rules. Individuals who earn this certification are able to customize user interfaces, use and create extension rules, and manage configured item exceptions.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:525",
        "acronym": "p_id:525"
    },
    {
        "exam": "Oracle Order Management Cloud 2017 Implementation Essentials 1Z0-347",
        "title": "Order Management Cloud",
        "description": "\n\tAn Oracle Order Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to use Order Management Cloud on a project, utilize Order Entry functionalities, create basic reports, pricing startegies and setup shipping.  Individuals who earn this certification are able to understand interfaces and implement Order to cash business process, use different pricing functionalities, perform shipping related inventory transactions.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:477",
        "acronym": "p_id:477"
    },
    {
        "exam": "Oracle Pricing Cloud 2017 Implementation Essentials 1Z0-990",
        "title": "Order Management Cloud",
        "description": "\n\tAn Oracle Pricing Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to create and manage price lists, costs lists, tiers and matrices, pricing charge definitions and parameters.  Individuals who earn this certification are able to create and maintain pricing algorithms, service mappings, and matrix classes.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:518",
        "acronym": "p_id:518"
    },
    {
        "exam": "Oracle Big Data 2017 Implementation Essentials (Retiring October 30, 2019) 1Z0-449",
        "title": "Big Data Appliance",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:416",
        "acronym": "p_id:416"
    },
    {
        "exam": "Oracle Exadata X3 and X4 Administration 1Z0-027",
        "title": "Exadata",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:179",
        "acronym": "p_id:179"
    },
    {
        "exam": "Oracle Exadata X5 Administration 1Z0-070",
        "title": "Exadata",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:465",
        "acronym": "p_id:465"
    },
    {
        "exam": "Oracle Exadata Database Machine 2014 Implementation Essentials 1Z0-485",
        "title": "Exadata",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:250",
        "acronym": "p_id:250"
    },
    {
        "exam": "Oracle Exadata Database Machine and Cloud Service 2017 Implementation Essentials 1Z0-338",
        "title": "Exadata",
        "description": "\n\tAn Oracle Exadata Database Machine and Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to administer and configure the Exadata system. They can architect Exadata backup and recovery as well as high availability.  They can load data, monitor the system, and provide software maintenance of the Exadata Database Machine. Individuals who earn this certification are able to describe the Exadata Architecture and it’s benefits, configure security policies, set up service requests, and configure Oracle Exadata Database machine in the cloud.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:482",
        "acronym": "p_id:482"
    },
    {
        "exam": "Exalogic Elastic Cloud X2-2 Essentials (Retiring May 31st, 2018) 1Z0-569",
        "title": "Exalogic Elastic Cloud",
        "description": "\n\tThis certification retires May 31st, 2018. \n\tExalogic Elastic Cloud X2-2 Essentials exam is intended for system administrators who have implemented and are managing an Exalogic Elastic Cloud environment in a data center. The exam targets a broad range of topics from fundamentals and initial machine setup to storage and network configuration. In addition to on-the-job training, preparation can include attending Oracle University's Oracle Exalogic Elastic Cloud Administration course.\n\tThis certification is available to all candidates, but it is geared toward members of the Oracle PartnerNetwork. OPN Members earning this certification will be recognized as OPN Certified Specialists. This certification helps their companies qualify for the Exalogic Elastic Cloud Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:91",
        "acronym": "p_id:91"
    },
    {
        "exam": "Oracle Exalogic Elastic Cloud 2014 Implementation Essentials 1Z0-491",
        "title": "Exalogic Elastic Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:271",
        "acronym": "p_id:271"
    },
    {
        "exam": "Exalytics In-Memory Machine X3-4 Implementation Essentials (Retiring July 31, 2018) 1Z0-484",
        "title": "Exalytics",
        "description": "\n\tThis certification retires July 31, 2018. \n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:261",
        "acronym": "p_id:261"
    },
    {
        "exam": "Fujitsu M10 Servers Installation Essentials 1Z0-488",
        "title": "Server Administration",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:255",
        "acronym": "p_id:255"
    },
    {
        "exam": "SPARC M6-32 and SPARC M5-32 Servers Installation Essentials 1Z0-489",
        "title": "Server Administration",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:263",
        "acronym": "p_id:263"
    },
    {
        "exam": "SPARC T4-Based Server Installation Essentials 1Z0-597",
        "title": "Server Administration",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:92",
        "acronym": "p_id:92"
    },
    {
        "exam": "Oracle FS1 Series Systems Implementation Essentials (retiring May 31st, 2018) 1Z0-429",
        "title": "Oracle All Flash Storage System",
        "description": "\n\t'This certification retires May 31st, 2018. \n\tThe Oracle FS1 Series Systems consist of high-availability components, providing connectivity between storage resources and external application hosts. These components are tightly integrated and managed via the Oracle FS System Services software (formerly known as Axiom software release 6.0), enabling intuitive storage management, configuration, and monitoring.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:349",
        "acronym": "p_id:349"
    },
    {
        "exam": "Oracle ZFS Storage Appliance 2017 Implementation Essentials 1Z0-499",
        "title": "Oracle ZFS Storage Appliance",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:485",
        "acronym": "p_id:485"
    }
]