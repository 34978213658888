import { useCallback, useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { debounce } from "@mui/material/utils"

import { getLocationInfoRequest, getLocationListRequest } from "./redux/action"

const useLocation = props => {
  const { type } = props || { type: "address" }
  const locationList = useSelector(state => state.locationData.locationList)
  const locationListRequesting = useSelector(
    state => state.locationData.locationListRequesting
  )
  const locationDetails = useSelector(state => state.locationData.locationInfo)
  const locationInfoRequesting = useSelector(
    state => state.locationData.locationInfoRequesting
  )
  const [locationOptions, setLocationOptions] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    setLocationOptions(locationList)
  }, [locationList])

  const fetchLocationList = useMemo(
    () =>
      debounce(
        searchText =>
          dispatch(getLocationListRequest({ location: searchText, type })),
        1000
      ),
    [dispatch, type]
  )

  const fetchLocationInfo = useCallback(
    placeId => {
      dispatch(getLocationInfoRequest({ placeId }))
    },
    [dispatch]
  )

  return {
    locationOptions,
    setLocationOptions,
    locationListRequesting,
    locationDetails,
    locationInfoRequesting,
    fetchLocationList,
    fetchLocationInfo
  }
}

export default useLocation
