/*

From https://learning.sap.com/certifications

*/

export const SAP_CERTIFICATIONS = [
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "architect",
        "description": "<p>This certification verifies that you possess a sound skills and understanding of the features and architecture of SAP Customer Experience Solutions and SAP BTP. The Associate Architect, with two or more years of experience in SAP Customer Experience solutions, can effectively perform and provide guidance on various project tasks within a project team.</p><p>&nbsp;</p><p><span>Note: To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.”</span></p>",
        "title": "SAP Certified Associate - Solution Architect - Customer Experience",
        "templateId": "5c10f868-0b03-48ab-a091-7fed3cd6c56f",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/696bf047-4021-464d-8b0c-149ec84aaa6a/image.png",
        "objId": "C_C4HCX",
        "id": "aa7adf65-1bc6-4e03-a31d-1b9007189eee",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-solution-architect-customer-experience",
        "acronym": "C_C4HCX"
    },
    {
        "languages": [
            "English",
            "Japanese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the fundamental knowledge required to explain and execute core implementation project tasks to deploy, adopt and extend SAP S/4HANA Cloud Public Edition as well as core knowledge in Financial Accounting. It proves that the candidate has an overall understanding and in‐depth technical skills to participate as a member of a SAP S/4HANA Cloud Public Edition implementation project team with a focus on Financial Accounting in a mentored role.</p><p>&nbsp;</p><p><strong>Please note:</strong>&nbsp;To prepare for this certification, it is necessary to take Learning Journey&nbsp;<a rel=\"noopener\" data-auth=\"NotApplicable\" data-linkindex=\"8\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/implementing-sap-s-4-hana-cloud-public-edition\">https://learning.sap.com/learning-journeys/implementing-sap-s-4-hana-cloud-public-edition</a>&nbsp;<strong>in addition</strong>&nbsp;to the Learning Journey(s) displayed under \"how to prepare\".</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP S/4HANA Cloud Public Edition, Financial Accounting",
        "templateId": "7af6434d-dc29-4609-9203-bf1a9682618d",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/bafb24fe-feda-40bd-912e-e7cdf62429f9/image.png",
        "objId": "C_S4CFI",
        "id": "6ec5532d-267f-4e3e-ae77-650c5c38f177",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-sap-s-4hana-cloud-public-edition-financial-accounting",
        "acronym": "C_S4CFI"
    },
    {
        "languages": [
            "English",
            "Chinese",
            "Japanese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "60",
        "description": "<p>This certification verifies that you possess the core skills required to explain and execute core implementation project tasks to deploy, adopt, and extend SAP S/4HANA Cloud Public Edition. This certification is designed for implementation project members to prove their overall understanding and in-depth skills to participate in their role as members of a SAP S/4HANA Cloud Public Edition implementation project.</p><p>&nbsp;</p><p><b><span>Please note</span></b><span>: To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/explore-sap-cloud-erp\">Exploring SAP Cloud ERP</a> in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP S/4HANA Cloud Public Edition",
        "templateId": "1dd54f86-cf5c-46ab-9cd8-b12f4203689f",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/e756fd7d-c106-4249-87b1-b342f3c3b873/image.png",
        "objId": "C_S4CPB",
        "id": "7f5a7137-66fc-45ed-976d-f9ea9ef6d88c",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-sap-s-4hana-cloud-public-edition",
        "acronym": "C_S4CPB"
    },
    {
        "level": "Associate",
        "stayCertified": "false",
        "release": "1",
        "validForYears": "5",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>The \"SAP Certified Application Associate - SAP S/4HANA Asset Management\" certification exam verifies that the candidate has the fundamental and core knowledge in the area of Asset Management required for the consultant profile. This certificate proves that the candidate has an overall understanding and can implement the knowledge practically in projects to contribute to the success of the planning and implementation phases in a mentored role. Previous project experience is not necessarily a prerequisite to pass this exam.</p>\n<p>This certification exam is relevant for SAP S/4HANA Cloud, private edition and SAP S/4HANA on-premise.</p>",
        "title": "SAP Certified Application Associate - SAP S/4HANA Asset Management 2021",
        "duration": 180,
        "objId": "C_TS413_2021",
        "id": "998c82e9-06e5-4ee5-9cee-ebfee9d174d5",
        "robots": "ALL",
        "objType": "certification",
        "slug": "sap-certified-application-associate-sap-s-4hana-asset-management-2021",
        "acronym": "C_TS413_2021"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess SAP Customer Data Platform the cores and core skills in the SAP Customer Data Solutions line of business area required of the consultant profile and proves that you have an overall understanding and in depth technical skills to participate as a member of an SAP Customer Data Platform implementation project team. The certification is recommended as an entry-level qualification.</p><p>&nbsp;</p><ul style=\"margin-top: 0in; margin-bottom: 0in;\"><li style=\"margin-top: 0in; margin-right: 0in; margin-bottom: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\"><b>Note:</b> To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li></ul>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Customer Data Platform",
        "templateId": "391aa7c1-1fee-438d-a77b-b8011a895db3",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/d48b0952-a79c-4b98-aaf9-701bc701c7d0/image.png",
        "objId": "C_C4H63",
        "id": "0f2d9e91-7b12-4d5e-8dd4-e581cb3c6ea3",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-developer-sap-customer-data-platform",
        "acronym": "C_C4H63"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills required for the development consultant profile and proves that you have an overall understanding and in-depth technical skills to participate as a member of an SAP Customer Data Cloud project team in a mentored role. The certification is recommended as an entry-level qualification.</p><p>&nbsp;</p><p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Customer Data Cloud",
        "templateId": "2dec3f0f-174c-42fe-9c3f-be4df943e6a6",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/6c4564d3-5fe3-4662-86f4-3480546f085a/image.png",
        "objId": "C_C4H62",
        "id": "9ca487be-0c4f-4dc4-8e20-0d33e1a8afa1",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-developer-sap-customer-data-cloud",
        "acronym": "C_C4H62"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills to be able to integrate SAP Sales Cloud and SAP Service Cloud with SAP S/4HANA and SAP ERP using SAP Cloud Integration and proves that you have the core understanding within the Integration Consultant profile and are able to apply the associated prerequisite skills practically under supervision of an expert consultant in a project environment.</p><p>&nbsp;</p><p>Note: To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.”</p>",
        "title": "SAP Certified Associate - Integration Consultant - SAP Sales and Service Cloud",
        "templateId": "4ed0ad93-d7c0-406f-a4fc-b3bcd4ca285f",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/f73f6256-cb6d-4d18-a74b-618ee2a78d18/image.png",
        "objId": "C_C4H45",
        "id": "9fea8713-2c65-429b-a687-158557f3dc67",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-integration-consultant-sap-sales-and-service-cloud",
        "acronym": "C_C4H45"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP SuccessFactors Performance and Goals. This certification is designed for SAP partner consultants implementing SAP SuccessFactors Performance and Goals, allowing them to apply their skills in projects under experienced consultant guidance. Registered SAP Partner consultants receive provisioning rights, while customers and independent consultants do not.</p>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>\n<p><span>&nbsp;</span></p>\n<p><span><b>Note:</b> The next version of this exam should be available between December 16-19, 2024. </span></p>\n<p></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Performance and Goals",
        "templateId": "0cc43c67-2aad-4df6-96ec-5c9029c08ed7",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/95ef31c7-4b68-4801-bc04-a72b51cf0283/image.png",
        "objId": "C_THR82",
        "id": "e62f96ca-c2b2-4c06-a6ac-7b1ca58a8f74",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-performance-and-goals",
        "acronym": "C_THR82"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "administrator",
        "description": "<p>This certification verifies that you possess the core skills required for the technology consultant profile and proves that the candidate has an overall understanding and can implement the skills practically in projects to contribute to the success of the planning and implementation phases in a mentored role. Previous project experience is not necessarily a prerequisite to pass this .</p>",
        "title": "SAP Certified Associate Implementation Consultant - SAP Emarsys Customer Engagement",
        "templateId": "f2dc3e3d-c839-48d1-be65-947120699bd8",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/7c6e4bca-3936-46f7-9ac9-cb826e0ca8f8/image.png",
        "objId": "C_C4H22",
        "id": "c1df5f26-036a-436c-b288-b002828d125c",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-sap-emarsys-customer-engangement",
        "acronym": "C_C4H22"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "developer",
        "description": "<p>This certification verifies that you possess the core skills required for the consultant profile and proves that you have an overall understanding and in depth technical skills to participate as a member of an SAP Fiori team with a focus on system administration in a mentored role. The certification is recommended as an entry-level qualification.</p>",
        "title": "SAP Certified Associate - SAP Fiori System Administration",
        "templateId": "60c15824-74d8-43f1-9f1e-d997dfe90668",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/b53dc8c2-cf86-4f11-bd28-36e6bc515f6d/image.png",
        "objId": "C_FIOAD",
        "id": "b8f67dcb-63b4-43b7-8404-f4dc1fa542ad",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-fiori-system-administration",
        "acronym": "C_FIOAD"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills required of the SAP SuccessFactors for Employee Central Payroll profile and proves that you have an overall understanding and in?depth technical skills to participate as a member of a project team in a mentored role. The certification is recommended as an entry-level qualification.<br>*Consultants implementing Employee Central Payroll are required to hold valid credentials for both C_HCMPAY and C_HRHPC.</p>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span><span></span></p>\n<p><span>&nbsp;</span></p>\n<p><span><b>Note:</b> The next version of this exam should be available between December 16-19, 2024. </span></p>",
        "title": "SAP Certified Associate - SAP SuccessFactors for Employee Central Payroll",
        "templateId": "3716a9d3-0709-4691-9d4e-89bad1c57248",
        "duration": 180,
        "validForDays": "1",
        "imageUrl": "https://images.credly.com/size/200x200/images/8b7ceda3-5124-4214-a4a0-c35fa31467ed/image.png",
        "objId": "C_HRHPC",
        "id": "8da5a4a4-1197-4366-bbb7-f7ebe00ab5b9",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-for-employee-central-payroll",
        "acronym": "C_HRHPC"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "developer",
        "description": "<p>This certification exam validates that the candidate possesses the fundamental and core knowledge required of the SAP Integration Developer profile. This certification proves that the candidate has an overall understanding and in depth technical skills to participate as a member of a project team in a mentored role.</p>",
        "title": "SAP Certified Associate - Integration Developer",
        "templateId": "75dca71c-0467-487c-a5ee-5b51517198b0",
        "duration": 120,
        "imageUrl": "https://images.credly.com/size/400x400/images/d98ec6e8-4c61-4654-bb73-e511b326e632/image.png",
        "objId": "C_CPI",
        "id": "403ef7cb-7103-4a03-a4d2-d4ce5a60c808",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-integration-developer",
        "acronym": "C_CPI"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP SuccessFactors Workforce Analytics. This certification is designed for SAP partner consultants implementing SAP SuccessFactors Workforce Analytics and Planning, allowing them to apply their skills in projects under experienced consultant guidance, and only granting provisioning rights to registered SAP Partner consultants.</p><p>&nbsp;</p><ul style=\"margin-top: 0in; margin-bottom: 0px;\"><li><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li></ul><p>&nbsp;</p><p><b><span>Note:</span></b><span> The next version of this exam should be available between December 16-19, 2024.</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Workforce Analytics",
        "templateId": "68f7cacb-1cef-40d9-9817-34f619f847f0",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/bb949c89-affc-4d4e-9fbb-8d5c5b2be695/image.png",
        "objId": "C_THR89",
        "id": "e3a5bd9a-083a-4ae0-9e99-7d115b0d571b",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-workforce-analytics-and-planning-functional-consultant",
        "acronym": "C_THR89"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the core skills in business process and high level configuration required of an SAP Billing and Revenue Innovation Management - Subscription Order Management associate consultant and proves that you have an overall foundational understanding to participate as a member of an SAP Billing and Revenue Innovation Management - Subscription Order Management project team in a mentored role and is recommended as an entry-level qualification.</p>",
        "title": "SAP Certified Associate - SAP Billing and Revenue Innovation Mgmt. - Subscription Order Management",
        "templateId": "820723ae-0bf0-4502-a699-319a61f47dd3",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/33bcc78d-4874-473d-9707-80179798fb54/image.png",
        "objId": "C_BRSOM",
        "id": "97525464-3ebd-4113-9c06-834db7fb6441",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-billing-and-revenue-innovation-mgmt-subscription-order-management",
        "acronym": "C_BRSOM"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the core skills in business process and high level configuration required of an SAP Billing and Revenue Innovation Management - Usage to Cash associate consultant and proves that the candidate has an overall foundational understanding to participate as a member of an SAP Billing and Revenue Innovation Management - Usage to Cash  project team in a mentored role. This certification is recommended as an entry-level qualification.</p>",
        "title": "SAP Certified Associate - SAP Billing and Revenue Innovation Management - Usage to Cash",
        "templateId": "f2ddc7cb-4f15-47ed-ab6e-55afc3807c8e",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/86eb5248-b218-45ff-822b-0c204263872d/image.png",
        "objId": "C_BRU2C",
        "id": "e2366142-0f13-41b5-b326-acc85fff16de",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-billing-and-revenue-innovation-management-usage-to-cash",
        "acronym": "C_BRU2C"
    },
    {
        "languages": [
            "English",
            "Japanese",
            "Korean",
            "Chinese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the core skills required of the SAP Business ByDesign Solution Consultant profile and proves that the candidate has an overall understanding and in?depth technical skills to participate as a member of a project team in a mentored role. The certification is recommended as an entry-level qualification.</p>",
        "title": "SAP Certified Associate - SAP Business ByDesign Implementation Consultant",
        "templateId": "80dff9dc-90e7-4db0-b009-da730d567f1c",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/79085f6e-d07f-4faf-ba18-13b9bf2d393a/image.png",
        "objId": "C_BYD15",
        "id": "d28123a9-eef0-4557-9047-48b6bcf4aa96",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-business-bydesign-implementation-consultant",
        "acronym": "C_BYD15"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "business_user",
        "description": "<p>This certification verifies that you possess the core skills required of an SAP Commerce Cloud Business Analyst, enabling participation in a mentored project team role. It is recommended for entry-level qualifications. Access requires SAP customer or partner registration, material to prepare (the SAP Commerce Cloud  documentation (at: https://help.sap.com/viewer/product/SAP_COMMERCE_CLOUD)\ndespite older versions).</p>",
        "title": "SAP Certified Associate - Business User - SAP Commerce Cloud",
        "templateId": "f6be00f7-0b8b-4658-abe2-5489d8e52189",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/53ea3a3e-8bc7-4ea7-b263-e5f851bb6972/image.png",
        "objId": "C_C4H32",
        "id": "37663f96-7340-4b3b-a587-e6ec365cb1d7",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-business-user-sap-commerce-cloud",
        "acronym": "C_C4H32"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Specialist",
        "stayCertified": "true",
        "release": "1",
        "roles": "project_manager",
        "description": "<p>This certification verifies that the candidate has the knowledge required in the area of SAP Activate agile project management. It validates SAP Cloud-focused advanced business skills and the ability to apply methodologies and tools. It also verifies that the project manager has skills to manage internal and external stakeholders, has basic knowledge of change management processes and can successfully manage the end-to-end transition of the project from Sales to Realization.</p>\n<p></p>\n<p><strong>Please note: </strong>To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/discovering-sap-activate-implementation-tools-and-methodology\">Discovering SAP Activate</a> in addition to the Learning Journey(s) displayed under \"how to prepare\".</p>",
        "title": "SAP Certified Specialist - Project Manager - SAP Activate for Agile Implementation Management",
        "templateId": "6501c5ff-447b-45c7-b3b2-999f770642a0",
        "duration": 90,
        "imageUrl": "https://images.credly.com/size/400x400/images/8150ef30-8449-401e-94a5-e54e965660fd/image.png",
        "objId": "E_ACTAI",
        "id": "f8a23006-7825-4f71-b031-57c491a20d69",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-specialist-project-manager-sap-activate-for-agile-implementation-management",
        "acronym": "E_ACTAI"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "project_manager",
        "description": "<p>This certification verifies that you possess the skills required in the area of SAP project management. It validate project managers' business skills, communication, stakeholder management, change management, successful project transitions, and understanding of testing and quality management, especially for SAP-related projects, requiring the SAP Activate methodology.</p>\n<p></p>\n<p><b>Please note:</b> To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/discovering-sap-activate-agile-project-delivery\">Discovering SAP Activate - Agile Project Delivery</a> in addition to the Learning Journey(s) displayed under \"how to prepare\".</p>",
        "title": "SAP Certified Associate - Project Manager - SAP Activate",
        "templateId": "f2103bc1-2fc8-4b17-a1dc-389e63f83faf",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/d7829924-abbe-42be-bb46-34df2ac777dd/image.png",
        "objId": "C_ACT",
        "id": "301675a0-0bb9-4dc0-b542-bc05ca1fd519",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-project-manager-sap-activate",
        "acronym": "C_ACT"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "data_analyst",
        "questions": "60",
        "description": "<p>This certification is designed for professionals who analyze and enhance business processes based on process data. It confirms that the candidate has fundamental knowledge to conduct thorough investigations and make recommendations for improvements. This role is crucial in the establishment of a business process management practice founded on process data. This certification exam is recommended as an entry level qualification.</p>",
        "title": "SAP Certified Associate - Process Data Analyst - SAP Signavio",
        "templateId": "53363188-357f-4662-97ef-7334f38588de",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/d89aaddd-9430-42d0-9d00-4db087388b28/image.png",
        "objId": "C_SIGDA",
        "id": "e1c5773a-63f4-4844-aaa2-cc59c02adc65",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-process-data-analyst-sap-signavio",
        "acronym": "C_SIGDA"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "60",
        "description": "<p>This certification verifies that you possess the fundamental and core knowledge required of the SAP Build Work Zone implementation and administration profile and proves that you have an overall understanding and in?depth technical skills to participate as a member of a project team in a mentored role. This certificate builds on the basic knowledge gained through related SAP Business Technology Platform and SAP Build Work Zone training.</p>\n<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"color: black; margin-bottom: 12pt; margin-top: 0in; margin-right: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification is also available in the (older) release versions:<span> <a rel=\"noopener\" href=\"https://training.sap.com/certification/c_wzadm_01-sap-certified-associate---sap-build-work-zone---implementation-and-administration-g/?\">C_WZADM_01</a></span></li>\n</ul>",
        "title": "SAP Certified Associate - SAP Build Work Zone - Implementation and Administration",
        "templateId": "06622ec3-3561-4f66-bdaa-757c33438559",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/b7c4ae8a-0728-4a4e-8e06-c716d73bebc2/image.png",
        "objId": "C_WZADM",
        "id": "6899a17a-f633-4493-b2db-750dc0c02b35",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-build-work-zone-implementation-and-administration",
        "acronym": "C_WZADM"
    },
    {
        "languages": [
            "English",
            "German",
            "French",
            "Spanish"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "data_scientist",
        "questions": "80",
        "description": "<p><span>This certification verifies that you possess the core skills required in the profile of modeling, data acquisition, and query design with SAP BW/4HANA and SAP Datasphere and proves that you have an overall understanding and in-depth technical skills to participate as a member of a project team in a mentored role. The certification is recommended as an entry-level qualification.</span></p>\n<p><span>This certification is also available in the (older) release versions:</span><span> </span><span><a rel=\"noopener\" href=\"https://training.sap.com/certification/c_bw4h_211-sap-certified-associate---reporting-modeling-and-data-acquisition-with-sap-bw4hana-g/?\">C_BW4H_211</a></span><span> and </span><span><a rel=\"noopener\" href=\"https://training.sap.com/certification/c_bw4h_214-sap-certified-associate---reporting-modeling-and-data-acquisition-with-sap-bw4hana-g/?\">C_BW4H_214</a></span><span>. <br><b>&nbsp;<br>Please note:</b> To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/explore-sap-datasphere\">Exploring SAP Datasphere</a> and <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/getting-started-building-an-on-premise-data-warehouse-using-sap-bw-4hana\">Building a Data Warehouse</a> in addition to the Learning Journey(s) displayed under \"how to prepare\". </span></p>",
        "title": "SAP Certified Associate - Data Engineer - Data Fabric",
        "templateId": "b5db4d8d-3c44-48ec-a08c-b67021ad5d69",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/6ebc7444-0bc3-4722-812c-f6210f491424/image.png",
        "objId": "C_BW4H",
        "id": "b8afac46-98ee-417a-8db3-fdb92e9eba94",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-reporting-modelling-and-data-acquisition-with-sap-bw-4hana",
        "acronym": "C_BW4H"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "developer",
        "description": "<p>This certification verifies that you possess the required skills in the area of SAP Fiori application development as it pertains to the Frontend Developer profile. This certification builds on the basic knowledge gained by a developer from formal SAP Learning training and preferably refined by practical experience within a SAP Fiori application development project team in a mentored role. This is an entry level certification.</p>",
        "title": "SAP Certified Associate - SAP Fiori Application Developer",
        "templateId": "6a0b59ea-3e01-4033-b622-f3d1d0d8280b",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/8cd2f15f-9865-43fe-9a33-722e175c1b17/image.png",
        "objId": "C_FIORD",
        "id": "e6fc8644-ab8e-4bf3-be37-7e1244a27f60",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-fiori-application-developer",
        "acronym": "C_FIORD"
    },
    {
        "languages": [
            "English",
            "Spanish",
            "Japanese",
            "Portuguese",
            "French",
            "German",
            "Chinese",
            "Korean"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "developer",
        "questions": "80",
        "description": "<p>This certification verifies that you possess ABAP programming language the cores based on ABAP RESTful Application Programming Model on SAP Business Technology Platform and principles of building custom extensions in SAP S/4HANA Cloud required of the back-end developer profile and proves that you have an overall understanding and in?depth skills to participate as a member of project team in a mentored role.</p>",
        "title": "SAP Certified Associate - Back-End Developer - ABAP Cloud",
        "templateId": "bfc0b785-632b-4679-ae27-43ec7836f039",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/65fe9e2c-6cac-4584-a5c3-a65e01516e7d/image.png",
        "objId": "C_ABAPD",
        "id": "cc037fd8-a063-4c96-9a23-f52e0d15f624",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-back-end-developer-abap-cloud",
        "acronym": "C_ABAPD"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess SAP Sales Cloud Version 2 fundamentals and administration knowledge in the product area required of the consultant profile. This certification proves that you have an overall understanding and in-depth skills to participate as a member of project team with a focus on Sales Cloud version 2 in a mentored role.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Sales Cloud Version 2",
        "templateId": "6a8e9740-103f-4e63-ba04-918afe036910",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/1d084c0d-e1f5-4792-bb34-07a1e456778b/image.png",
        "objId": "C_C4H47",
        "id": "b4569b65-2874-4878-aad0-f5d6440a3e34",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-sap-sales-cloud-version-2",
        "acronym": "C_C4H47"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "administrator",
        "description": "<p>This certification verifies that you possess the required database administration skills in SAP HANA Cloud and SAP HANA for the role of an SAP HANA database administrator. This certification is based on the knowledge gained through the related SAP HANA database administration learning journeys or training courses.</p>",
        "title": "SAP Certified Associate - Database Administrator - SAP HANA",
        "templateId": "786d7bc5-f691-4d09-a9b1-843b23bcbd04",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/0c43a7e7-dd77-401c-947e-050436ce4262/image.png",
        "objId": "C_DBADM",
        "id": "cde48fcd-0b8c-4b7d-a735-377ad222e81d",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-database-administrator-sap-hana",
        "acronym": "C_DBADM"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of integrating SAP Ariba applications with each other and with other systems required for the consultant profile and proves that you have an overall understanding and can implement the skills practically in projects to contribute to the success of the planning and implementation phases in a mentored role. Previous project experience is not necessarily a prerequisite to pass this.</p>\n<p>&nbsp;</p>\n<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-top: 0in; margin-right: 0in; margin-bottom: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\"><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>",
        "title": "SAP Certified Associate - Implementation Consultant - Managed gateway for spend management and SAP Business Network",
        "templateId": "9c490566-2166-4938-9adc-f3784fada08d",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/f8d9930a-c958-495a-a832-07c0b0175438/image.png",
        "objId": "C_ARCIG",
        "id": "f5342470-8e47-4ded-9864-2af5ea213eb0",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-managed-gateway-for-spend-network",
        "acronym": "C_ARCIG"
    },
    {
        "languages": [
            "English",
            "Spanish",
            "German"
        ],
        "level": "Professional",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "40",
        "description": "<p><span>This certification verifies that you possess the ability to perform an SAP Solution Architect role with SAP BTP. SAP Solution Architects develop and implement solutions that meet business requirements; they ensure that the solutions are technically feasible and economically viable. SAP solution architects are responsible for the architectural design of a solution and translate requirements of an organization into technical solution designs that can be implemented by software architects and software developers. An SAP Solution Architect focuses on technical decisions that are made based on the proposed solution and their impact on business outcomes and interfaces.</span></p>",
        "title": "SAP Certified Professional - Solution Architect - SAP BTP",
        "templateId": "b2ecf453-355d-4d9b-8af9-c73b989a8176",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/c0c53141-77fb-4394-9d23-0c351f9851b1/image.png",
        "objId": "P_BTPA",
        "id": "cc0487c0-e5b8-401c-9140-5c29f270e8b8",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-professional-solution-architect-sap-btp",
        "acronym": "P_BTPA"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the fundamental knowledge required to explain and execute core implementation project tasks to deploy, adopt and extend SAP S/4HANA Cloud Public Edition as well as core knowledge in Sourcing &amp; Procurement. It proves that the candidate has an overall understanding and in‐depth technical skills to participate as a member of a SAP S/4HANA Cloud Public Edition implementation project team with a focus on Sourcing &amp; Procurement in a mentored role.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP S/4HANA Cloud Public Edition, Sourcing and Procurement",
        "templateId": "3c486482-db2b-401a-9ad9-364c0ad11388",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/781af1c0-040b-4cd7-9985-9199f4c93df1/image.png",
        "objId": "C_S4CPR",
        "id": "2b6532ea-dd5e-4861-9988-eca91ceb34c2",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-sap-s-4hana-cloud-public-edition-sourcing-and-procurement",
        "acronym": "C_S4CPR"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"color: black; margin-top: 0in; margin-right: 0in; margin-bottom: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification verifies that you possess the fundamental knowledge required to explain and execute core implementation project tasks to deploy, adopt and extend SAP S/4HANA Cloud Public Edition as well as core knowledge in Sales. It proves that the candidate has an overall understanding and technical skills to participate as a member of a SAP S/4HANA Cloud Public Edition implementation project team with a focus on Sales in a mentored role.</li>\n</ul>\n<p><span>&nbsp;</span></p>\n<p><b><span>Please note</span></b><span>: To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/implementing-sap-s-4-hana-cloud-public-edition\">Implementing SAP S/4HANA Cloud Public Edition</a> and <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journey/applying-sap-s-4hana-sales\">Applying SAP S/4HANA Sales</a> in addition to the Learning Journeys displayed under \"How to Prepare.\"</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP S/4HANA Cloud Public Edition, Sales",
        "templateId": "06f6592d-115e-497e-b700-f4ba0a909e4e",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/92aedcea-8341-452a-acaa-fa788f0f1f9f/image.png",
        "objId": "C_S4CS",
        "id": "229cb2c4-5d81-4bd2-976d-301c2792a1fe",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-sap-s-4hana-cloud-public-edition-sales",
        "acronym": "C_S4CS"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "developer",
        "description": "<p>This certification verifies that you possess the core skills of CAP to design and execute extension applications to existing SAP applications, specifically SAP S/4HANA Cloud, using the side-by-side extensibility concept on SAP BTP. This certification is for backend developers working with CAP using Node.js/JavaScript, Java, Core Data Services, and Fiori Elements, demonstrating technical skills and mentorship abilities.</p><p><strong>Please note:</strong> To prepare for this certification, it is necessary to take the Learning Journey <a target=\"__blank\" href=\"https://learning.sap.com/learning-journeys/create-processes-and-automations-with-sap-build-process-automation\">Creating Processes and Automations with SAP Build Process Automation</a> in addition to the Learning Journeys displayed under \"How to Prepare.\"</p>",
        "title": "SAP Certified Associate - Backend Developer - SAP Cloud Application Programming Model",
        "templateId": "9fbb2460-4500-4612-bfd0-509a9623ad54",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/852e3e88-a0d3-4805-8ad9-6c2490ca2e20/image.png",
        "objId": "C_CPE",
        "id": "567202cc-2b2f-4fe0-b939-c95ff60190fd",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-backend-developer-sap-cloud-programming-model",
        "acronym": "C_CPE"
    },
    {
        "languages": [
            "English",
            "Chinese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the core skills required for the Application and Implementation consultant profile and proves that you have an overall understanding and in-depth technical skills to participate as a member of a project team in a mentored role. The certification is recommended as an entry-level qualification.</p>",
        "title": "SAP Certified Associate - SAP Enable Now",
        "templateId": "167d3d18-8942-49ca-9b51-e757a96fc1e8",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/2feed126-dc20-4703-a6ae-65a0cc3813ea/image.png",
        "objId": "C_SEN",
        "id": "f917bb1f-bf3b-4190-8746-129bc2bd3b6a",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-enable-now",
        "acronym": "C_SEN"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Specialist",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "40",
        "description": "<div>This certification verifies that you possess the knowledge of implementing and modeling SAP BW/4HANA required by the profile of an SAP BW consultant. The certificate builds on both the basic knowledge gained in related SAP BW/4HANA training and documentation, as well as on practical experience gained as a member of a SAP BW team where the candidate would apply this knowledge practically in projects</div>",
        "title": "SAP Certified Specialist - SAP BW/4HANA Delta",
        "templateId": "da792f85-7e3a-48b9-a8d1-86599aadc338",
        "duration": 90,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/e3c83e90-42f3-41ad-b98a-d2948b5198f8/image.png",
        "objId": "E_BW4HE",
        "id": "be0175d0-ab8b-442e-ad0e-1db2f7c97328",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-specialist-sap-bw-4hana-delta",
        "acronym": "E_BW4HE"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "data_analyst",
        "questions": "60",
        "description": "<p style=\"margin: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification verifies that you possess the fundamental and core knowledge required for the SAP Analytics Cloud Data Analyst profile. It proves that the candidate has an overall understanding and technical skills to participate as a member of the project team. The beginner Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/exploring-sap-analytics-cloud\">Exploring SAP Analytics Cloud</a> builds up the basic knowledge of SAP Analytics Cloud and is relevant for passing the certification.</p>\n<p style=\"margin: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">&nbsp;</p>",
        "title": "SAP Certified Associate - Data Analyst - SAP Analytics Cloud",
        "templateId": "4a100b20-3656-43b8-802d-bbf5f8c2840b",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/ba74d0f6-3922-41b8-8a09-97eca504d275/image.png",
        "objId": "C_SAC",
        "id": "b3a652f4-c3f9-4bb9-9678-f534ef3d73eb",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-data-analyst-sap-analytics-cloud",
        "acronym": "C_SAC"
    },
    {
        "languages": [
            "English",
            "German"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP Human Capital Management and proves that you have the required understanding within this consultant profile of the HCM solution, and can implement this skills practically in projects under the guidance of an experienced consultant. It is recommended as an entry-level qualification to allow consultants to get acquainted with the the cores of SAP HCM. </p>",
        "title": "SAP Certified Associate - SAP HCM for S/4HANA",
        "templateId": "07fdc23c-06de-49de-a980-a35cae8697b3",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/d223bc2f-b614-478c-9696-b7c3ac805fca/image.png",
        "objId": "C_THR12",
        "validUntil": "2025-03-31",
        "id": "7932b978-6e86-41df-a0e2-bd072bceb530",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-hcm-for-s-4hana",
        "acronym": "C_THR12"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-bottom: 12pt; margin-top: 0in; margin-right: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification verifies that you possess the core skills in the area of SAP SuccessFactors Recruiting: Recruiter Experience. This certification is an entry-level qualification for SAP partner consultants implementing SAP SuccessFactors Recruiting: Recruiter Experience, allowing them to apply skills in projects under experienced consultant guidance. Registered and certified SAP partner consultants receive provisioning rights, while customers and independent consultants do not.<br><br><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>\n<p><b><span>&nbsp; &nbsp; </span></b></p>\n<p><b><span>&nbsp; &nbsp; Note:</span></b><span> </span>The next version of this exam should be available between December 16-19, 2024.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Recruiting: Recruiter Experience",
        "templateId": "de57569f-85a4-470e-a155-54bce617baf7",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/411f05e8-70ff-42cb-9e48-3bce7ed81b8f/image.png",
        "objId": "C_THR83",
        "id": "3b561335-2fcf-4fb1-b2cc-3d5e0353d8af",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-recruiting-recruiter-experience",
        "acronym": "C_THR83"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-bottom: 12pt; margin-top: 0in; margin-right: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification verifies that you possess the core skills in the area of SAP SuccessFactors Recruiting: Candidate Experience. It is is an entry-level qualification for SAP partner consultants implementing SAP SuccessFactors Recruiting: Candidate Experience, allowing them to apply skills in projects under experienced consultant guidance. Registered and certified SAP partner consultants receive provisioning rights, while customers and independent consultants do not.<br><br><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"&nbsp; &nbsp;</li>\n</ul>\n<p><b><span>&nbsp; &nbsp; &nbsp; </span></b></p>\n<p><b><span>&nbsp; &nbsp; Note:</span></b><span> </span>The next version of this exam should be available between December 16-19, 2024.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Recruiting: Candidate Experience",
        "templateId": "27f3c5a7-22bf-4c83-9a69-dd2d9cefab30",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/2a422d37-a3e9-4d04-acb9-a25b06aa92d7/image.png",
        "objId": "C_THR84",
        "id": "f46b9925-dff9-4612-aa32-0c6cab91afbb",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-recruiting-candidate-experience",
        "acronym": "C_THR84"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP SuccessFactors Career Development Planning. This certification is designed for SAP partner consultants implementing SAP SuccessFactors Career Development Planning and Mentoring, allowing them to apply skills under experienced consultant guidance. Registered SAP partner consultants receive provisioning rights, while customers and independent consultants do not.</p>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>\n<p><span>&nbsp;</span></p>\n<p><span><b>Note:</b> The next version of this exam should be available between December 16-19, 2024. </span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Career Development Planning and Mentoring",
        "templateId": "97493670-6a86-41ae-9e43-f21c42759b75",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/de3ea363-9f74-4fe4-bad6-2cfde367746b/image.png",
        "objId": "C_THR95",
        "id": "5c73b0f3-7f1d-49f6-8230-bae533e48406",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-career-development-planning-and-mentoring",
        "acronym": "C_THR95"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP SuccessFactors Employee Central Core. This certification is designed for SAP partner consultants implementing SAP SuccessFactors Employee Central Core, allowing them to apply their skills in projects under experienced consultant guidance. Registered SAP Partner consultants receive provisioning rights, while customers and independent consultants do not.</p>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>\n<p><span>&nbsp;</span></p>\n<p><span><b>Note:</b> The next version of this exam should be available between December 16-19, 2024. </span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Employee Central Core",
        "templateId": "400f4c6e-7519-4efe-aaed-c46e2a97f6c1",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/8d56137a-7385-4439-9260-bc86415de8e9/image.png",
        "objId": "C_THR81",
        "id": "3d0df04f-5c39-4c6c-9ec7-124e7d77b0cf",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-employee-central-core",
        "acronym": "C_THR81"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "developer",
        "description": "<p>This certification verifies that you have fundamental knowledge and proven skills in SAP Cloud Applications Studio to develop SAP Sales Cloud and SAP Service Cloud functional enhancements. It proves that you have fundamental understanding of the Developer profile and can apply the associated prerequisite skills practically under the supervision of an expert consultant in a project environment.</p><p><strong>Please note: </strong>To prepare for this certification, it is necessary to take Learning Journey <a target=\"__blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\".</p>",
        "title": "SAP Certified Associate - Developer - SAP Sales and Service Cloud",
        "templateId": "27c1026a-1968-40c0-a68e-5c2964e0059c",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/046e6fcd-cc98-42b3-b070-c7ac31e67892/image.png",
        "objId": "C_C4H46",
        "id": "08541ec9-7b08-42c9-8477-4305c9f005ff",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-developer-sap-cloud-for-customer",
        "acronym": "C_C4H46"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills and proven skills in SAP Service Cloud project implementation and proves that you have the core understanding required of an application consultant and are able to apply the associated prerequisite skills practically under the supervision of an expert consultant in a project environment.</p><p>&nbsp;</p><p><b><span>Note:</span></b><span> To prepare for this certification, it is necessary to take the Learning Journeys </span><span><a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/sap-sales-and-service-cloud-administration\">Configuring SAP Sales and Service Cloud</a> and </span><span><a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Service Cloud",
        "templateId": "96b02165-0bac-43ee-b424-dcfc50291bcf",
        "duration": 180,
        "validForDays": "364",
        "imageUrl": "https://images.credly.com/size/400x400/images/de5fab7c-c58e-421e-8b78-7de6afeed536/image.png",
        "objId": "C_C4H51",
        "id": "81e0ff59-c5eb-49fc-b867-aa39453c701e",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-sap-service-cloud",
        "acronym": "C_C4H51"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in SAP Sales Cloud, proves that you have the core understanding required of an Implementation Consultant and are able to apply the associated prerequisite skills practically under the supervision of an expert consultant in a project environment.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Sales Cloud",
        "templateId": "48697ee0-54f2-4791-bbdc-4ce679916942",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/f495643f-3163-4fd1-b24b-ce67204624ac/image.png",
        "objId": "C_C4H41",
        "id": "982d0467-63f8-4db9-be44-18b28742b190",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-sap-sales-cloud",
        "acronym": "C_C4H41"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-bottom: 12pt; margin-top: 0in; margin-right: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification verifies that you possess the core skills in the area of SAP SuccessFactors Variable Pay. It is an entry-level qualification for SAP partner consultants implementing SAP SuccessFactors Variable Pay, allowing them to apply skills in projects under experienced consultant guidance. Registered and certified SAP partner consultants receive provisioning rights, while customers and independent consultants do not.<br><br><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>\n<p><b><span>&nbsp; &nbsp;</span></b></p>\n<p><b><span>&nbsp; &nbsp; Note:</span></b><span> </span>The next version of this exam should be available between December 16-19, 2024.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Variable Pay ",
        "templateId": "e821a636-bb73-490f-b542-da6e8ed9532e",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/dc25d53d-0bbb-4308-ace1-59a7e12dda82/image.png",
        "objId": "C_THR87",
        "id": "40c7ffbd-7b0a-4b92-b9e9-aa560dcaace9",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-variable-pay",
        "acronym": "C_THR87"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-bottom: 12pt; margin-top: 0in; margin-right: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification verifies that you possess the core skills in the area of SAP SuccessFactors Compensation. It is an entry-level qualification for SAP partner consultants implementing SAP SuccessFactors Compensation, allowing them to apply skills in projects under experienced consultant guidance. Registered and certified SAP partner consultants receive provisioning rights, while customers and independent consultants do not.<br><br><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>\n<p><b><span>&nbsp; &nbsp; </span></b></p>\n<p><b><span>&nbsp; &nbsp; Note:</span></b><span> </span>The next version of this exam should be available between December 16-19, 2024.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Compensation",
        "templateId": "4c1de344-7ce6-452d-88df-f94fbc514e37",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/2904c380-4433-4fdf-9de0-38632dbb4f00/image.png",
        "objId": "C_THR86",
        "id": "b732286b-88f9-4247-88ac-0975a1450518",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-compensation",
        "acronym": "C_THR86"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP SuccessFactors Succession Management. This certification is an entry-level qualification for SAP partner consultants, allowing them to apply skills in projects under experienced consultant guidance. Once certified, registered SAP partner consultants will be granted provisioning rights. Customers and independent consultants will not be granted provisioning rights.</p>\n<p>&nbsp;</p>\n<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-top: 0in; margin-right: 0in; margin-bottom: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\"><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> The next version of this exam should be available between December 16-19, 2024.</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Succession Management",
        "templateId": "b27b9f5f-4c4b-41f5-8757-58a9cf786933",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/a148c0ba-f838-4645-b5f0-2a79a98fa609/image.png",
        "objId": "C_THR85",
        "id": "c4d18818-72e4-4ed4-ac21-abcfb980391b",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-succession-management",
        "acronym": "C_THR85"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP SuccessFactors People Analytics: Reporting. This certification is an entry-level qualification for SAP partner consultants, allowing them to apply skills in projects under experienced consultant guidance. Once certified, registered SAP partner consultants will be granted provisioning rights. Customers and independent consultants will not be granted provisioning rights.</p>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>\n<p><span>&nbsp;</span></p>\n<p><span><b>Note:</b> The next version of this exam should be available between December 16-19, 2024. </span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors People Analytics: Reporting",
        "templateId": "ba7cbb77-fbf3-4607-8f3f-e7e14fb6a4b1",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/78e23e92-6cab-48a3-a382-ede14cf5dfba/image.png",
        "objId": "C_THR92",
        "id": "f7ce5404-8fbf-4621-841e-ff998c02a4d9",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-people-analytics-reporting",
        "acronym": "C_THR92"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills required of the SAP Ariba Sourcing Consultant profile and proves that you have an overall understanding and necessary technical skills to participate as a member of a project team in a mentored role. This certification is recommended as an entry-level qualification to participate in SAP Ariba Sourcing design and implementation projects.</p>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Ariba Sourcing",
        "templateId": "b940e395-d3bf-4536-ad6c-8bef9df1c884",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/1bf09278-4b18-440c-8a0c-e6cfab57906b/image.png",
        "objId": "C_ARSOR",
        "id": "cf078c8b-7d4e-4038-9c54-96dbb1d2a1f3",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-ariba-sourcing",
        "acronym": "C_ARSOR"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP SuccessFactors Workforce Analytics. This certification is designed for SAP partner consultants implementing SAP SuccessFactors Workforce Analytics, allowing them to apply their skills in projects under experienced consultant guidance. Registered SAP Partner consultants receive provisioning rights, while customers and independent consultants are not.</p>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>\n<p><span>&nbsp;</span></p>\n<p><span><b>Note:</b> The next version of this exam should be available between December 16-19, 2024. </span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Workforce Analytics",
        "templateId": "af389135-701d-4814-bbee-b9a6bacda463",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/images/d70ab3f3-ffca-421d-8a90-e987e00ecc1d/image.png",
        "objId": "C_THR96",
        "id": "edb691d2-cbd0-4528-bd9d-c6bdea898c4b",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-workforce-analytics-technical-consultant",
        "acronym": "C_THR96"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "79",
        "description": "<p>This certification verifies that you possess the core skills required of an SAP Ariba Associate Business Consultant profile and proves that you have an overall understanding to participate as a member of a project team in a mentored role. The certification is recommended as an entry-level qualification to participate in all SAP Ariba Supplier Management design and implementation projects.</p>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Ariba Supplier Management",
        "templateId": "3076fc57-3f12-4bf3-8d10-ed2b8c31ff23",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/48x48/images/2a01dfb6-bd64-4b07-af85-bd2999c5cd6a/image.png",
        "objId": "C_ARSUM",
        "id": "faec5655-9db1-44d5-be5e-556b586d6ee0",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-ariba-supplier-management",
        "acronym": "C_ARSUM"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-bottom: 12pt; margin-top: 0in; margin-right: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification verifies that you possess the core skills in the area of SAP SuccessFactors Time Management. It is an entry-level qualification for SAP partner consultants implementing SAP SuccessFactors Time Management, allowing them to apply skills in projects under experienced consultant guidance. Registered and certified SAP partner consultants receive provisioning rights, while customers and independent consultants do not.<br><br><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>\n<p><b><span>&nbsp; &nbsp;</span></b></p>\n<p><b><span>&nbsp; &nbsp; Note:</span></b><span> </span>The next version of this exam should be available between December 16-19, 2024.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Time Management",
        "templateId": "056ba87b-c646-47bc-bd77-b43ee44db047",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/43be3127-8706-4c29-92d8-42ad3cb71a85/image.png",
        "objId": "C_THR94",
        "id": "e90040b6-95ea-418f-b903-3699d370aab5",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-time-management",
        "acronym": "C_THR94"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills required of an SAP Ariba Associate Business Consultant profile and proves that you have an overall understanding to participate as a member of a project team. The certification is recommended as a qualification to participate in all SAP Ariba Supply Chain Collaboration design and implementation projects.</p>\n<p>&nbsp;</p>\n<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-top: 0in; margin-right: 0in; margin-bottom: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\"><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Business Network for Supply Chain",
        "templateId": "41dff691-a9ad-4805-85ae-1f9f0941b7c7",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/ba35ddbf-9b9d-4a4b-a788-20b35fa38eee/image.png",
        "objId": "C_ARSCC",
        "id": "5cd62a3d-ed1a-45de-b7ac-af026fb319b6",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-business-network-supply-chain-collaboration",
        "acronym": "C_ARSCC"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the SAP Service Cloud the cores and core skills required for the consultant profile and proves that you have an overall understanding and in?depth skills to participate as a member of project team with a focus on setting up, implementing and using SAP Service Cloud Version 2 in a mentored role.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Service Cloud Version 2",
        "templateId": "d25013a6-f9e5-41af-808a-bd185d258d04",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/3073e64e-0489-4ac1-b6e2-05dc696ae615/image.png",
        "objId": "C_C4H56",
        "id": "570ffd3e-b635-42f7-815b-0a819f1a437f",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-sap-service-cloud-version-2",
        "acronym": "C_C4H56"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the core skills required for the implementation consultant profile and proves that you have an overall understanding and functional configuration skills to participate as a member of an SAP Field Service Management project team in a mentored role. The certification  is recommended as an entry-level qualification.</p>",
        "title": "SAP Certified Associate - SAP Field Service Management",
        "templateId": "d711d429-dfa2-4764-bcf7-6f4d9902ab17",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/eea96265-6827-4723-96e1-dd646e22bc52/image.png",
        "objId": "C_FSM",
        "id": "9db7fb71-d16f-4dc2-b9a8-b098ccad4116",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-field-service-management",
        "acronym": "C_FSM"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the fundamental and core knowledge in the area of SAP Payroll and proves that you have the required understanding within this consultant profile of the HCM solution, and can implement this knowledge practically in projects under the guidance of an experienced consultant. It is recommended as an entry-level qualification to allow consultants to get acquainted with the the fundamentals of SAP HCM Payroll.</p>",
        "title": "SAP Certified Associate - SAP HCM Payroll for SAP S/4HANA",
        "templateId": "96618ef2-34f3-4663-b578-4e90fa30ff37",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/769e2726-cb8a-4fdf-a485-12df16a96ef1/image.png",
        "objId": "C_HCMP",
        "id": "f31a9af0-7a00-46a6-9bcb-42be225839bf",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-hcm-payroll-for-sap-s-4hana",
        "acronym": "C_HCMP"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the core skills required of the SAP S/4HANA PPM Implementation Consultant profile and proves that you have an overall understanding and in?depth technical skills to participate as a member of a project team in a mentored role. The certification is recommended as an entry-level qualification.</p>\n<p>This certification is relevant for SAP S/4HANA Cloud, private edition and SAP S/4HANA on-premise.</p>",
        "title": "SAP Certified Associate - SAP S/4HANA Portfolio and Project Management",
        "templateId": "07b7517f-3ac7-4e36-9759-0a5afb602bea",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/29b655ea-b300-4672-a9e1-82f445e16105/image.png",
        "objId": "C_S4PPM",
        "id": "3e6de80b-1929-4ff2-a3b2-fdf190932670",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-portfolio-and-project-management",
        "acronym": "C_S4PPM"
    },
    {
        "languages": [
            "English",
            "German"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that the candidate possesses the fundamental and core knowledge required of the application consultant profile. This certification proves that the candidate has an overall understanding and in?depth technical skills to participate as a member of a project team  in a mentored role. This certification exam is recommended as an  entry level qualification.</p>",
        "title": "SAP Certified Associate - SAP Master Data Governance",
        "templateId": "9236807d-26a2-4f23-86dc-a88fd60202ca",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/28c9a4ef-74c3-4ba8-9311-d3bec6e5b6a9/image.png",
        "objId": "C_MDG",
        "id": "72e70a5b-1bd5-41df-bf0f-c8b5daf4dffc",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-master-data-governance",
        "acronym": "C_MDG"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Professional",
        "stayCertified": "true",
        "release": "1",
        "roles": "developer",
        "description": "<p>This certification verifies that you possess the required level of skills to work effectively with the SAP Commerce Cloud Suite. The certification requires candidates to demonstrate technical skills and understanding of SAP Commerce Cloud. Candidates must also study SAP Commerce Cloud Suite documentation (at: https://help.sap.com/viewer/product/SAP_COMMERCE_CLOUD), which requires SAP customer or partner access. Three years of practical experience on SAP Commerce Cloud is required.</p>",
        "title": "SAP Certified Professional - Developer - SAP Commerce Cloud",
        "templateId": "c829481a-a6bc-4917-a18c-69b4aac1e7bd",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/d237a23e-5b5a-4b40-8234-a62174bd4753/image.png",
        "objId": "P_C4H34",
        "id": "b73f7cd1-7129-4953-8f5d-6ec6d482d827",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-professional-developer-sap-commerce-cloud",
        "acronym": "P_C4H34"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Professional",
        "stayCertified": "true",
        "release": "1",
        "roles": "architect",
        "description": "<p>This certification verifies that you possess the ability to perform an SAP enterprise architect role. Enterprise architects develop a holistic enterprise architecture model aligned with a company's business strategy, ensuring IT infrastructure supports goals and is crucial in maintaining and updating IT hardware, software, and services.</p>\n<p>More information about the skills areas is available in this document: https://url.sap/f5oplb.</p>",
        "title": "SAP Certified Professional - SAP Enterprise Architect",
        "templateId": "c1c25f88-907e-4b20-bfe8-25d7e98b369c",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/79fa4827-545f-492b-bcd9-95da95ea93db/image.png",
        "objId": "P_SAPEA",
        "id": "432753d3-8633-4178-8424-6bef11e2545c",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-professional-sap-enterprise-architect",
        "acronym": "P_SAPEA"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>The \"SAP Certified Associate - Enterprise Architecture Consultant - SAP LeanIX\" certification exam validates that you possess enterprise architecture fundamental skills and core knowledge in using SAP LeanIX required for the consultant profile.&nbsp;<br>This certification proves that you have an overall understanding and in‐depth skills to participate as a member of project team with a focus on enterprise architecture using SAP LeanIX.</p>",
        "title": "SAP Certified Associate - Enterprise Architecture Consultant - SAP LeanIX",
        "templateId": "45ea67e0-61a0-46f1-bb2e-a8bc23767f2b",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/63f6c88a-f9b8-468f-911a-ce7bdd27edfd/image.png",
        "objId": "C_LIXEA",
        "validUntil": null,
        "id": "c5d76c5a-248d-40c1-bd38-2b76c75bf6b8",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-enterprise-architecture-consultant-sap-leanix",
        "acronym": "C_LIXEA"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "developer",
        "questions": "60",
        "description": "<p>This certification verifies that you possess the ability to perform low-code and no-code development with SAP Build. As low-code/no-code developer, you are able to build advanced apps and SAP extensions using SAP Build Apps, consume SAP backend data and build your own data models. You can create automations and processes with SAP Build Process Automation and integrate them with other applications using APIs. Finally, you are also able to design business sites and integrate apps in SAP Build Work Zone to enhance user engagement and streamline business processes.</p><p>The certification proves that you have an overall understanding and in‐depth skills to participate as a member of a project team.</p><p><strong>Please note: </strong>To prepare for this certification, it is necessary to take the &nbsp;<a target=\"__blank\" href=\"https://learning.sap.com/learning-journeys/discover-sap-business-technology-platform\">Discovering SAP Business Technology Platform</a> and <a target=\"__blank\" href=\"https://learning.sap.com/learning-journeys/compose-and-automate-with-sap-build-the-no-code-way\">Composing and automating with SAP Build the No-Code Way</a> Learning Journeys in addition to the Learning Journeys displayed under \"How to Prepare.\"</p>",
        "title": "SAP Certified Associate - Low-Code/No-Code Developer - SAP Build",
        "templateId": "45ea67e0-61a0-46f1-bb2e-a8bc23767f2b",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/340x340/images/25e2d9c2-5d52-416a-95d3-7094d711ede0/image.png",
        "objId": "C_LCNC",
        "validUntil": null,
        "id": "93f8c67b-ad19-4f06-a02d-6c2fa1a96101",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-low-code-no-code-developer-sap-build",
        "acronym": "C_LCNC"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP SuccessFactors Incentive Management the cores and implementation for Consultants and validates that you have good skills and understanding of how to implement compensation plans. Based on this skills, you are able to apply the acquired skills to perform a variety of appropriate implementation and customization-related tasks within a project team, and contribute positively to the success of a project.</p>",
        "title": "SAP Certified Associate - SAP SuccessFactors Incentive Management",
        "templateId": "c784cfcc-bc47-4015-b060-50ba9db02f0f",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/7387ec83-33c4-442a-94db-84744e7da19f/image.png",
        "objId": "C_THR70",
        "id": "71a706a6-1de6-4f81-a53a-3e106a8bfc84",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-incentive-management",
        "acronym": "C_THR70"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "data_scientist",
        "description": "<p>This certification verifies that you possess the required data provisioning and data modelling skills in SAP HANA Cloud and SAP HANA for the role of a SAP HANA data engineer. This certification is based on the knowledge gained through the related SAP HANA data provisioning and data modelling learning journeys or training courses.</p>\n<p>In addition to the SAP Learning Journeys we recommend to enroll to this hands-on experience: <a rel=\"noopener\" href=\"https://www.sap.com/products/technology-platform/hana/guided-experience.html\">Experience SAP HANA Cloud</a> to prepare for the exam.&nbsp;</p>",
        "title": "SAP Certified Associate - Data Engineer - SAP HANA",
        "templateId": "e5f229d3-f916-43de-a3a7-3fdaf2b42e84",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/74f177d2-c559-4481-bf84-968ff228de7f/image.png",
        "objId": "C_HAMOD",
        "id": "cb0c6189-1c2f-48e8-92fc-ac7b926ac7e1",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-data-engineer-sap-hana",
        "acronym": "C_HAMOD"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Specialist",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "60",
        "description": "<p>This certification exam validates that the candidate possesses the core knowledge required to support SAP S/4HANA Cloud Private Edition. It is designed for the Implementation Consultant &amp; Project Manager who needs to prove that he/she has an overall understanding and in-depth skills of SAP S/4HANA Cloud Private Edition and can participate in his/her role as a member of an implementation project. This is an entry level certification.</p><p>&nbsp;</p><p><b><span>Please note</span></b><span>: To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/explore-sap-cloud-erp\">Exploring SAP Cloud ERP</a> in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>",
        "title": "SAP Certified Specialist - Implementation Consultant - SAP S/4HANA Cloud Private Edition",
        "templateId": "47c26786-414e-441e-a3c6-f29b22e7712f",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/1af6b23d-eb83-4513-b54a-44645568f92e/image.png",
        "objId": "E_S4CPE",
        "id": "07785fe2-3e2d-4c83-8ae2-a0184d893b91",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-specialist-sap-s-4hana-cloud-private-edition-implementation-with-sap-activate",
        "acronym": "E_S4CPE"
    },
    {
        "languages": [
            "English",
            "German",
            "Spanish",
            "French",
            "Japanese",
            "Russian",
            "Chinese",
            "Portuguese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the core skills about business processes and their integration in SAP S/4HANA. The certification is recommended as an entry-level qualification to allow consultants and application users to get acquainted with SAP S/4HANA end-to-end business process the cores.</p>\n<p></p>\n<p>An exam on a previous release is available as well: <a rel=\"noopener\" href=\"https://training.sap.com/certification/c_ts410_2020-sap-certified-associate---business-process-integration-with-sap-s4hana-2020-g/?\">C_TS410_2020 - SAP Certified Associate - Business Process Integration with SAP S/4HANA 2020 | SAP Training</a>&nbsp;</p>",
        "title": "SAP Certified Associate - Business Process Integration with SAP S/4HANA",
        "templateId": "58ed27a2-1d8d-4e7c-9b83-e6b39cb6366b",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/6037bed2-a42a-4bd8-b3d3-2a3ce7ab6ebd/image.png",
        "objId": "C_TS410",
        "id": "10b3e7f6-9e01-4753-9508-8b87dc4b6379",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-business-process-integration-with-sap-s-4hana",
        "acronym": "C_TS410"
    },
    {
        "languages": [
            "English",
            "German",
            "French",
            "Japanese",
            "Chinese",
            "Spanish",
            "Korean"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "60",
        "description": "<p>This certification verifies that you possess the core skills required of the SAP S/4HANA Sales profile and proves that you have an overall understanding and in-depth technical skills to participate as a member of a project team in a mentored role. The certification is recommended as an entry-level qualification.</p>\n<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"color: black; margin-bottom: 12pt; margin-top: 0in; margin-right: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification is also available in the (older) release versions: <a rel=\"noopener\" href=\"https://training.sap.com/certification/c_ts462_2021-sap-certified-associate---sap-s4hana-sales-2021-g/?\">C_TS462_2021</a> and <a rel=\"noopener\" href=\"https://training.sap.com/certification/c_ts462_2022-sap-certified-associate---sap-s4hana-sales-2022-g/?\">C_TS462_2022</a></li>\n</ul>\n<p><b><span>Please note:</span></b><span> To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\".</span> </p>",
        "title": "SAP Certified Associate - SAP S/4HANA Cloud Private Edition, Sales",
        "templateId": "038800ff-309b-484a-ba3e-c383b1de1fed",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/1989a253-2ac3-4dc3-aafe-65ca1ad8f3e9/image.png",
        "objId": "C_TS462",
        "id": "0136c8a5-5645-4e58-b6bf-1f0fc3959fdc",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-sales",
        "acronym": "C_TS462"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you posses the core skills in the area of the SAP supply chain planning and proves that you have an overall understanding within this consultant profile of the industry solutions, and can implement this skills practically in projects under the guidance of an experienced consultant. It is recommended as an entry-level qualification to allow consultants to get acquainted with the the cores of SAP Integrated Business Planning. </p>",
        "title": "SAP Certified Associate - SAP IBP for Supply Chain",
        "templateId": "f2769eb5-547a-45b9-b4c5-605ff035681d",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/eb7d1463-b00c-46b7-8e5e-a1b020e154f9/image.png",
        "objId": "C_IBP",
        "id": "ab16e808-8c30-421f-8f75-4e2137fe9ee5",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-ibp-for-supply-chain",
        "acronym": "C_IBP"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>The \"SAP Certified Associate - Implementation Consultant - &nbsp;SAP SuccessFactors Onboarding\" certification exam verifies that the candidate possesses fundamental knowledge and skills in the area of SAP SuccessFactors Onboarding. This certification is an entry-level qualification for SAP partner consultants implementing SAP SuccessFactors Onboarding, allowing them to apply skills in projects under experienced consultant guidance. Registered and certified SAP partner consultants receive provisioning rights, while customers and independent consultants do not.</p><p><strong>Note:</strong>&nbsp;To prepare for this certification, it is necessary to take the Learning Journey&nbsp;<a target=\"__blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.</p><p><strong>Note:&nbsp;</strong>The next version of this exam should be available between December 16-19, 2024.</p>",
        "title": "SAP Certified Associate -Implementation Consultant - SAP SuccessFactors Onboarding",
        "templateId": "e85a3ac4-f482-4d33-9fd2-dfa1d40f8310",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/f617a878-ec75-4bed-88f2-531aee9c593b/image.png",
        "objId": "C_THR97",
        "id": "1a71123f-d500-4fd0-b0ff-b339dfdf03a2",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-onboarding",
        "acronym": "C_THR97"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>The \"SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Learning\" certification exam verifies that the candidate possesses fundamental knowledge and skills in the area of SAP SuccessFactors Learning. This certificate proves that the candidate can apply the knowledge and skills in projects under the guidance of an experienced consultant. It is recommended as an entry-level qualification to allow consultants to get acquainted with the fundamentals of SAP SuccessFactors Learning. Registered and certified SAP partner consultants receive provisioning rights, while customers and independent consultants do not.</p><p>&nbsp;</p><p><strong>Note:</strong> To prepare for this certification, it is necessary to take the Learning Journey <a target=\"__blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.<br><b><span></span></b></p><p>&nbsp;</p><p><strong>Note: </strong>The next version of this exam should be available between December 16-19, 2024.</p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Learning",
        "templateId": "6357a3d2-e808-4969-a6ab-69b59629f17e",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/b09456ab-4215-4cd5-a075-41cb5abe3bc5/image.png",
        "objId": "C_THR88",
        "id": "524282b5-70dd-40c0-9fe0-f410edb5dc66",
        "robots": "ALL",
        "relations": [
            "exam",
            "live-event",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-learning-management",
        "acronym": "C_THR88"
    },
    {
        "languages": [
            "English",
            "Japanese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "administrator",
        "description": "<p>This certification exam evaluates the candidate's technical expertise in system administration and configuration tasks within the SAP S/4HANA environment. This certification ensures that the candidate possesses the necessary knowledge to implement SAP projects and the skills to install SAP HANA 2.0—an essential role of an SAP HANA 2.0 technology consultant. Preparation for the certification requires a combination of educational coursework and practical experience.&nbsp;</p>",
        "title": "SAP Certified Associate - SAP S/4HANA System Administration",
        "templateId": "e4b48c6d-c13a-420f-ba18-6e3b3ec384eb",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/0d829244-052d-4ce9-97f4-a109a454d36f/image.png",
        "objId": "C_TADM",
        "id": "87e86fc3-71bf-4787-aa61-fefc0c7ec0e2",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-system-administration",
        "acronym": "C_TADM"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Specialist",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the required level of skills to perform a technical SAP S/4HANA conversion and an SAP system upgrade.&nbsp; It proves that you have an overall understanding and the in-depth technical skills required to successfully complete a system upgrade and conversion. Candidates that pass this certification can implement these skills practically in SAP projects.</p>",
        "title": "SAP Certified Specialist - SAP S/4HANA Conversion and SAP System Upgrade",
        "templateId": "2cc77275-1591-4607-8f21-97a637f3f94c",
        "duration": 90,
        "imageUrl": "https://images.credly.com/size/400x400/images/063cad5a-3eff-4b41-92d3-1eded26c73ef/image.png",
        "objId": "E_S4CON",
        "id": "4d04b033-786f-4878-865f-87dcece0361d",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-specialist-sap-s-4hana-conversion-and-sap-system-upgrade",
        "acronym": "E_S4CON"
    },
    {
        "languages": [
            "English",
            "German",
            "Chinese",
            "Japanese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP S/4HANA Project Systems required for the consultant profile and proves that you have an overall understanding and can implement the skills practically in projects to contribute to the success of the planning and implementation phases in a mentored role.</p>\n<p>This certification  is relevant for SAP S/4HANA Cloud, private edition and SAP S/4HANA on-premise.</p>",
        "title": "SAP Certified Associate - SAP S/4HANA Project Systems",
        "templateId": "7afb43d6-f7db-49f5-92e3-f61b448db08b",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/cfbf7ea5-d1fe-4ff4-873a-e7a43b77d369/image.png",
        "objId": "C_TS412",
        "id": "cd3a2a66-1f7a-42aa-a518-81ec634a11b1",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-project-systems",
        "acronym": "C_TS412"
    },
    {
        "languages": [
            "English",
            "German",
            "French",
            "Japanese",
            "Chinese",
            "Spanish",
            "Portuguese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills and proven skills in the area of SAP S/4HANA Financial Accounting. This certificate is an entry-level qualification for Financial Accounting consultants on SAP S/4HANA, allowing them to understand and implement skills in projects and is relevant for SAP S/4HANA Cloud, private edition and SAP S/4HANA on-premise. It is an ideal starting point for a professional career.</p><p>&nbsp;</p><p><strong>Please note: </strong>To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\".</p>",
        "title": "SAP Certified Associate - SAP S/4HANA Cloud Private Edition, Financial Accounting",
        "templateId": "6a26188d-e485-46c7-a038-d0752f19d067",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/images/3587a5cb-eeb4-44d7-a07f-ad762fa6b69d/image.png",
        "objId": "C_TS4FI",
        "id": "e6824c05-b0d1-4d47-8293-d7d1b30963be",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-for-financial-accounting",
        "acronym": "C_TS4FI"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p>This certification verifies that you possess the required skills in the area of the SAP Design Thinking methodology, processes and workshop organization/facilitation for the consultant profile. You should demonstrate the successful use of Design Thinking principles in projects. This certificate builds on the skills gained through the related Design Thinking training.</p>",
        "title": "SAP Certified Associate - Design Thinking",
        "templateId": "a7de502b-4d0e-463a-94ee-27a57981b9df",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/3247ecfb-7e4a-4de6-8c40-b41f1094c88c/image.png",
        "objId": "C_THINK",
        "id": "4a50978c-fec7-4616-bb80-15a7c0b66cca",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-design-thinking",
        "acronym": "C_THINK"
    },
    {
        "languages": [
            "English",
            "German",
            "French",
            "Chinese",
            "Japanese",
            "Portuguese",
            "Spanish"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the cores and core skills in the area of sourcing and procurement line of business area required of the consultant profile and proves that you have an overall understanding and in-depth skills to participate as a member of a project team with a focus on the planning and implementation phases in a mentored role.</p><p>&nbsp;</p><p>This certification is relevant for SAP S/4HANA Cloud, private edition and SAP S/4HANA on-premise.</p><p>&nbsp;</p><p><b>Note:</b> This certification is also available in the (older) release version: <a rel=\"noopener\" target=\"_blank\" href=\"https://training.sap.com/certification/c_ts452_2022-sap-certified-associate---sap-s4hana-sourcing-and-procurement-g/?\">C_TS422_2022</a>.</p><p>&nbsp;</p><p>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</p>",
        "title": "SAP Certified Associate - SAP S/4HANA Cloud Private Edition, Sourcing and Procurement",
        "templateId": "e85ed8aa-0c8f-43fd-a5af-4f5d2af10827",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/340x340/images/d72c87e0-3af8-4336-b603-085bb98dc649/blob",
        "objId": "C_TS452",
        "id": "e943339f-65c7-491a-bedd-642c6d635026",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-sourcing-and-procurement",
        "acronym": "C_TS452"
    },
    {
        "languages": [
            "English",
            "German",
            "Spanish",
            "French",
            "Japanese",
            "Russian",
            "Chinese",
            "Portuguese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "roles": "consultant",
        "description": "<p> This certification verifies that you possess the fundamental knowledge in the areas of implementation, logistics, financials and SAP Business One support and proves that you are able to work under the guidance of an experienced consultant to implement their first SAP Business One implementation project.</p>",
        "title": "SAP Certified Associate - SAP Business One",
        "templateId": "909a96f4-ff6e-485f-91fc-9546299fd1b4",
        "duration": 180,
        "imageUrl": "https://images.credly.com/size/400x400/images/280c2019-e78c-4a13-86d0-b5871ced579c/image.png",
        "objId": "C_TB120",
        "id": "c7231cee-0df5-4ff2-a5de-ae0bbee67a06",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-business-one",
        "acronym": "C_TB120"
    },
    {
        "languages": [
            "English",
            "Chinese",
            "Portuguese",
            "German",
            "Spanish"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of Asset Management required for the consultant profile and proves that you have an overall understanding and can implement the skills practically in projects to contribute to the success of the planning and implementation phases in a mentored role. The certification is relevant for SAP S/4HANA Cloud Private Edition and SAP S/4HANA on-premise.</p>\n<p>&nbsp;</p>\n<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-top: 0in; margin-right: 0in; margin-bottom: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\"><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>",
        "title": "SAP Certified Associate - SAP S/4HANA Asset Management",
        "templateId": "c15e7673-7b9d-4b3a-b543-f01cd54c4ed7",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/340x340/images/f495643f-3163-4fd1-b24b-ce67204624ac/image.png",
        "objId": "C_S43",
        "id": "b412cea3-3cae-4a2c-af18-492c1e084b4e",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment",
            "live-event"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-asset-management",
        "acronym": "C_S43"
    },
    {
        "languages": [
            "English",
            "German",
            "Japanese",
            "Spanish"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<div data-ccp-timestamp=\"1729039862405\"><table><colgroup><col><col><col><col><col><col><col><col><col><col><col></colgroup><tbody><tr><td>This certification verifies that you possess the core skills in the area of extended warehouse management required for the consultant profile and proves that tyou have an overall understanding and can implement the skills practically in projects to contribute to the success of the planning and implementation phases in a mentored role.<br>&nbsp;<br>This certification is relevant for SAP S/4HANA Cloud, private edition and SAP S/4HANA on-premise.</td></tr></tbody></table></div><p> </p><p></p>",
        "title": "SAP Certified Associate - Extended Warehouse Management in SAP S/4HANA",
        "templateId": "94d17eef-783c-4837-a1b0-37fa3d5e90a0",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/f465f25e-f5d8-4566-b052-07cc3657ad08/image.png",
        "objId": "C_S4EWM",
        "id": "b43d4e38-b89e-42e5-a3db-a213d0abae50",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-extended-warehouse-management-in-sap-s-4hana",
        "acronym": "C_S4EWM"
    },
    {
        "languages": [
            "English",
            "Japanese",
            "German"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP S/4HANA, transportation management. It validates consultants' understanding of SAP TM in SAP S/4HANA, based on training and practical experience. It is an entry-level qualification, allowing consultants to implement this skills in projects. It is relevant for SAP S/4HANA Cloud Private Edition and SAP S/4HANA on-premise.</p>\n<p><span style=\"font-size: 11.0pt; font-family: Calibri, sans-serif; color: black;\">Please note:</span><span style=\"font-size: 11.0pt; font-family: Calibri, sans-serif; color: black;\"> To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\". </span>&nbsp;</p>",
        "title": "SAP Certified Associate - SAP S/4HANA Cloud Private Edition, Transportation Management",
        "templateId": "3329f024-ed6d-472a-8248-67ba1b0af425",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/97cefc1b-012c-4199-aaff-44b2ef6d5eff/image.png",
        "objId": "C_S4TM",
        "id": "5a513e44-b003-4a5b-8739-e3c51333291c",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-transportation-management-in-sap-s-4hana",
        "acronym": "C_S4TM"
    },
    {
        "languages": [
            "English",
            "Spanish",
            "Portuguese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills required for the Central Finance profile. This certification is an entry-level qualification for Central Finance consultants, demonstrating technical skills and understanding. It ensures quick onboarding and immediate contribution to project success, making it an ideal starting point for a career in Central Finance.</p><p>&nbsp;</p><p><b><span>Please note:</span></b><span> To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\". </span></p>",
        "title": "SAP Certified Associate - SAP S/4HANA Cloud Private Edition, Central Finance",
        "templateId": "1b91deef-4250-4203-abe5-fbafd49abe85",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/55b3a0ea-bea9-43b7-8d15-ae427f53a107/image.png",
        "objId": "C_S4FCF",
        "id": "2e58d7a7-4749-470f-b245-d786e6c493aa",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-central-finance-in-sap-s-4hana",
        "acronym": "C_S4FCF"
    },
    {
        "languages": [
            "English",
            "Spanish",
            "German"
        ],
        "level": "Professional",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the ability to prepare, setup and lead an implementation project of Financials in SAP S/4HANA. The certification necessitates 5 years of experience in implementing Financial Accounting and Management Accounting in SAP ERP systems, demonstrating expertise in SAP Finance business processes and architecture, proving your skills and capabilities as a supervisor and consultant and is relevant for SAP S/4HANA Cloud, private edition.</p>\n<p><strong>Please note:</strong><span> </span></p>\n<p><span><i>This certification is also available in the (older) release version <a rel=\"noopener\" href=\"https://training.sap.com/certification/p_s4fin_2021-sap-certified-professional---financials-in-sap-s4hana-2021-for-sap-erp-finance-experts-g/?\">P_S4FIN_2021</a>.</i> </span></p>\n<p><span>To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\". </span></p>",
        "title": "SAP Certified Professional - Financials in SAP S/4HANA for SAP ERP Finance Experts",
        "templateId": "b2ecf453-355d-4d9b-8af9-c73b989a8176",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/680x680/images/804de703-9360-4d1d-abba-c7cf878df07e/image.png",
        "objId": "P_S4FIN",
        "id": "6e52f251-62b6-40ee-96bc-a484846344ab",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-professional-financials-in-sap-s-4hana-for-sap-erp-finance-experts",
        "acronym": "P_S4FIN"
    },
    {
        "languages": [
            "English",
            "Chinese",
            "Portuguese",
            "German",
            "Japanese",
            "Spanish"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "79",
        "description": "<p>This certification verifies that you possess core skills in the Quality Management line of business area required of the consultant profile and proves that you have an overall understanding and in-depth technical skills to participate as a member of an SAP S/4HANA OnPrem with a focus on Quality Management in a mentored role. The certification is recommended as an entry-level qualification and is relevant for SAP S/4HANA Cloud Private Edition and SAP S/4HANA on-premise.</p><p>&nbsp;</p><p><strong>Please note:</strong> To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\".</p>",
        "title": "SAP Certified Associate - SAP S/4HANA Cloud Private Edition, Quality Management",
        "templateId": "256f7bd2-826b-491d-831c-8380617f42ec",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/images/5ffa2d93-935d-43b0-8b0e-9d83bd0598a3/image.png",
        "objId": "C_TS414",
        "id": "91772e15-0c48-4bd8-b458-a140fd47a437",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-quality-management",
        "acronym": "C_TS414"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills required of an SAP Ariba Associate Business Consultant profile and proves that you have an overall understanding to participate as a member of a project team in a mentored role. The certification is recommended as an entry-level qualification to participate in all SAP Ariba Contracts design and implementation projects.</p>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> </span><span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Ariba Contracts",
        "templateId": "92346d9b-1077-4f63-80f6-c5e3d6193409",
        "duration": 180,
        "validForDays": "364",
        "imageUrl": "https://images.credly.com/size/200x200/images/26d9f7a1-f018-40f3-b84f-ec39c9b32a29/image.png",
        "objId": "C_ARCON",
        "id": "ed202047-1105-421f-a541-b7ab80eb65b6",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-ariba-contracts",
        "acronym": "C_ARCON"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills in the area of SAP Ariba Procurement solutions. The certificate proves that you have an overall understanding of the SAP Ariba Procurement consultant profile and can implement this skills practically in projects under the guidance of an experienced consultant. It is recommended as an entry-level qualification to allow consultants to get acquainted with the the cores of the SAP Ariba product line.</p>\n<p>&nbsp;</p>\n<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-top: 0in; margin-right: 0in; margin-bottom: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\"><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP Ariba Procurement",
        "templateId": "82ccfcde-0c66-4ccf-bca6-0be489a5aacf",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/c099b0d4-03c8-4307-ac94-56ffcd262f54/image.png",
        "objId": "C_ARP2P",
        "id": "42731b59-a891-4111-9ca4-1985f737d704",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-ariba-procurement",
        "acronym": "C_ARP2P"
    },
    {
        "languages": [
            "English",
            "German"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "60",
        "description": "<p>This certification exam verifies that the candidate has the core knowledge about SAP end-to-end business processes and their integration. It is recommended as an entry-level qualification to allow consultants and application users to get acquainted with SAP end-to-end business process fundamentals.</p>\n<p><b><span>Please note:</span></b><span> To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\".</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - End-to-End Business Processes for the Intelligent Enterprise",
        "templateId": "038800ff-309b-484a-ba3e-c383b1de1fed",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/b5b790b5-d24f-45ed-9c95-094c399b2207/image.png",
        "objId": "C_IEE2E",
        "id": "683cfe89-9980-4f8b-ab18-b36f21dbb2d6",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-implementation-consultant-end-to-end-business-processes-for-the-intelligent-enterprise",
        "acronym": "C_IEE2E"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "60",
        "description": "<p>This certification is intended for professionals who manage business processes with SAP Signavio solutions. It validates that the candidate possesses fundamental knowledge for managing and designing business processes with customer experience at the heart of the process design. This role is crucial in establishing a continuous business process-centric approach within an organization. This certification exam is recommended as an entry level qualification.</p>",
        "title": "SAP Certified Associate - Process Management Consultant - SAP Signavio",
        "templateId": "daf99b32-ab39-4127-8178-269f46521f53",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/680x680/images/7a3e356b-b73a-45f7-b0c0-dcca4b5506fb/image.png",
        "objId": "C_SIGPM",
        "id": "b48f695c-66ab-4e92-8863-35874d1f1747",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-process-management-consultant-sap-signavio",
        "acronym": "C_SIGPM"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills required of SAP S/4HANA Cash Management and Treasury and Risk Management solution. This certification validates a consultant's technical skills and understanding in Cash Management and Treasury implementation projects on SAP S/4HANA. It ensures quick onboarding and immediate project success, making it an ideal starting point for a career and is relevant for SAP S/4HANA Cloud, private edition and SAP S/4HANA on-premise.</p>\n<p></p>\n<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"color: black; margin-bottom: 12pt; margin-top: 0in; margin-right: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\">This certification is also available in the (older) release versions:<span><a rel=\"noopener\" href=\"https://training.sap.com/certification/c_s4ftr_2021-sap-certified-associate---treasury-with-sap-s4hana-2021-g/?\"> C_S4FTR_2021</a></span></li>\n</ul>\n<p><b><span>Please note:</span></b><span> To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\". </span></p>",
        "title": "SAP Certified Associate - SAP S/4HANA Cloud Private Edition, Treasury",
        "templateId": "f70fe234-c77b-4a38-b4a4-cb369cbc9b25",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/d246a72a-c044-4b4a-9b79-fb38879b916e/image.png",
        "objId": "C_S4FTR",
        "id": "3807ff16-8485-4985-90de-0009e5d6f2c0",
        "robots": "ALL",
        "relations": [
            "assessment",
            "exam"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-treasury-with-sap-s-4hana",
        "acronym": "C_S4FTR"
    },
    {
        "languages": [
            "English",
            "German",
            "Japanese",
            "Chinese",
            "French",
            "Portuguese",
            "Spanish"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills required for the SAP S/4HANA Manufacturing consultant profile and proves that you have an overall understanding and in-depth technical skills to participate as a member of a project team in a mentored role.</p><p>&nbsp;</p><p><span>This certification is also available in the (older) release versions:</span><span> </span><span><a rel=\"noopener\" target=\"_blank\" href=\"https://training.sap.com/certification/c_ts422_2021-sap-certified-associate---sap-s4hana-production-planning-and-manufacturing-g/?\">C_TS422_2021</a></span><span> and </span><span><a rel=\"noopener\" target=\"_blank\" href=\"https://training.sap.com/certification/c_ts422_2022-sap-certified-associate---sap-s4hana-production-planning-and-manufacturing-g/?\">C_TS422_2022</a></span><span>. <br><b>&nbsp;<br>Please note:</b> To prepare for this certification, it is necessary to take Learning Journey </span><span><a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a></span><span> in addition to the Learning Journey(s) displayed under \"how to prepare\". </span></p>",
        "title": "SAP Certified Associate - SAP S/4HANA Cloud Private Edition, Production Planning and Manufacturing",
        "templateId": "a283a790-d003-4c9a-ae27-54af1ca5cbef",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/f93a3c0c-f86a-4523-8454-e17ae1cc2e56/image.png",
        "objId": "C_TS422",
        "id": "2df0276a-062a-4f5f-86e0-98354a653b1e",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-production-planning-and-manufacturing",
        "acronym": "C_TS422"
    },
    {
        "languages": [
            "English",
            "German",
            "French",
            "Japanese",
            "Chinese",
            "Spanish",
            "Portuguese"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills and proven skills in the area of SAP S/4HANA Management Accounting. This certificate is an entry-level qualification for Management Accounting consultants on SAP S/4HANA. It is recommended for those with experience in SAP implementation projects relevant for SAP S/4HANA Cloud, private edition and SAP S/4HANA on-premise.</p>\n<p>This certification is also available in the (older) release version <a href=\"https://training.sap.com/certification/c_ts4co_2021-sap-certified-associate---sap-s4hana-2021-for-management-accounting-g/?\">C_TS4CO_2021</a>.</p>\n<p><strong>Please note:</strong><span><strong> </strong>To prepare for this certification, it is necessary to take Learning Journey <a rel=\"noopener\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a> in addition to the Learning Journey(s) displayed under \"how to prepare\". </span></p>",
        "title": "SAP Certified Associate - SAP S/4HANA for Management Accounting Associates",
        "templateId": "bc24dbf1-42f5-4398-9e95-b73305999b52",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/8739524f-3023-4d18-8920-5f71a8fb79aa/image.png",
        "objId": "C_TS4CO",
        "id": "d80480e4-d4a4-48c0-b07c-6645c275f3be",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-s-4hana-for-management-accounting-associates",
        "acronym": "C_TS4CO"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "80",
        "description": "<p>This certification verifies that you possess the core skills required for an Integration Consultant for SAP SuccessFactors Full Cloud/Core Hybrid. This certification is an entry-level qualification for SAP partner consultants, allowing them to apply skills in projects under experienced consultant guidance. Once certified, registered SAP partner consultants will be granted provisioning rights. Customers and independent consultants will not be granted provisioning rights.</p>\n<p>&nbsp;</p>\n<ul style=\"margin-top: 0in; margin-bottom: 0in;\">\n<li style=\"margin-top: 0in; margin-right: 0in; margin-bottom: 0in; font-size: 11pt; font-family: Calibri, sans-serif;\"><b><span>Note:</span></b><span> </span>To prepare for this certification, it is necessary to take the Learning Journey <a rel=\"noopener\" target=\"_blank\" href=\"https://learning.sap.com/learning-journeys/managing-clean-core-for-sap-s-4hana-cloud\">Managing Clean Core</a>, in addition to the Learning Journey displayed under \"How to Prepare.\"</li>\n</ul>\n<p>&nbsp;</p>\n<p><b><span>Note:</span></b><span> The next version of this exam should be available between December 16-19, 2024.</span></p>",
        "title": "SAP Certified Associate - Implementation Consultant - SAP SuccessFactors Full Cloud/Core Hybrid",
        "templateId": "6967cd9e-20fc-448e-b32d-002b2198c503",
        "duration": 180,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/200x200/images/87194f82-e6b1-4b99-978e-7e1ebf386eb3/image.png",
        "objId": "C_HRHFC",
        "id": "054159f7-5490-4703-bd75-a6c6baef2dfe",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-successfactors-full-cloud-core-hybrid",
        "acronym": "C_HRHFC"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "administrator",
        "questions": "60",
        "description": "<p>This certification verifies that you possess the core skills of configuration, administration, analytics, and integration required for services procurement and proves that you have an overall understanding of the services procurement workflow and can implement the skills practically in projects to contribute to the success in a mentored role.</p>",
        "title": "SAP Certified Associate - Administrator - SAP Fieldglass Services Procurement",
        "templateId": "2fa92f81-738f-4628-a3a1-cc49c5670b2b",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/974a3815-66e6-47f8-a566-b0b78c3e57c8/blob",
        "objId": "C_TFG61",
        "id": "da69028b-7d43-4af9-9157-00fe625f3149",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-fieldglass-services-procurement",
        "acronym": "C_TFG61"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "administrator",
        "questions": "60",
        "description": "<p>This certification verifies that you possess the core skills of configuration, administration, analytics, and integration required for contingent workforce and proves that you have an overall understanding of the contingent workflow and can implement the skills practically in projects to contribute to the success in a mentored role.</p>",
        "title": "SAP Certified Associate - Administrator - SAP Fieldglass Contingent Workforce Management",
        "templateId": "2b0d7161-3df2-4521-89ee-1dd91cbc384d",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/400x400/images/97b3a96c-f454-4e03-9013-f40bece7538c/blob",
        "objId": "C_TFG51",
        "id": "34923a12-1f36-482d-86af-dab9df111fa4",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-sap-fieldglass-contingent-workforce-management",
        "acronym": "C_TFG51"
    },
    {
        "languages": [
            "English"
        ],
        "level": "Associate",
        "stayCertified": "true",
        "release": "1",
        "validForYears": "1",
        "roles": "consultant",
        "questions": "60",
        "description": "<p>This certification validates your proficiency in application lifecycle management skills and core knowledge in SAP Cloud ALM, that are necessary for the Solution Transformation Consultant profile. It demonstrates a comprehensive understanding and in-depth skills required to lead a project team as a solution transformation consultant, guiding the team through the solution transformation journey using SAP Cloud ALM.&nbsp;</p>",
        "title": "SAP Certified Associate - Solution Transformation Consultant with SAP Cloud ALM",
        "templateId": "4a100b20-3656-43b8-802d-bbf5f8c2840b",
        "duration": 120,
        "validForDays": "365",
        "imageUrl": "https://images.credly.com/size/340x340/images/da756182-348a-4557-aebc-59b9806811ce/blob",
        "objId": "C_STC",
        "id": "13f70360-c13a-423b-891b-18039b34ee0e",
        "robots": "ALL",
        "relations": [
            "exam",
            "assessment"
        ],
        "objType": "certification",
        "slug": "sap-certified-associate-solution-transformation-consultant-with-sap-cloud-alm",
        "acronym": "C_STC"
    },
    {
        "title": "SAP Certified Technology Associate - SAP Integration Suite",
        "acronym": "SAP Certified Technology Associate - SAP Integration Suite"
    },
    {
        "title": "SAP Certified Development Associate - ABAP with SAP NetWeaver 7.50",
        "acronym": "SAP Certified Development Associate - ABAP with SAP NetWeaver 7.50"
    },
    {
        "title": "SAP Certified Development Associate - SAP Cloud Platform Integration",
        "acronym": "SAP Certified Development Associate - SAP Cloud Platform Integration"
    },
    {
        "title": "SAP Certified Development Associate - SAP HANA 2.0 (SPS05)",
        "acronym": "SAP Certified Development Associate - SAP HANA 2.0 (SPS05)"
    },
    {
        "title": "SAP Certified Development Associate - SAP Extension Suite",
        "acronym": "SAP Certified Development Associate - SAP Extension Suite"
    },
    {
        "title": "SAP Certified Application Associate - SAP S/4HANA for Financial Accounting Associates (SAP S/4HANA 2020)",
        "acronym": "SAP Certified Application Associate - SAP S/4HANA for Financial Accounting Associates (SAP S/4HANA 2020)"
    },
    {
        "title": "SAP Certified Application Associate - SAP S/4HANA Production Planning and Manufacturing (SAP S/4HANA 2020)",
        "acronym": "SAP Certified Application Associate - SAP S/4HANA Production Planning and Manufacturing (SAP S/4HANA 2020)"
    },
    {
        "title": "SAP Certified Technology Professional - System Security Architect",
        "acronym": "SAP Certified Technology Professional - System Security Architect"
    },
    {
        "title": "SAP Certified Development Professional - SAP Commerce Cloud 1905 Developer",
        "acronym": "SAP Certified Development Professional - SAP Commerce Cloud 1905 Developer"
    },
    {
        "title": "SAP Certified Specialist - SAP Activate for Cloud Solutions Project Manager",
        "acronym": "SAP Certified Specialist - SAP Activate for Cloud Solutions Project Manager"
    },
    {
        "title": "SAP Certified Specialist - SAP BW/4HANA 2.0 Delta",
        "acronym": "SAP Certified Specialist - SAP BW/4HANA 2.0 Delta"
    },
    {
        "title": "SAP Certified Associate - SAP HANA 2.0 (SPS05)",
        "acronym": "SAP Certified Associate - SAP HANA 2.0 (SPS05)"
    },
    {
        "title": "SAP Certified Associate - SAP Ariba",
        "acronym": "SAP Certified Associate - SAP Ariba"
    },
    {
        "title": "SAP Certified Solution Architect - SAP S/4HANA Cloud, public edition",
        "acronym": "SAP Certified Solution Architect - SAP S/4HANA Cloud, public edition"
    },
    {
        "title": "SAP Certified Solution Architect - SAP Cloud Platform",
        "acronym": "SAP Certified Solution Architect - SAP Cloud Platform"
    },
    {
        "title": "SAP Certified Application Associate - SAP IS Retail with SAP ERP 6.0 EHP7",
        "acronym": "SAP Certified Application Associate - SAP IS Retail with SAP ERP 6.0 EHP7"
    },
    {
        "title": "SAP Certified Application Associate - Utilities with SAP ERP 6.0",
        "acronym": "SAP Certified Application Associate - Utilities with SAP ERP 6.0"
    }
]