import {
  GET_ALL_CANDIDATES_REQUEST,
  GET_ALL_CANDIDATES_REQUEST_SUCCESS,
  GET_ALL_CANDIDATES_REQUEST_FAILURE,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_REQUEST_SUCCESS,
  GET_ALL_USERS_REQUEST_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from './type';

const initialState = {
    usersData: {
      allUsers: {}
    },
    deleteRequesting: false,
    requesting: false
  }
  const adminUsers = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_USERS_REQUEST:
        return {
          ...state,
          requesting: true
        }

      case GET_ALL_USERS_REQUEST_SUCCESS:
        return {
          ...state,
          requesting: false,
          usersData: {
            ...state.usersData,
            allUsers: action.data
          }
        }
      case GET_ALL_USERS_REQUEST_FAILURE:
        return {
          ...state,
          requesting: false,
          error: action.data
        }
      case DELETE_USER_REQUEST:
        return {
          ...state,
          deleteRequesting: true
        }

      case DELETE_USER_SUCCESS:
        return {
          ...state,
          deleteRequesting: false
        }
      case DELETE_USER_FAILURE:
        return {
          ...state,
          deleteRequesting: false,
          error: action.data
        }
      case GET_ALL_CANDIDATES_REQUEST:
        return {
          ...state,
          requesting: true
        }

      case GET_ALL_CANDIDATES_REQUEST_SUCCESS:
        return {
          ...state,
          requesting: false,
          usersData: {
            ...state.usersData,
            allCandidates: action.data
          }
        }
      case GET_ALL_CANDIDATES_REQUEST_FAILURE:
        return {
          ...state,
          requesting: false,
          error: action.data
        }
      default:
        return state
    }
  }

  export default adminUsers
