export const MICROSOFT_CERTIFICATIONS = [
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "administrator"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Administrator"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.ms-102",
                "display_name": "MS-102",
                "url": "/credentials/certifications/exams/ms-102/"
            }
        ],
        "uid": "certification.m365-enterprise-administrator",
        "title": "Microsoft 365 Certified: Administrator Expert",
        "last_modified": "2024-07-15T21:08:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "entra",
            "entra-id",
            "office-365"
        ],
        "display_products": [
            "Microsoft 365",
            "Microsoft Entra",
            "Microsoft Entra ID",
            "Office 365"
        ],
        "url": "/credentials/certifications/m365-administrator-expert/",
        "acronym": "certification.m365-enterprise-administrator"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "administrator"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Administrator"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.m365-collaboration-communications-systems-engineer",
        "title": "Microsoft 365 Certified: Collaboration Communications Systems Engineer Associate",
        "last_modified": "2024-07-22T20:51:00Z",
        "locale": "en-us",
        "products": [
            "m365"
        ],
        "display_products": [
            "Microsoft 365"
        ],
        "url": "/credentials/certifications/m365-collaboration-communications-systems-engineer/",
        "acronym": "certification.m365-collaboration-communications-systems-engineer"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "administrator"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Administrator"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.modern-desktop",
        "title": "Microsoft 365 Certified: Endpoint Administrator Associate",
        "last_modified": "2024-10-22T18:09:00Z",
        "locale": "en-us",
        "products": [
            "m365"
        ],
        "display_products": [
            "Microsoft 365"
        ],
        "url": "/credentials/certifications/modern-desktop/",
        "acronym": "certification.modern-desktop"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-fundamentals-badge.svg",
        "roles": [
            "administrator"
        ],
        "levels": [
            "beginner"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "fundamentals"
        ],
        "display_roles": [
            "Administrator"
        ],
        "display_levels": [
            "Beginner"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Fundamentals"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.microsoft-365-fundamentals",
        "title": "Microsoft 365 Certified: Fundamentals",
        "last_modified": "2024-07-08T21:08:00Z",
        "locale": "en-us",
        "products": [
            "office-365"
        ],
        "display_products": [
            "Office 365"
        ],
        "url": "/credentials/certifications/microsoft-365-fundamentals/",
        "acronym": "certification.microsoft-365-fundamentals"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "administrator"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Administrator"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.m365-teams-administrator-associate",
        "title": "Microsoft 365 Certified: Teams Administrator Associate",
        "last_modified": "2024-10-29T14:40:00Z",
        "locale": "en-us",
        "products": [
            "m365"
        ],
        "display_products": [
            "Microsoft 365"
        ],
        "url": "/credentials/certifications/m365-teams-administrator-associate/",
        "acronym": "certification.m365-teams-administrator-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.microsoft-certified-educator",
        "title": "Microsoft Certified Educator",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "windows"
        ],
        "display_products": [
            "Windows"
        ],
        "url": "/credentials/certifications/microsoft-certified-educator/",
        "acronym": "certification.microsoft-certified-educator"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "ai-engineer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "artificial-intelligence"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "AI Engineer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Artificial intelligence"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-ai-engineer",
        "title": "Microsoft Certified: Azure AI Engineer Associate",
        "last_modified": "2024-10-31T21:15:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-ai-engineer/",
        "acronym": "certification.azure-ai-engineer"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-fundamentals-badge.svg",
        "roles": [
            "ai-engineer"
        ],
        "levels": [
            "beginner"
        ],
        "subjects": [
            "artificial-intelligence"
        ],
        "credential_types": [
            "certification",
            "fundamentals"
        ],
        "display_roles": [
            "AI Engineer"
        ],
        "display_levels": [
            "Beginner"
        ],
        "display_subjects": [
            "Artificial intelligence"
        ],
        "display_credential_types": [
            "",
            "Fundamentals"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-ai-fundamentals",
        "title": "Microsoft Certified: Azure AI Fundamentals",
        "last_modified": "2024-07-22T06:01:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-ai-fundamentals/",
        "acronym": "certification.azure-ai-fundamentals"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "administrator"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Administrator"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-administrator",
        "title": "Microsoft Certified: Azure Administrator Associate",
        "last_modified": "2024-07-08T21:08:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-administrator/",
        "acronym": "certification.azure-administrator"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-specialty-badge.svg",
        "roles": [
            "developer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "data-management"
        ],
        "credential_types": [
            "certification",
            "specialty"
        ],
        "display_roles": [
            "Developer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Data management"
        ],
        "display_credential_types": [
            "",
            "Specialty"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-cosmos-db-developer-specialty",
        "title": "Microsoft Certified: Azure Cosmos DB Developer Specialty",
        "last_modified": "2024-10-24T18:08:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-cosmos-db-developer-specialty/",
        "acronym": "certification.azure-cosmos-db-developer-specialty"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "data-engineer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "data-management",
            "data-engineering"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Data Engineer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Data management",
            "Data engineering"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-data-engineer",
        "title": "Microsoft Certified: Azure Data Engineer Associate",
        "last_modified": "2024-10-24T18:08:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-data-engineer/",
        "acronym": "certification.azure-data-engineer"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-fundamentals-badge.svg",
        "roles": [
            "data-engineer"
        ],
        "levels": [
            "beginner"
        ],
        "subjects": [
            "data-management",
            "databases"
        ],
        "credential_types": [
            "certification",
            "fundamentals"
        ],
        "display_roles": [
            "Data Engineer"
        ],
        "display_levels": [
            "Beginner"
        ],
        "display_subjects": [
            "Data management",
            "Databases"
        ],
        "display_credential_types": [
            "",
            "Fundamentals"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-data-fundamentals",
        "title": "Microsoft Certified: Azure Data Fundamentals",
        "last_modified": "2024-11-01T18:06:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-data-fundamentals/",
        "acronym": "certification.azure-data-fundamentals"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "data-scientist"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "artificial-intelligence",
            "machine-learning"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Data Scientist"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Artificial intelligence",
            "Machine learning"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-data-scientist",
        "title": "Microsoft Certified: Azure Data Scientist Associate",
        "last_modified": "2024-10-16T16:43:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-data-scientist/",
        "acronym": "certification.azure-data-scientist"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "database-administrator"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "data-management",
            "databases"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Database Administrator"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Data management",
            "Databases"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-database-administrator-associate",
        "title": "Microsoft Certified: Azure Database Administrator Associate",
        "last_modified": "2024-10-25T17:16:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-database-administrator-associate/",
        "acronym": "certification.azure-database-administrator-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "developer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "app-development"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Developer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Application development"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-developer",
        "title": "Microsoft Certified: Azure Developer Associate",
        "last_modified": "2024-10-11T18:35:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-developer/",
        "acronym": "certification.azure-developer"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-fundamentals-badge.svg",
        "roles": [
            "administrator"
        ],
        "levels": [
            "beginner"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "fundamentals"
        ],
        "display_roles": [
            "Administrator"
        ],
        "display_levels": [
            "Beginner"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Fundamentals"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-fundamentals",
        "title": "Microsoft Certified: Azure Fundamentals",
        "last_modified": "2024-07-08T21:08:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-fundamentals/",
        "acronym": "certification.azure-fundamentals"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "network-engineer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "infrastructure"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Network Engineer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Technical infrastructure"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-network-engineer-associate",
        "title": "Microsoft Certified: Azure Network Engineer Associate",
        "last_modified": "2024-10-25T17:16:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-network-engineer-associate/",
        "acronym": "certification.azure-network-engineer-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "security-engineer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "security"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Security Engineer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Security"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-security-engineer",
        "title": "Microsoft Certified: Azure Security Engineer Associate",
        "last_modified": "2024-10-30T16:29:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-security-engineer/",
        "acronym": "certification.azure-security-engineer"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "solution-architect"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Solution Architect"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.az-305",
                "display_name": "AZ-305",
                "url": "/credentials/certifications/exams/az-305/"
            }
        ],
        "uid": "certification.azure-solutions-architect",
        "title": "Microsoft Certified: Azure Solutions Architect Expert",
        "last_modified": "2024-10-18T18:39:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-solutions-architect/",
        "acronym": "certification.azure-solutions-architect"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-specialty-badge.svg",
        "roles": [
            "administrator"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "specialty"
        ],
        "display_roles": [
            "Administrator"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Specialty"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-virtual-desktop-specialty",
        "title": "Microsoft Certified: Azure Virtual Desktop Specialty",
        "last_modified": "2024-10-16T16:43:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-virtual-desktop-specialty/",
        "acronym": "certification.azure-virtual-desktop-specialty"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-specialty-badge.svg",
        "roles": [
            "solution-architect"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "infrastructure"
        ],
        "credential_types": [
            "certification",
            "specialty"
        ],
        "display_roles": [
            "Solution Architect"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Technical infrastructure"
        ],
        "display_credential_types": [
            "",
            "Specialty"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.azure-for-sap-workloads-specialty",
        "title": "Microsoft Certified: Azure for SAP Workloads Specialty",
        "last_modified": "2024-10-11T18:35:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/azure-for-sap-workloads-specialty/",
        "acronym": "certification.azure-for-sap-workloads-specialty"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "administrator",
            "security-engineer",
            "security-operations-analyst",
            "solution-architect"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Administrator",
            "Security Engineer",
            "Security Operations Analyst",
            "Solution Architect"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.sc-100",
                "display_name": "SC-100",
                "url": "/credentials/certifications/exams/sc-100/"
            }
        ],
        "uid": "certification.cybersecurity-architect-expert",
        "title": "Microsoft Certified: Cybersecurity Architect Expert",
        "last_modified": "2024-07-22T20:51:00Z",
        "locale": "en-us",
        "products": [
            "microsoft-defender",
            "defender-for-cloud",
            "azure",
            "microsoft-sentinel",
            "entra",
            "entra-id",
            "defender-identity",
            "defender-for-cloud-apps"
        ],
        "display_products": [
            "Microsoft Defender",
            "Microsoft Defender for Cloud",
            "Azure",
            "Microsoft Sentinel",
            "Microsoft Entra",
            "Microsoft Entra ID",
            "Microsoft Defender for Identity",
            "Microsoft Defender for Cloud Apps"
        ],
        "url": "/credentials/certifications/cybersecurity-architect-expert/",
        "acronym": "certification.cybersecurity-architect-expert"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "devops-engineer"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "DevOps Engineer"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.az-400",
                "display_name": "AZ-400",
                "url": "/credentials/certifications/exams/az-400/"
            }
        ],
        "uid": "certification.devops-engineer",
        "title": "Microsoft Certified: DevOps Engineer Expert",
        "last_modified": "2024-07-26T16:52:00Z",
        "locale": "en-us",
        "products": [
            "azure",
            "azure-devops",
            "azure-kubernetes-service",
            "azure-container-instances",
            "azure-automation"
        ],
        "display_products": [
            "Azure",
            "Azure DevOps",
            "Azure Kubernetes Service (AKS)",
            "Azure Container Instances",
            "Azure Automation"
        ],
        "url": "/credentials/certifications/devops-engineer/",
        "acronym": "certification.devops-engineer"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-analyst",
            "business-owner",
            "business-user",
            "developer",
            "solution-architect",
            "support-engineer",
            "technology-manager"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Business Analyst",
            "Business Owner",
            "Business User",
            "Developer",
            "Solution Architect",
            "Support Engineer",
            "Technology Manager"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-business-central-developer-associate",
        "title": "Microsoft Certified: Dynamics 365 Business Central Developer Associate",
        "last_modified": "2024-09-20T23:04:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-business-central-developer-associate/",
        "acronym": "certification.d365-business-central-developer-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-business-central-functional-consultant-associate",
        "title": "Microsoft Certified: Dynamics 365 Business Central Functional Consultant Associate",
        "last_modified": "2024-08-08T13:17:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365",
            "dynamics-business-central",
            "power-platform"
        ],
        "display_products": [
            "Dynamics 365",
            "Business Central",
            "Microsoft Power Platform"
        ],
        "url": "/credentials/certifications/d365-business-central-functional-consultant-associate/",
        "acronym": "certification.d365-business-central-functional-consultant-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-analyst",
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications",
            "customer-relationship-management",
            "marketing-sales",
            "solution-design"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Business Analyst",
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications",
            "Customer relationship management",
            "Marketing and sales",
            "Solution design"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-customer-experience-analyst-associate",
        "title": "Microsoft Certified: Dynamics 365 Customer Experience Analyst Associate",
        "last_modified": "2024-10-29T17:22:00Z",
        "locale": "en-us",
        "products": [
            "dynamics",
            "dynamics-365",
            "dynamics-customer-engagement"
        ],
        "display_products": [
            "Dynamics",
            "Dynamics 365",
            "Customer Engagement apps"
        ],
        "url": "/credentials/certifications/d365-customer-experience-analyst-associate/",
        "acronym": "certification.d365-customer-experience-analyst-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-specialty-badge.svg",
        "roles": [
            "data-analyst",
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "specialty"
        ],
        "display_roles": [
            "Data Analyst",
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Specialty"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.customer-data-platform-specialty",
        "title": "Microsoft Certified: Dynamics 365 Customer Insights (Data) Specialty",
        "last_modified": "2024-12-02T23:15:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-customer-insights-data-specialty/",
        "acronym": "certification.customer-data-platform-specialty"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-functional-consultant-marketing-v3",
        "title": "Microsoft Certified: Dynamics 365 Customer Insights (Journeys) Functional Consultant Associate",
        "last_modified": "2024-12-02T23:15:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-customer-insights-journeys-functional-consultant/",
        "acronym": "certification.d365-functional-consultant-marketing-v3"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-functional-consultant-customer-service-v3",
        "title": "Microsoft Certified: Dynamics 365 Customer Service Functional Consultant Associate",
        "last_modified": "2024-11-07T19:51:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-functional-consultant-customer-service-v3/",
        "acronym": "certification.d365-functional-consultant-customer-service-v3"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-functional-consultant-field-service-v3",
        "title": "Microsoft Certified: Dynamics 365 Field Service Functional Consultant Associate",
        "last_modified": "2024-10-22T18:09:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-functional-consultant-field-service/",
        "acronym": "certification.d365-functional-consultant-field-service-v3"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications",
            "business-reporting",
            "finance-accounting"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications",
            "Business reporting",
            "Finance and accounting"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-functional-consultant-financials",
        "title": "Microsoft Certified: Dynamics 365 Finance Functional Consultant Associate",
        "last_modified": "2024-09-20T16:48:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-functional-consultant-financials/",
        "acronym": "certification.d365-functional-consultant-financials"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-fundamentals-badge.svg",
        "roles": [
            "business-owner"
        ],
        "levels": [
            "beginner"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "fundamentals"
        ],
        "display_roles": [
            "Business Owner"
        ],
        "display_levels": [
            "Beginner"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Fundamentals"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-fundamentals-customer-engagement-apps-crm",
        "title": "Microsoft Certified: Dynamics 365 Fundamentals (CRM)",
        "last_modified": "2024-11-18T22:34:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-fundamentals-customer-engagement-apps-crm/",
        "acronym": "certification.d365-fundamentals-customer-engagement-apps-crm"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-fundamentals-badge.svg",
        "roles": [
            "business-owner"
        ],
        "levels": [
            "beginner"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "fundamentals"
        ],
        "display_roles": [
            "Business Owner"
        ],
        "display_levels": [
            "Beginner"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Fundamentals"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-fundamentals-finance-and-operations-apps-erp",
        "title": "Microsoft Certified: Dynamics 365 Fundamentals (ERP)",
        "last_modified": "2024-11-22T00:53:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-fundamentals-finance-and-operations-apps-erp/",
        "acronym": "certification.d365-fundamentals-finance-and-operations-apps-erp"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-functional-consultant-sales-v3",
        "title": "Microsoft Certified: Dynamics 365 Sales Functional Consultant Associate",
        "last_modified": "2024-12-02T23:15:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-functional-consultant-sales-v3/",
        "acronym": "certification.d365-functional-consultant-sales-v3"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications",
            "supply-chain-management"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications",
            "Supply chain management"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-functional-consultant-supply-chain-management",
        "title": "Microsoft Certified: Dynamics 365 Supply Chain Management Functional Consultant Associate",
        "last_modified": "2024-07-25T17:12:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-functional-consultant-supply-chain-management/",
        "acronym": "certification.d365-functional-consultant-supply-chain-management"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "functional-consultant"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.mb-335",
                "display_name": "MB-335",
                "url": "/credentials/certifications/exams/mb-335/"
            }
        ],
        "uid": "certification.d365-supply-chain-management-functional-consultant-expert",
        "title": "Microsoft Certified: Dynamics 365 Supply Chain Management Functional Consultant Expert",
        "last_modified": "2024-11-19T21:17:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365",
            "dynamics-scm"
        ],
        "display_products": [
            "Dynamics 365",
            "Supply Chain Management"
        ],
        "url": "/credentials/certifications/d365-supply-chain-management-functional-consultant-expert/",
        "acronym": "certification.d365-supply-chain-management-functional-consultant-expert"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "developer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "app-development",
            "backend-development",
            "devops",
            "business-applications",
            "custom-app-development",
            "infrastructure",
            "application-management",
            "migration"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Developer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Application development",
            "Backend development",
            "DevOps",
            "Business applications",
            "Custom app development",
            "Technical infrastructure",
            "Application management",
            "Migration"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.d365-finance-and-operations-apps-developer-associate",
        "title": "Microsoft Certified: Dynamics 365: Finance and Operations Apps Developer Associate",
        "last_modified": "2024-11-18T22:34:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-finance-and-operations-apps-developer-associate/",
        "acronym": "certification.d365-finance-and-operations-apps-developer-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "functional-consultant",
            "solution-architect"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant",
            "Solution Architect"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.mb-700",
                "display_name": "MB-700",
                "url": "/credentials/certifications/exams/mb-700/"
            }
        ],
        "uid": "certification.d365-finance-and-operations-apps-solution-architect-expert",
        "title": "Microsoft Certified: Dynamics 365: Finance and Operations Apps Solution Architect Expert",
        "last_modified": "2024-11-07T19:51:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365"
        ],
        "display_products": [
            "Dynamics 365"
        ],
        "url": "/credentials/certifications/d365-finance-and-operations-apps-solution-architect-expert/",
        "acronym": "certification.d365-finance-and-operations-apps-solution-architect-expert"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "data-engineer",
            "data-analyst"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "data-management",
            "data-analytics"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Data Engineer",
            "Data Analyst"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Data management",
            "Data analytics"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.fabric-analytics-engineer-associate",
        "title": "Microsoft Certified: Fabric Analytics Engineer Associate",
        "last_modified": "2024-11-15T17:42:00Z",
        "locale": "en-us",
        "products": [
            "fabric"
        ],
        "display_products": [
            "Microsoft Fabric"
        ],
        "url": "/credentials/certifications/fabric-analytics-engineer-associate/",
        "acronym": "certification.fabric-analytics-engineer-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-analyst",
            "data-analyst",
            "data-engineer",
            "data-scientist"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Business Analyst",
            "Data Analyst",
            "Data Engineer",
            "Data Scientist"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.dp-700",
                "display_name": "DP-700",
                "url": "/credentials/certifications/exams/dp-700/"
            }
        ],
        "uid": "certification.fabric-data-engineer-associate",
        "title": "Microsoft Certified: Fabric Data Engineer Associate",
        "last_modified": "2024-10-22T15:40:00Z",
        "locale": "en-us",
        "products": [
            "fabric"
        ],
        "display_products": [
            "Microsoft Fabric"
        ],
        "url": "/credentials/certifications/fabric-data-engineer-associate/",
        "acronym": "certification.fabric-data-engineer-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "security-engineer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "security"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Security Engineer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Security"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.identity-and-access-administrator",
        "title": "Microsoft Certified: Identity and Access Administrator Associate",
        "last_modified": "2024-07-29T21:11:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/identity-and-access-administrator/",
        "acronym": "certification.identity-and-access-administrator"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "administrator"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "security"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Administrator"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Security"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.information-protection-administrator",
        "title": "Microsoft Certified: Information Protection and Compliance Administrator Associate",
        "last_modified": "2024-07-08T21:08:00Z",
        "locale": "en-us",
        "products": [
            "m365"
        ],
        "display_products": [
            "Microsoft 365"
        ],
        "url": "/credentials/certifications/information-protection-administrator/",
        "acronym": "certification.information-protection-administrator"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "developer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Developer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.power-automate-rpa-developer-associate",
        "title": "Microsoft Certified: Power Automate RPA Developer Associate",
        "last_modified": "2024-11-19T21:17:00Z",
        "locale": "en-us",
        "products": [
            "power-platform"
        ],
        "display_products": [
            "Microsoft Power Platform"
        ],
        "url": "/credentials/certifications/power-automate-rpa-developer-associate/",
        "acronym": "certification.power-automate-rpa-developer-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "data-analyst"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "data-management",
            "data-analytics"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Data Analyst"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Data management",
            "Data analytics"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.data-analyst-associate",
        "title": "Microsoft Certified: Power BI Data Analyst Associate",
        "last_modified": "2024-10-21T16:47:00Z",
        "locale": "en-us",
        "products": [
            "power-platform",
            "power-bi"
        ],
        "display_products": [
            "Microsoft Power Platform",
            "Power BI"
        ],
        "url": "/credentials/certifications/data-analyst-associate/",
        "acronym": "certification.data-analyst-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "developer"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Developer"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.power-platform-developer-associate",
        "title": "Microsoft Certified: Power Platform Developer Associate",
        "last_modified": "2024-11-21T18:38:00Z",
        "locale": "en-us",
        "products": [
            "power-platform"
        ],
        "display_products": [
            "Microsoft Power Platform"
        ],
        "url": "/credentials/certifications/power-platform-developer-associate/",
        "acronym": "certification.power-platform-developer-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "functional-consultant"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "business-applications"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Functional Consultant"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Business applications"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.power-platform-functional-consultant-associate",
        "title": "Microsoft Certified: Power Platform Functional Consultant Associate",
        "last_modified": "2024-11-26T20:10:00Z",
        "locale": "en-us",
        "products": [
            "power-platform"
        ],
        "display_products": [
            "Microsoft Power Platform"
        ],
        "url": "/credentials/certifications/power-platform-functional-consultant-associate/",
        "acronym": "certification.power-platform-functional-consultant-associate"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-fundamentals-badge.svg",
        "roles": [
            "maker"
        ],
        "levels": [
            "beginner"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "fundamentals"
        ],
        "display_roles": [
            "App Maker"
        ],
        "display_levels": [
            "Beginner"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Fundamentals"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.power-platform-fundamentals",
        "title": "Microsoft Certified: Power Platform Fundamentals",
        "last_modified": "2024-07-08T21:08:00Z",
        "locale": "en-us",
        "products": [
            "power-platform"
        ],
        "display_products": [
            "Microsoft Power Platform"
        ],
        "url": "/credentials/certifications/power-platform-fundamentals/",
        "acronym": "certification.power-platform-fundamentals"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "developer",
            "functional-consultant",
            "solution-architect"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Developer",
            "Functional Consultant",
            "Solution Architect"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.pl-600",
                "display_name": "PL-600",
                "url": "/credentials/certifications/exams/pl-600/"
            }
        ],
        "uid": "certification.power-platform-solution-architect-expert",
        "title": "Microsoft Certified: Power Platform Solution Architect Expert",
        "last_modified": "2024-10-30T16:29:00Z",
        "locale": "en-us",
        "products": [
            "dynamics-365",
            "power-platform",
            "ai-builder",
            "power-automate",
            "power-bi",
            "power-apps",
            "microsoft-copilot-studio",
            "azure",
            "entra",
            "entra-id",
            "dynamics-customer-engagement",
            "dataverse",
            "azure-app-configuration",
            "azure-app-service"
        ],
        "display_products": [
            "Dynamics 365",
            "Microsoft Power Platform",
            "AI Builder",
            "Power Automate",
            "Power BI",
            "Power Apps",
            "Microsoft Copilot Studio",
            "Azure",
            "Microsoft Entra",
            "Microsoft Entra ID",
            "Customer Engagement apps",
            "Microsoft Dataverse",
            "Azure App Configuration",
            "Azure App Service"
        ],
        "url": "/credentials/certifications/power-platform-solution-architect-expert/",
        "acronym": "certification.power-platform-solution-architect-expert"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "security-operations-analyst"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [
            "security"
        ],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Security Operations Analyst"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [
            "Security"
        ],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.security-operations-analyst",
        "title": "Microsoft Certified: Security Operations Analyst Associate",
        "last_modified": "2024-10-21T16:47:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/security-operations-analyst/",
        "acronym": "certification.security-operations-analyst"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-fundamentals-badge.svg",
        "roles": [
            "security-engineer"
        ],
        "levels": [
            "beginner"
        ],
        "subjects": [
            "security"
        ],
        "credential_types": [
            "certification",
            "fundamentals"
        ],
        "display_roles": [
            "Security Engineer"
        ],
        "display_levels": [
            "Beginner"
        ],
        "display_subjects": [
            "Security"
        ],
        "display_credential_types": [
            "",
            "Fundamentals"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.security-compliance-and-identity-fundamentals",
        "title": "Microsoft Certified: Security, Compliance, and Identity Fundamentals",
        "last_modified": "2024-07-26T16:52:00Z",
        "locale": "en-us",
        "products": [
            "azure"
        ],
        "display_products": [
            "Azure"
        ],
        "url": "/credentials/certifications/security-compliance-and-identity-fundamentals/",
        "acronym": "certification.security-compliance-and-identity-fundamentals"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "administrator",
            "identity-access-admin",
            "ip-admin",
            "network-engineer",
            "security-engineer",
            "support-engineer",
            "technology-manager"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "role-based"
        ],
        "display_roles": [
            "Administrator",
            "Identity and Access Administrator",
            "Information Protection and Compliance Administrator",
            "Network Engineer",
            "Security Engineer",
            "Support Engineer",
            "Technology Manager"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "Role-based"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.az-800",
                "display_name": "AZ-800",
                "url": "/credentials/certifications/exams/az-800/"
            },
            {
                "uid": "exam.az-801",
                "display_name": "AZ-801",
                "url": "/credentials/certifications/exams/az-801/"
            }
        ],
        "uid": "certification.windows-server-hybrid-administrator",
        "title": "Microsoft Certified: Windows Server Hybrid Administrator Associate",
        "last_modified": "2024-07-26T16:52:00Z",
        "locale": "en-us",
        "products": [
            "entra",
            "entra-id",
            "azure",
            "azure-arc",
            "azure-automation",
            "microsoft-defender",
            "defender-for-cloud",
            "azure-disk-encryption",
            "azure-files",
            "azure-monitor",
            "azure-policy",
            "azure-powershell",
            "microsoft-sentinel",
            "azure-virtual-machines",
            "defender-endpoint",
            "windows-server"
        ],
        "display_products": [
            "Microsoft Entra",
            "Microsoft Entra ID",
            "Azure",
            "Azure Arc",
            "Azure Automation",
            "Microsoft Defender",
            "Microsoft Defender for Cloud",
            "Azure Disk Encryption",
            "Azure Files",
            "Azure Monitor",
            "Azure Policy",
            "Azure PowerShell",
            "Microsoft Sentinel",
            "Azure Virtual Machines",
            "Microsoft Defender for Endpoint",
            "Windows Server"
        ],
        "url": "/credentials/certifications/windows-server-hybrid-administrator/",
        "acronym": "certification.windows-server-hybrid-administrator"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.77-726",
                "display_name": "77-726",
                "url": "/credentials/certifications/exams/77-726/"
            },
            {
                "uid": "exam.77-728",
                "display_name": "77-728",
                "url": "/credentials/certifications/exams/77-728/"
            },
            {
                "uid": "exam.77-729",
                "display_name": "77-729",
                "url": "/credentials/certifications/exams/77-729/"
            }
        ],
        "uid": "certification.microsoft-office-specialist-master-certification-2016",
        "title": "Microsoft Office Specialist: 2016 Master",
        "last_modified": "2024-01-26T06:54:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office"
        ],
        "display_products": [
            "Microsoft 365",
            "Office"
        ],
        "url": "/credentials/certifications/microsoft-office-specialist-master-certification-2016/",
        "acronym": "certification.microsoft-office-specialist-master-certification-2016"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-access-2016",
        "title": "Microsoft Office Specialist: Access (Office 2016)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-access"
        ],
        "display_products": [
            "Microsoft 365",
            "Access"
        ],
        "url": "/credentials/certifications/mos-access-2016/",
        "acronym": "certification.mos-access-2016"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.mo-110",
                "display_name": "MO-110",
                "url": "/credentials/certifications/exams/mo-110/"
            }
        ],
        "uid": "certification.mos-associate-m365-apps",
        "title": "Microsoft Office Specialist: Associate (Microsoft 365 Apps)",
        "last_modified": "2024-02-23T22:42:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-powerpoint",
            "office-word",
            "office-excel"
        ],
        "display_products": [
            "Microsoft 365",
            "PowerPoint",
            "Word",
            "Excel"
        ],
        "url": "/credentials/certifications/microsoft-office-specialist-associate-m365-apps/",
        "acronym": "certification.mos-associate-m365-apps"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.mo-100",
                "display_name": "MO-100",
                "url": "/credentials/certifications/exams/mo-100/"
            },
            {
                "uid": "exam.mo-200",
                "display_name": "MO-200",
                "url": "/credentials/certifications/exams/mo-200/"
            },
            {
                "uid": "exam.mo-300",
                "display_name": "MO-300",
                "url": "/credentials/certifications/exams/mo-300/"
            }
        ],
        "uid": "certification.microsoft-office-specialist-associate-2019",
        "title": "Microsoft Office Specialist: Associate (Office 2019)",
        "last_modified": "2024-02-22T15:55:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office"
        ],
        "display_products": [
            "Microsoft 365",
            "Office"
        ],
        "url": "/credentials/certifications/microsoft-office-specialist-associate-2019/",
        "acronym": "certification.microsoft-office-specialist-associate-2019"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-excel-2016",
        "title": "Microsoft Office Specialist: Excel (Office 2016)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-excel"
        ],
        "display_products": [
            "Microsoft 365",
            "Excel"
        ],
        "url": "/credentials/certifications/mos-excel-2016/",
        "acronym": "certification.mos-excel-2016"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-excel-associate-m365-apps",
        "title": "Microsoft Office Specialist: Excel Associate (Microsoft 365 Apps)",
        "last_modified": "2024-03-29T19:01:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-excel"
        ],
        "display_products": [
            "Microsoft 365",
            "Excel"
        ],
        "url": "/credentials/certifications/mos-excel-associate-m365-apps/",
        "acronym": "certification.mos-excel-associate-m365-apps"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-excel-2019",
        "title": "Microsoft Office Specialist: Excel Associate (Office 2019)",
        "last_modified": "2024-05-30T15:39:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-excel"
        ],
        "display_products": [
            "Microsoft 365",
            "Excel"
        ],
        "url": "/credentials/certifications/mos-excel-2019/",
        "acronym": "certification.mos-excel-2019"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-excel-expert-365-apps",
        "title": "Microsoft Office Specialist: Excel Expert (Microsoft 365 Apps)",
        "last_modified": "2024-04-05T17:48:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-excel"
        ],
        "display_products": [
            "Microsoft 365",
            "Excel"
        ],
        "url": "/credentials/certifications/mos-excel-expert-m365-apps/",
        "acronym": "certification.mos-excel-expert-365-apps"
    },
    {
        "icon_url": "/media/learn/certification/badges/certification-shield-3-stars.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-excel-2016-expert",
        "title": "Microsoft Office Specialist: Excel Expert (Office 2016)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-excel"
        ],
        "display_products": [
            "Microsoft 365",
            "Excel"
        ],
        "url": "/credentials/certifications/mos-excel-2016-expert/",
        "acronym": "certification.mos-excel-2016-expert"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.mo-211",
                "display_name": "MO-211",
                "url": "/credentials/certifications/exams/mo-211/"
            }
        ],
        "uid": "certification.mos-expert-m365-apps",
        "title": "Microsoft Office Specialist: Expert (Microsoft 365 Apps)",
        "last_modified": "2024-02-23T22:42:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-word",
            "office-excel"
        ],
        "display_products": [
            "Microsoft 365",
            "Word",
            "Excel"
        ],
        "url": "/credentials/certifications/microsoft-office-specialist-expert-m365-apps/",
        "acronym": "certification.mos-expert-m365-apps"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [
            {
                "uid": "exam.mo-101",
                "display_name": "MO-101",
                "url": "/credentials/certifications/exams/mo-101/"
            },
            {
                "uid": "exam.mo-201",
                "display_name": "MO-201",
                "url": "/credentials/certifications/exams/mo-201/"
            },
            {
                "uid": "exam.mo-500",
                "display_name": "MO-500",
                "url": "/credentials/certifications/exams/mo-500/"
            }
        ],
        "uid": "certification.microsoft-office-specialist-expert-2019",
        "title": "Microsoft Office Specialist: Expert (Office 2019)",
        "last_modified": "2024-02-22T15:55:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office",
            "office-excel",
            "office-365"
        ],
        "display_products": [
            "Microsoft 365",
            "Office",
            "Excel",
            "Office 365"
        ],
        "url": "/credentials/certifications/microsoft-office-specialist-expert-2019/",
        "acronym": "certification.microsoft-office-specialist-expert-2019"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-access-expert-2019",
        "title": "Microsoft Office Specialist: Microsoft Access Expert (Office 2019)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-access"
        ],
        "display_products": [
            "Microsoft 365",
            "Access"
        ],
        "url": "/credentials/certifications/mos-access-expert-2019/",
        "acronym": "certification.mos-access-expert-2019"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-excel-expert-2019",
        "title": "Microsoft Office Specialist: Microsoft Excel Expert (Office 2019)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-excel"
        ],
        "display_products": [
            "Microsoft 365",
            "Excel"
        ],
        "url": "/credentials/certifications/mos-excel-expert-2019/",
        "acronym": "certification.mos-excel-expert-2019"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-word-expert-2019",
        "title": "Microsoft Office Specialist: Microsoft Word Expert (Office 2019)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-word"
        ],
        "display_products": [
            "Microsoft 365",
            "Word"
        ],
        "url": "/credentials/certifications/mos-word-expert-2019/",
        "acronym": "certification.mos-word-expert-2019"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-outlook-2016",
        "title": "Microsoft Office Specialist: Outlook (Office 2016)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-outlook"
        ],
        "display_products": [
            "Microsoft 365",
            "Outlook"
        ],
        "url": "/credentials/certifications/mos-outlook-2016/",
        "acronym": "certification.mos-outlook-2016"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-outlook-2019",
        "title": "Microsoft Office Specialist: Outlook Associate (Office 2019)",
        "last_modified": "2024-05-30T15:39:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-outlook"
        ],
        "display_products": [
            "Microsoft 365",
            "Outlook"
        ],
        "url": "/credentials/certifications/mos-outlook-2019/",
        "acronym": "certification.mos-outlook-2019"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-powerpoint-2016",
        "title": "Microsoft Office Specialist: PowerPoint (Office 2016)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-powerpoint"
        ],
        "display_products": [
            "Microsoft 365",
            "PowerPoint"
        ],
        "url": "/credentials/certifications/mos-powerpoint-2016/",
        "acronym": "certification.mos-powerpoint-2016"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-powerpoint-associate-365-apps",
        "title": "Microsoft Office Specialist: PowerPoint Associate (Microsoft 365 Apps)",
        "last_modified": "2024-03-29T19:01:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-powerpoint"
        ],
        "display_products": [
            "Microsoft 365",
            "PowerPoint"
        ],
        "url": "/credentials/certifications/mos-powerpoint-associate-m365-apps/",
        "acronym": "certification.mos-powerpoint-associate-365-apps"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-powerpoint-2019",
        "title": "Microsoft Office Specialist: PowerPoint Associate (Office 2019)",
        "last_modified": "2024-05-30T15:39:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-powerpoint"
        ],
        "display_products": [
            "Microsoft 365",
            "PowerPoint"
        ],
        "url": "/credentials/certifications/mos-powerpoint-2019/",
        "acronym": "certification.mos-powerpoint-2019"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-word-2016",
        "title": "Microsoft Office Specialist: Word (Office 2016)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-word"
        ],
        "display_products": [
            "Microsoft 365",
            "Word"
        ],
        "url": "/credentials/certifications/mos-word-2016/",
        "acronym": "certification.mos-word-2016"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-word-associate-m365-apps",
        "title": "Microsoft Office Specialist: Word Associate (Microsoft 365 Apps)",
        "last_modified": "2024-05-30T15:39:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-word"
        ],
        "display_products": [
            "Microsoft 365",
            "Word"
        ],
        "url": "/credentials/certifications/mos-word-associate-m365-apps/",
        "acronym": "certification.mos-word-associate-m365-apps"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-associate-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "intermediate"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Intermediate"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-word-2019",
        "title": "Microsoft Office Specialist: Word Associate (Office 2019)",
        "last_modified": "2024-05-30T15:39:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-word"
        ],
        "display_products": [
            "Microsoft 365",
            "Word"
        ],
        "url": "/credentials/certifications/mos-word-2019/",
        "acronym": "certification.mos-word-2019"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-word-expert-365-apps",
        "title": "Microsoft Office Specialist: Word Expert (Microsoft 365 Apps)",
        "last_modified": "2024-06-27T20:07:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-word"
        ],
        "display_products": [
            "Microsoft 365",
            "Word"
        ],
        "url": "/credentials/certifications/mos-word-expert-m365-apps/",
        "acronym": "certification.mos-word-expert-365-apps"
    },
    {
        "icon_url": "/media/learn/certification/badges/microsoft-certified-expert-badge.svg",
        "roles": [
            "business-user"
        ],
        "levels": [
            "advanced"
        ],
        "subjects": [],
        "credential_types": [
            "certification",
            "mos"
        ],
        "display_roles": [
            "Business User"
        ],
        "display_levels": [
            "Advanced"
        ],
        "display_subjects": [],
        "display_credential_types": [
            "",
            "MOS"
        ],
        "hidden": false,
        "exams": [],
        "uid": "certification.mos-word-2016-expert",
        "title": "Microsoft Office Specialist: Word Expert (Office 2016)",
        "last_modified": "2024-03-22T15:21:00Z",
        "locale": "en-us",
        "products": [
            "m365",
            "office-word"
        ],
        "display_products": [
            "Microsoft 365",
            "Word"
        ],
        "url": "/credentials/certifications/mos-word-2016-expert/",
        "acronym": "certification.mos-word-2016-expert"
    }
]