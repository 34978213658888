export const DELL_CERTIFICATIONS = [
    {
        "title": "DECA-ISM",
        "function": "Technology / Associate",
        "exam_name": "Information Storage and Management v3 Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-001-2015Q3&apass=INFORMATION",
        "exams": [
            "Information Storage and Management v3 Exam ; E05-001"
        ],
        "level": "Associate",
        "training": "Information Storage and Management",
        "company": "Dell",
        "exam_id": "E05-001",
        "acronym": "DECA-ISM"
    },
    {
        "title": "DECA-DS",
        "function": "Technology / Associate",
        "exam_name": "Data Science and Big Data Analytics Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-007-2012Q1&apass=DATASCIENCE",
        "exams": [
            "Data Science and Big Data Analytics Exam ; E20-007"
        ],
        "level": "Associate",
        "training": "Data Science",
        "company": "Dell",
        "exam_id": "E20-007",
        "acronym": "DECA-DS"
    },
    {
        "title": "DECS-DS",
        "function": "Technology / Associate",
        "exam_name": "Advanced Analytics Specialist Exam for Data Scientists",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-065-2015Q2&apass=ADVANCED",
        "exams": [
            "Advanced Analytics Specialist Exam for Data Scientists ; E20-065"
        ],
        "level": "Specialist",
        "training": "Advanced Analytics",
        "company": "Dell",
        "exam_id": "E20-065",
        "acronym": "DECS-DS"
    },
    {
        "title": "DECA-DPM",
        "function": "Technology / Associate",
        "exam_name": "Data Protection and Management Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-005-2016Q2&apass=DATAPROTECTIONANDMANAGEME",
        "exams": [
            "Data Protection and Management Exam ; E10-005"
        ],
        "level": "Associate",
        "training": "Data Protection and Management",
        "company": "Dell",
        "exam_id": "E10-005",
        "acronym": "DECA-DPM"
    },
    {
        "title": "DECS-CA",
        "function": "Design",
        "exam_name": "Cloud Infrastructure Specialist Exam for Cloud Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-020-2015Q4&apass=CLOUDINFRASTRUCTURE",
        "exams": [
            "Cloud Infrastructure Specialist Exam for Cloud Architects ; E20-020"
        ],
        "level": "Specialist",
        "training": "Cloud Infrastructure",
        "company": "Dell",
        "exam_id": "E20-020",
        "acronym": "DECS-CA"
    },
    {
        "title": "DECE-CA",
        "function": "Design",
        "exam_name": "Cloud Services Expert Exam for Cloud Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-920-2016Q1&apass=CLOUDSERVICES",
        "exams": [
            "Cloud Services Expert Exam for Cloud Architects ; E20-920"
        ],
        "level": "Expert",
        "training": "Cloud Services",
        "company": "Dell",
        "exam_id": "E20-920",
        "acronym": "DECE-CA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | VMAX All Flash and VMAX3 Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-507-2016Q4&apass=VAF3SSSA",
        "exams": [
            "Specialist Systems Administrator | VMAX All Flash and VMAX3 Solutions Exam ; E20-507"
        ],
        "level": "Specialist",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-507",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | Isilon Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-559-2016Q3&apass=ISILONSOLUTIONS",
        "exams": [
            "Specialist Systems Administrator | Isilon Solutions Exam ; E20-559"
        ],
        "level": "Specialist",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "E20-559",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | VPLEX Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-562-2016Q3&apass=VPLEXSASPECIALIST",
        "exams": [
            "Specialist Systems Administrator | VPLEX Exam ; E20-562"
        ],
        "level": "Specialist",
        "training": "VPLEX",
        "company": "Dell",
        "exam_id": "E20-562",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | XtremIO Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-568-2017Q4&apass=SPESAXTRIOSO",
        "exams": [
            "Specialist Systems Administrator | XtremIO Solutions Exam ; E20-568"
        ],
        "level": "Specialist",
        "training": "XtremIO Solutions",
        "company": "Dell",
        "exam_id": "E20-568",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | RecoverPoint Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-575-2017Q1&apass=RECOVPOINTSPESAS",
        "exams": [
            "Specialist Systems Administrator | RecoverPoint Exam ; E20-575"
        ],
        "level": "Specialist",
        "training": "RecoverPoint",
        "company": "Dell",
        "exam_id": "E20-575",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist - Systems Administrator Data Domain Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-585-2017Q3&apass=SPSYSDADADO",
        "exams": [
            "Specialist - Systems Administrator Data Domain Exam ; E20-585"
        ],
        "level": "Specialist",
        "training": "Data Domain",
        "company": "Dell",
        "exam_id": "E20-585",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | NetWorker Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-597-2017Q1&apass=NETWORKERSPSA",
        "exams": [
            "Specialist Systems Administrator | NetWorker Exam ; E20-597"
        ],
        "level": "Specialist",
        "training": "NetWorker",
        "company": "Dell",
        "exam_id": "E20-597",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | Avamar Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-598-2017Q2&apass=AVASPEXSAS",
        "exams": [
            "Specialist Systems Administrator | Avamar Exam ; E20-598"
        ],
        "level": "Specialist",
        "training": "Avamar",
        "company": "Dell",
        "exam_id": "E20-598",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECE",
        "function": "Manage",
        "exam_name": "Expert VMAX All Flash and VMAX3 Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-807-2018Q1&apass=EXPVMAXAFVMAX3",
        "exams": [
            "Expert VMAX All Flash and VMAX3 Solutions Exam ; E20-807"
        ],
        "level": "Expert",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-807",
        "acronym": "DECE"
    },
    {
        "title": "DEC",
        "function": "Deploy ",
        "exam_name": "ScaleIO 2.x Server-Based SAN Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-214-2016Q3&apass=SCALEIOV2.0",
        "exams": [
            "ScaleIO 2.x Server-Based SAN Exam ; E22-214"
        ],
        "level": "Product Technology Specific",
        "training": "ScaleIO 2.x Server-Based SAN",
        "company": "Dell",
        "exam_id": "E22-214",
        "acronym": "DEC"
    },
    {
        "title": "DEC",
        "function": " Manage",
        "exam_name": "ScaleIO 2.x Server-Based SAN Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-214-2016Q3&apass=SCALEIOV2.0",
        "exams": [
            "ScaleIO 2.x Server-Based SAN Exam ; E22-214"
        ],
        "level": "Product Technology Specific",
        "training": "ScaleIO 2.x Server-Based SAN",
        "company": "Dell",
        "exam_id": "E22-214",
        "acronym": "DEC"
    },
    {
        "title": "DEC",
        "function": "Deploy ",
        "exam_name": "ViPR SRM 4.x Storage Resource Management Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-265-2017Q3&apass=VIPSRM4STREMA",
        "exams": [
            "ViPR SRM 4.x Storage Resource Management Exam ; E22-265"
        ],
        "level": "Product Technology Specific",
        "training": "ViPR SRM 4.x Storage Resource Management",
        "company": "Dell",
        "exam_id": "E22-265",
        "acronym": "DEC"
    },
    {
        "title": "DEC",
        "function": " Manage",
        "exam_name": "ViPR SRM 4.x Storage Resource Management Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-265-2017Q3&apass=VIPSRM4STREMA",
        "exams": [
            "ViPR SRM 4.x Storage Resource Management Exam ; E22-265"
        ],
        "level": "Product Technology Specific",
        "training": "ViPR SRM 4.x Storage Resource Management",
        "company": "Dell",
        "exam_id": "E22-265",
        "acronym": "DEC"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "Specialist Implementation Engineer Dell EMC Unity Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-393-2018Q3&apass=IEUNITYSOL",
        "exams": [
            "Specialist Implementation Engineer Dell EMC Unity Solutions Exam ; E20-393"
        ],
        "level": "Specialist",
        "training": "Dell EMC Unity",
        "company": "Dell",
        "exam_id": "E20-393",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "VPLEX Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-260-2016Q3&apass=VPLEXIESPECIALIST",
        "exams": [
            "VPLEX Specialist Exam for Implementation Engineers ; E20-260"
        ],
        "level": "Specialist",
        "training": "VPLEX",
        "company": "Dell",
        "exam_id": "E20-260",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "VMAX All Flash and VMAX3 Solutions Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-307-2016Q4&apass=VMAXAF3SSIE",
        "exams": [
            "VMAX All Flash and VMAX3 Solutions Specialist Exam for Implementation Engineers ; E20-307"
        ],
        "level": "Specialist",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-307",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECE",
        "function": "Deploy",
        "exam_name": "Expert VMAX All Flash and VMAX3 Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-807-2018Q1&apass=EXPVMAXAFVMAX3",
        "exams": [
            "Expert VMAX All Flash and VMAX3 Solutions Exam ; E20-807"
        ],
        "level": "Expert",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-807",
        "acronym": "DECE"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "Isilon Solutions Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-357-2017Q2&apass=ISILONSOSPIES",
        "exams": [
            "Isilon Solutions Specialist Exam for Implementation Engineers ; E20-357"
        ],
        "level": "Specialist",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "E20-357",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "XtremIO Solutions Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-368-2017Q4&apass=SPEIEXTRIOSO",
        "exams": [
            "XtremIO Solutions Specialist Exam for Implementation Engineers ; E20-368"
        ],
        "level": "Specialist",
        "training": "XtremIO Solutions",
        "company": "Dell",
        "exam_id": "E20-368",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "RecoverPoint Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-375-2016Q2&apass=RECOVERPOINT",
        "exams": [
            "RecoverPoint Specialist Exam for Implementation Engineers ; E20-375"
        ],
        "level": "Specialist",
        "training": "RecoverPoint",
        "company": "Dell",
        "exam_id": "E20-375",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "Specialist -  Implementation Engineer Data Domain Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-385-2017Q3&apass=SPIMPDADO",
        "exams": [
            "Specialist -  Implementation Engineer Data Domain Exam ; E20-385"
        ],
        "level": "Specialist",
        "training": "Data Domain",
        "company": "Dell",
        "exam_id": "E20-385",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "NetWorker Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-593-2017Q1&apass=NETWORKERSPIE",
        "exams": [
            "NetWorker Specialist Exam for Implementation Engineers ; E20-593"
        ],
        "level": "Specialist",
        "training": "NetWorker",
        "company": "Dell",
        "exam_id": "E20-593",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "Avamar Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-594-2017Q2&apass=AVASPEXIES",
        "exams": [
            "Avamar Specialist Exam for Implementation Engineers ; E20-594"
        ],
        "level": "Specialist",
        "training": "Avamar",
        "company": "Dell",
        "exam_id": "E20-594",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECE-IE",
        "function": "Deploy",
        "exam_name": "NetWorker Expert Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-893-2016Q3&apass=NETWORKEREXPERT",
        "exams": [
            "NetWorker Expert Exam for Implementation Engineers ; E20-893"
        ],
        "level": "Expert",
        "training": "NetWorker",
        "company": "Dell",
        "exam_id": "E20-893",
        "acronym": "DECE-IE"
    },
    {
        "title": "DECE-IE",
        "function": "Deploy",
        "exam_name": "Avamar Expert Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-895-2017Q2&apass=AVAEXPIES",
        "exams": [
            "Avamar Expert Exam for Implementation Engineers ; E20-895"
        ],
        "level": "Expert",
        "training": "Avamar",
        "company": "Dell",
        "exam_id": "E20-895",
        "acronym": "DECE-IE"
    },
    {
        "title": "DECS-PE",
        "function": "Support",
        "exam_name": "VMAX Family Specialist Exam for Platform Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-624-2015Q2&apass=VMAXFAMILY",
        "exams": [
            "VMAX Family Specialist Exam for Platform Engineers ; E20-624"
        ],
        "level": "Specialist",
        "training": "VMAX Family",
        "company": "Dell",
        "exam_id": "E20-624",
        "acronym": "DECS-PE"
    },
    {
        "title": "DECS-PE",
        "function": "Support",
        "exam_name": "Isilon Specialist Exam for Platform Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-655-2017Q2&apass=ISILONSPEPES",
        "exams": [
            "Isilon Specialist Exam for Platform Engineers ; E20-655"
        ],
        "level": "Specialist",
        "training": "Isilon",
        "company": "Dell",
        "exam_id": "E20-655",
        "acronym": "DECS-PE"
    },
    {
        "title": "DECS-PE",
        "function": "Support",
        "exam_name": "XtremIO Specialist Exam for PlatformEngineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-668-2017Q4&apass=SPEPLATXTREMIO",
        "exams": [
            "XtremIO Specialist Exam for PlatformEngineers ; E20-668"
        ],
        "level": "Specialist",
        "training": "XtremIO",
        "company": "Dell",
        "exam_id": "E20-668",
        "acronym": "DECS-PE"
    },
    {
        "title": "TSE",
        "function": "Support",
        "exam_name": "Isilon Specialist Exam for Technical Support Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-657-2016Q1&apass=ISILONSPECIALIST",
        "exams": [
            "Isilon Specialist Exam for Technical Support Engineers ; E20-657"
        ],
        "level": "Specialist",
        "training": "Isilon",
        "company": "Dell",
        "exam_id": "E20-657",
        "acronym": "TSE"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "Backup Recovery Solutions Design Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-329-2016Q4&apass=BARESOLUDESIGTA",
        "exams": [
            "Backup Recovery Solutions Design Exam for Technology Architects ; E20-329"
        ],
        "level": "Specialist",
        "training": "Backup Recovery Solutions",
        "company": "Dell",
        "exam_id": "E20-329",
        "acronym": "DECS-TA"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "XtremIO Solutions and Design Specialist Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-526-2015Q4&apass=XTREMIOSOLUTIONS",
        "exams": [
            "XtremIO Solutions and Design Specialist Exam for Technology Architects ; E20-526"
        ],
        "level": "Specialist",
        "training": "XtremIO Solutions",
        "company": "Dell",
        "exam_id": "E20-526",
        "acronym": "DECS-TA"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "VMAX3 Solutions and Design Specialist Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-542-2015Q2&apass=VMAX3SOLUTIONS",
        "exams": [
            "VMAX3 Solutions and Design Specialist Exam for Technology Architects ; E20-542"
        ],
        "level": "Specialist",
        "training": "VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-542",
        "acronym": "DECS-TA"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "Isilon Solutions and Design Specialist Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-555-2017Q2&apass=ISISOLDESSPETAS",
        "exams": [
            "Isilon Solutions and Design Specialist Exam for Technology Architects ; E20-555"
        ],
        "level": "Specialist",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "E20-555",
        "acronym": "DECS-TA"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "Backup Recovery Solutions Specialist Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-591-2016Q2&apass=BACKUPRECOVERY",
        "exams": [
            "Backup Recovery Solutions Specialist Exam for Technology Architects ; E20-591"
        ],
        "level": "Specialist",
        "training": "Backup Recovery Solutions",
        "company": "Dell",
        "exam_id": "E20-591",
        "acronym": "DECS-TA"
    },
    {
        "title": "DECE-TA",
        "function": "Design",
        "exam_name": "Backup Recovery Solutions Expert Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-891-2015Q3&apass=BACKUPRECOVERY",
        "exams": [
            "Backup Recovery Solutions Expert Exam for Technology Architects ; E20-891"
        ],
        "level": "Expert",
        "training": "Backup Recovery Solutions",
        "company": "Dell",
        "exam_id": "E20-891",
        "acronym": "DECE-TA"
    },
    {
        "title": "DECE",
        "function": "Design",
        "exam_name": "Expert VMAX All Flash and VMAX3 Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-807-2018Q1&apass=EXPVMAXAFVMAX3",
        "exams": [
            "Expert VMAX All Flash and VMAX3 Solutions Exam ; E20-807"
        ],
        "level": "Expert",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-807",
        "acronym": "DECE"
    },
    {
        "title": "DEC",
        "function": "Deploy",
        "exam_name": "VxRail Appliance 4.x Deployment and Implementation Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-285-2017Q1&apass=VXRAILAPP4XDI",
        "exams": [
            "VxRail Appliance 4.x Deployment and Implementation Exam ; E22-285"
        ],
        "level": "Product Technology Specific",
        "training": "VxRail Appliance 4.x",
        "company": "Dell",
        "exam_id": "E22-285",
        "acronym": "DEC"
    },
    {
        "title": "Dell Certified",
        "function": "Deploy ",
        "exam_name": "Dell  PowerEdge Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DCPPE200-2017Q1&apass=DELLPEPRO",
        "exams": [
            "Dell  PowerEdge Professional Exam ; DCPPE-200"
        ],
        "level": "Professional",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DCPPE-200",
        "acronym": "Dell Certified"
    },
    {
        "title": "Dell Certified",
        "function": " Manage ",
        "exam_name": "Dell  PowerEdge Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DCPPE200-2017Q1&apass=DELLPEPRO",
        "exams": [
            "Dell  PowerEdge Professional Exam ; DCPPE-200"
        ],
        "level": "Professional",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DCPPE-200",
        "acronym": "Dell Certified"
    },
    {
        "title": "Dell Certified",
        "function": " Support",
        "exam_name": "Dell  PowerEdge Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DCPPE200-2017Q1&apass=DELLPEPRO",
        "exams": [
            "Dell  PowerEdge Professional Exam ; DCPPE-200"
        ],
        "level": "Professional",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DCPPE-200",
        "acronym": "Dell Certified"
    },
    {
        "title": "Dell  Certified",
        "function": "Technology / Associate",
        "exam_name": "Dell  Networking Associate Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DCAN100-2017Q1&apass=DEASNETW",
        "exams": [
            "Dell  Networking Associate Exam ; DCAN-100"
        ],
        "level": "Associate",
        "training": "Networking",
        "company": "Dell",
        "exam_id": "DCAN-100",
        "acronym": "Dell  Certified"
    },
    {
        "title": "Dell Certified",
        "function": "Deploy ",
        "exam_name": "Dell  Networking Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DNDNS200-2017Q1&apass=DELLNETPRO",
        "exams": [
            "Dell  Networking Professional Exam ; DNSDNS-200"
        ],
        "level": "Professional",
        "training": "Networking",
        "company": "Dell",
        "exam_id": "DNSDNS-200",
        "acronym": "Dell Certified"
    },
    {
        "title": "Dell Certified",
        "function": " Manage ",
        "exam_name": "Dell  Networking Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DNDNS200-2017Q1&apass=DELLNETPRO",
        "exams": [
            "Dell  Networking Professional Exam ; DNSDNS-200"
        ],
        "level": "Professional",
        "training": "Networking",
        "company": "Dell",
        "exam_id": "DNSDNS-200",
        "acronym": "Dell Certified"
    },
    {
        "title": "Dell Certified",
        "function": " Support",
        "exam_name": "Dell  Networking Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DNDNS200-2017Q1&apass=DELLNETPRO",
        "exams": [
            "Dell  Networking Professional Exam ; DNSDNS-200"
        ],
        "level": "Professional",
        "training": "Networking",
        "company": "Dell",
        "exam_id": "DNSDNS-200",
        "acronym": "Dell Certified"
    },
    {
        "title": "DECS-PE",
        "function": "Support",
        "exam_name": "Specialist Platform Engineer | VMAX All Flash and VMAX3 Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-1141-2017Q3&apass=VMAXAFVMAX3SPEPES",
        "exams": [
            "Specialist Platform Engineer | VMAX All Flash and VMAX3 Exam ; DES-1141"
        ],
        "level": "Specialist",
        "training": "VMAX All Flash and VMAX3",
        "company": "Dell",
        "exam_id": "DES-1141",
        "acronym": "DECS-PE"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "Specialist Technology Architect Midrange Storage Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-1D11-2017Q3&apass=SPPEVMAXALL",
        "exams": [
            "Specialist Technology Architect Midrange Storage Solutions Exam ; DES-1D11"
        ],
        "level": "Specialist",
        "training": "Midrange Storage Solutions",
        "company": "Dell",
        "exam_id": "DES-1D11",
        "acronym": "DECS-TA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator Elastic Cloud Storage (ECS) Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-1B31-2017Q3&apass=SPSYSAECSSOL",
        "exams": [
            "Specialist Systems Administrator Elastic Cloud Storage (ECS) Exam ; DES-1B31"
        ],
        "level": "Specialist",
        "training": "Elastic Cloud Storage ECS",
        "company": "Dell",
        "exam_id": "DES-1B31",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator Converged Infrastructure Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-6131-2017Q4&apass=SPSYSACONVRINFRAS",
        "exams": [
            "Specialist Systems Administrator Converged Infrastructure Exam ; DES-6131"
        ],
        "level": "Specialist",
        "training": "Converged Infrastructure",
        "company": "Dell",
        "exam_id": "DES-6131",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator Hyper-converged Infrastructure Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-6331-2017Q4&apass=SPSAHYCONINF",
        "exams": [
            "Specialist Systems Administrator Hyper-converged Infrastructure Exam ; DES-6331"
        ],
        "level": "Specialist",
        "training": "Hyper-converged Infrastructure",
        "company": "Dell",
        "exam_id": "DES-6331",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECA-CSHC",
        "function": "Technology / Associate",
        "exam_name": "Associate – Converged Systems and Hybrid Cloud Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-64T-2017Q4&apass=ASSCONSYSHYBCLO",
        "exams": [
            "Associate – Converged Systems and Hybrid Cloud Exam ; DEA-64T1"
        ],
        "level": "Associate",
        "training": "Converged Systems and Hybrid Cloud",
        "company": "Dell",
        "exam_id": "DEA-64T1",
        "acronym": "DECA-CSHC"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy ",
        "exam_name": "Specialist Implementation Engineer SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Specialist Implementation Engineer SC Series Exam ; DES-1721"
        ],
        "level": "Specialist",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DES-1721",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": " Manage ",
        "exam_name": "Specialist Implementation Engineer SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Specialist Implementation Engineer SC Series Exam ; DES-1721"
        ],
        "level": "Specialist",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DES-1721",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": " Support",
        "exam_name": "Specialist Implementation Engineer SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Specialist Implementation Engineer SC Series Exam ; DES-1721"
        ],
        "level": "Specialist",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DES-1721",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy ",
        "exam_name": "Specialist Implementation Engineer PowerEdge Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES4121-2018Q1&apass=SPEIEPOWEDG",
        "exams": [
            "Specialist Implementation Engineer PowerEdge Exam ; DES-4121"
        ],
        "level": "Specialist",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DES-4121",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": " Manage ",
        "exam_name": "Specialist Implementation Engineer PowerEdge Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES4121-2018Q1&apass=SPEIEPOWEDG",
        "exams": [
            "Specialist Implementation Engineer PowerEdge Exam ; DES-4121"
        ],
        "level": "Specialist",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DES-4121",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECS-IE",
        "function": " Support",
        "exam_name": "Specialist Implementation Engineer PowerEdge Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES4121-2018Q1&apass=SPEIEPOWEDG",
        "exams": [
            "Specialist Implementation Engineer PowerEdge Exam ; DES-4121"
        ],
        "level": "Specialist",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DES-4121",
        "acronym": "DECS-IE"
    },
    {
        "title": "DECE",
        "function": "Deploy",
        "exam_name": "Expert SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Expert SC Series Exam ; DEE-1721"
        ],
        "level": "Expert",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DEE-1721",
        "acronym": "DECE"
    },
    {
        "title": "DECM-EA",
        "function": "",
        "exam_name": "Master Enterprise Architect Certification Description",
        "exams": [
            "Master Enterprise Architect Certification Description ; "
        ],
        "level": "Master",
        "training": "Cloud",
        "company": "Dell",
        "exam_id": "",
        "acronym": "DECM-EA"
    },
    {
        "title": "DECA-CIS",
        "function": "Technology / Associate",
        "exam_name": "Cloud Infrastructure and Services v3 Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEA2TT3-2018Q1&apass=CLOUINFRSERVER3",
        "exams": [
            "Cloud Infrastructure and Services v3 Exam ; DEA-2TT3"
        ],
        "level": "Associate",
        "training": "Cloud Infrastructure and Services",
        "company": "Dell",
        "exam_id": "DEA-2TT3",
        "acronym": "DECA-CIS"
    },
    {
        "title": "DECE",
        "function": "Manage",
        "exam_name": "Expert SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Expert SC Series Exam ; DEE-1721"
        ],
        "level": "Expert",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DEE-1721",
        "acronym": "DECE"
    },
    {
        "title": "DECE",
        "function": "Support",
        "exam_name": "Expert SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Expert SC Series Exam ; DEE-1721"
        ],
        "level": "Expert",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DEE-1721",
        "acronym": "DECE"
    },
    {
        "title": "DECE-SA",
        "function": "Manage",
        "exam_name": "Multi-Cloud",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEE2T31-2018Q1&apass=EXPSAMULTCLUD",
        "exams": [
            "Multi-Cloud ; DEE-2T31"
        ],
        "level": "Expert",
        "training": "Multi-Cloud",
        "company": "Dell",
        "exam_id": "DEE-2T31",
        "acronym": "DECE-SA"
    },
    {
        "title": "DECA-DS",
        "function": "Technology / Associate",
        "exam_name": "Associate Data Science and Big Data Analytics v2 Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEA7TT2-2018Q1&apass=ASODASCBGDAANYV2",
        "exams": [
            "Associate Data Science and Big Data Analytics v2 Exam ; DEA-7TT2"
        ],
        "level": "Associate",
        "training": "Data Science",
        "company": "Dell",
        "exam_id": "DEA-7TT2",
        "acronym": "DECA-DS"
    },
    {
        "title": "DECA-PowerEdge",
        "function": "Technology / Associate",
        "exam_name": "Associate PowerEdge Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEA41T1-2018Q2&apass=ASOPOWREDGE",
        "exams": [
            "Associate PowerEdge Exam ; DEA-41T1"
        ],
        "level": "Associate",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DEA-41T1",
        "acronym": "DECA-PowerEdge"
    },
    {
        "title": "DECE",
        "function": "Design",
        "exam_name": "Expert - Isilon Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEE1421-2018Q2&apass=EXPISILONSOLUTIONS",
        "exams": [
            "Expert - Isilon Solutions Exam ; DEE-1421"
        ],
        "level": "Expert",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "DEE-1421",
        "acronym": "DECE"
    },
    {
        "title": "DECE",
        "function": "Manage",
        "exam_name": "Expert - Isilon Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEE1421-2018Q2&apass=EXPISILONSOLUTIONS",
        "exams": [
            "Expert - Isilon Solutions Exam ; DEE-1421"
        ],
        "level": "Expert",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "DEE-1421",
        "acronym": "DECE"
    },
    {
        "title": "DECE",
        "function": "Deploy",
        "exam_name": "Expert - Isilon Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEE1421-2018Q2&apass=EXPISILONSOLUTIONS",
        "exams": [
            "Expert - Isilon Solutions Exam ; DEE-1421"
        ],
        "level": "Expert",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "DEE-1421",
        "acronym": "DECE"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator VxRack System FLEX Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES6231-2018Q3&apass=SPSAVXRACKFLEXSYS",
        "exams": [
            "Specialist Systems Administrator VxRack System FLEX Exam ; DES-6231"
        ],
        "level": "Specialist",
        "training": "VxRack Systems FLEX",
        "company": "Dell",
        "exam_id": "DES-6231",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "",
        "exam_name": "Specialist Systems Administrator VxRack System SDDC Exam",
        "exams": [
            "Specialist Systems Administrator VxRack System SDDC Exam ; DES-6431"
        ],
        "level": "Specialist",
        "training": "VxRack System SDDC",
        "company": "Dell",
        "exam_id": "DES-6431",
        "acronym": "DECS-SA"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator VxRail Appliance Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES6332-2018Q3&apass=SPSAVXRAILAPP",
        "exams": [
            "Specialist Systems Administrator VxRail Appliance Exam ; DES-6332"
        ],
        "level": "Specialist",
        "training": "VxRail Appliance",
        "company": "Dell",
        "exam_id": "DES-6332",
        "acronym": "DECS-SA"
    }
]