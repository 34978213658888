export const AWS_CERTIFICATIONS = [
    {
        "title": "AWS Certified Cloud Practitioner",
        "id": "AWS-CLOUD-Pra",
        "acronym": "AWS-CLOUD-Pra"
    },
    {
        "title": "AWS Certified AI Practitioner (Beta)",
        "id": "AWS-AI-Pra",
        "acronym": "AWS-AI-Pra"
    },
    {
        "title": "AWS Certified Solutions Architect - Associate",
        "id": "AWS-SA-A",
        "acronym": "AWS-SA-A"
    },
    {
        "title": "AWS Certified Developer - Associate",
        "id": "AWS-DEV-A",
        "acronym": "AWS-DEV-A"
    },
    {
        "title": "AWS Certified SysOps Administrator - Associate",
        "id": "AWS-SOA-A",
        "acronym": "AWS-SOA-A"
    },
    {
        "title": "AWS Certified Data Engineer - Associate",
        "id": "AWS-DE-A",
        "acronym": "AWS-DE-A"
    },
    {
        "title": "AWS Certified Machine Learning Engineer - Associate (Beta)",
        "id": "AWS-MLE-A",
        "acronym": "AWS-MLE-A"
    },
    {
        "title": "AWS Certified Solutions Architect - Professional",
        "id": "AWS-SA-Pro",
        "acronym": "AWS-SA-Pro"
    },
    {
        "title": "AWS Certified DevOps Engineer - Professional",
        "id": "AWS-DOE-Pro",
        "acronym": "AWS-DOE-Pro"
    },
    {
        "title": "AWS Certified Advanced Networking - Specialty",
        "id": "AWS-AN-S",
        "acronym": "AWS-AN-S"
    },
    {
        "title": "AWS Certified Machine Learning - Specialty",
        "id": "AWS-ML-S",
        "acronym": "AWS-ML-S"
    },
    {
        "title": "AWS Certified Security - Specialty",
        "id": "AWS-SEC-S",
        "acronym": "AWS-SEC-S"
    }
]
