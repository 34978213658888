Object.values(document.getElementsByClassName('fs-7 link-dark text-decoration-none stretched-link')).map((item) => {

    return {
        "title": item.textContent.trim(),
        "id": ""
    }
})

export const SCRUM_ORG_CERTIFICATIONS = [
    {
        "title": "Professional Scrum Master™ I Certification",
        "id": "PSM-I",
        "acronym": "PSM-I"
    },
    {
        "title": "Professional Scrum Master™ II Certification",
        "id": "PSM-II",
        "acronym": "PSM-II"
    },
    {
        "title": "Professional Scrum Master™ III Certification",
        "id": "PSM-III",
        "acronym": "PSM-III"
    },
    {
        "title": "Professional Scrum Product Owner™ I Certification",
        "id": "PSPO-I",
        "acronym": "PSPO-I"
    },
    {
        "title": "Professional Scrum Product Owner™ II Certification",
        "id": "PSPO-II",
        "acronym": "PSPO-II"
    },
    {
        "title": "Professional Scrum Product Owner™ III Certification",
        "id": "PSPO-III",
        "acronym": "PSPO-III"
    },
    {
        "title": "Professional Agile Leadership Certification",
        "id": "PAL-I",
        "acronym": "PAL-I"
    },
    {
        "title": "Professional Agile Leadership - EBM Certification",
        "id": "PAL-EBM",
        "acronym": "PAL-EBM"
    },
    {
        "title": "Professional Scrum Facilitation Skills Certification",
        "id": "PSF-Skills",
        "acronym": "PSF-Skills"
    },
    {
        "title": "Professional Scrum Product Backlog Management Skills Certification",
        "id": "PSPBM-Skills",
        "acronym": "PSPBM-Skills"
    },
    {
        "title": "Professional Scrum Developer Certification",
        "id": "PSD-I",
        "acronym": "PSD-I"
    },
    {
        "title": "Scaled Professional Scrum Certification",
        "id": "SPS",
        "acronym": "SPS"
    },
    {
        "title": "Professional Scrum with Kanban Certification",
        "id": "PSK-I",
        "acronym": "PSK-I"
    },
    {
        "title": "Professional Scrum with User Experience Certification",
        "id": "PSU-I",
        "acronym": "PSU-I"
    }
]