import {
  GET_CANDIDATE_LIST_REQUEST,
  GET_CANDIDATE_LIST_SUCCESS,
  GET_CANDIDATE_LIST_FAILURE,
  GET_CANDIDATE_COUNT_REQUEST,
  GET_CANDIDATE_COUNT_SUCCESS,
  EXPORT_CANDIDATE_LIST_SUCCESS,
  EXPORT_CANDIDATE_LIST_REQUEST,
  BULK_CANDIDATES_STATUS_UPDATE_REQUEST,
  BULK_CANDIDATES_STATUS_UPDATE_SUCCESS
} from "./type"

export const getCandidateListRequest = data => ({
  type: GET_CANDIDATE_LIST_REQUEST,
  data
})
export const getCandidateListSuccess = data => ({
  type: GET_CANDIDATE_LIST_SUCCESS,
  data
})
export const getCandidateListFailure = data => ({
  type: GET_CANDIDATE_LIST_FAILURE,
  data
})

export const getCandidateCountRequest = data => ({
  type: GET_CANDIDATE_COUNT_REQUEST,
  data
})

export const getCandidateCountSuccess = data => ({
  type: GET_CANDIDATE_COUNT_SUCCESS,
  data
})

export const exportCandidateListRequest = data => ({
  type: EXPORT_CANDIDATE_LIST_REQUEST,
  data
})

export const exportCandidateListSuccess = data => ({
  type: EXPORT_CANDIDATE_LIST_SUCCESS,
  data
})

export const bulkCandidatesStatusUpdateRequest = data => ({
  type: BULK_CANDIDATES_STATUS_UPDATE_REQUEST,
  data
})

export const bulkCandidatesStatusUpdateSuccess = data => ({
  type: BULK_CANDIDATES_STATUS_UPDATE_SUCCESS,
  data
})
