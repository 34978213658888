import React from "react"
import Button from "@mui/material/Button"

import "./style.scss"

const IconButton = ({ className, icon, endIcon, label, onClick, disabled, size }) => {
  return (
    <Button
      variant="contained"
      startIcon={icon}
      endIcon={endIcon}
      className={`icon-button ${className}`}
      onClick={onClick}
      disabled={disabled}
      size={size}
    >
      {label}
    </Button>
  )
}

export default IconButton
