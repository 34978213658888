export const JUNIPER_CERTIFICATIONS = [
    {
        "title": "SPECIALIST WAN Design, Specialist (JNCDS-WAN)",
        "decription": "Designed for networking professionals and designers with intermediate knowledge of wide area network (WAN) design, theory, and best practices, this written exam verifies the candidate’s understanding of WAN design principles.",
        "examDetails": [
            "Exam code: JN0-1360",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "JNCDA"
        ],
        "company": "Juniper",
        "acronym": "JNCDS-WAN"
    },
    {
        "title": "PROFESSIONAL Security, Professional (JNCIP-SEC)",
        "decription": "Designed for experienced networking professionals with advanced knowledge of the Juniper Networks Junos OS for SRX Series devices, this written exam verifies the candidate’s understanding of advanced security technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-634",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 120 minutes",
            "Exam type: 65 multiple choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 15.1"
        ],
        "prerequisites": [
            "JNCIS-SEC"
        ],
        "company": "Juniper",
        "acronym": "JNCIP-SEC"
    },
    {
        "title": "SPECIALIST Enterprise Routing and Switching, Specialist (JNCIS-ENT)",
        "decription": "Designed for experienced networking professionals with beginner to intermediate knowledge of routing and switching implementations in Junos, this written exam verifies the candidate’s basic understanding of routing and switching technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-347",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Software Release: "
        ],
        "prerequisites": [
            "JNCIA-Junos"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-ENT"
    },
    {
        "title": "SPECIALIST Automation and DevOps, Specialist (JNCIS-DevOps)",
        "decription": "Designed for networking professionals with intermediate knowledge of automation tools and best practices, this written exam verifies the candidate's understanding of the application of scripting tools such as PyEZ, Python, and Ansible to Junos devices and networks.",
        "examDetails": [
            "Exam code: JN0-420",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Software Release: 17.3"
        ],
        "prerequisites": [
            "JNCIA-Junos"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-DevOps"
    },
    {
        "title": "PROFESSIONAL Service Provider Routing and Switching, Professional (JNCIP-SP)",
        "decription": "Designed for experienced networking professionals with advanced knowledge of the Juniper Networks Junos OS, this written exam verifies the candidate’s understanding of advanced routing technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-662",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 120 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 17.1"
        ],
        "prerequisites": [
            "JNCIS-SP"
        ],
        "company": "Juniper",
        "acronym": "JNCIP-SP"
    },
    {
        "title": "PROFESSIONAL Enterprise Routing and Switching, Professional (JNCIP-ENT)",
        "decription": "JNCIP-ENT exam topics are based on the content of the recommended instructor-led training courses, as well as the additional resources.",
        "examDetails": [
            "Exam code: JN0-647",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 120 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Software Release: "
        ],
        "prerequisites": [
            "JNCIS-ENT"
        ],
        "company": "Juniper",
        "acronym": "JNCIP-ENT"
    },
    {
        "title": "SPECIALIST Service Provider Design, Specialist (JNCDS-SP)",
        "decription": "Designed for networking professionals and designers with intermediate knowledge of service provider design, theory, and best practices, this written exam verifies the candidate’s understanding of service provider design principles.",
        "examDetails": [
            "Exam code: JN0-1361",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "JNCDA"
        ],
        "company": "Juniper",
        "acronym": "JNCDS-SP"
    },
    {
        "title": "SPECIALIST Cloud, Specialist (JNCIS-Cloud)",
        "decription": "Designed for networking professionals with intermediate knowledge of software-defined networking, theory, and best practices, this written exam verifies the candidate’s understanding of software-defined networking principles and technologies.",
        "examDetails": [
            "Exam code: JN0-411",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Contrail Release: 3.2"
        ],
        "prerequisites": [
            "JNCIA-Cloud"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-Cloud"
    },
    {
        "title": "PROFESSIONAL Data Center, Professional (JNCIP-DC)",
        "decription": "Designed for experienced data center networking professionals with advanced knowledge of the Juniper Networks Junos software and data center devices, this written exam verifies the candidate’s understanding of data center technologies, related platform configuration, and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-680",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 120 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 15.1"
        ],
        "prerequisites": [
            "JNCIS-ENT"
        ],
        "company": "Juniper",
        "acronym": "JNCIP-DC"
    },
    {
        "title": "SPECIALIST Security, Specialist (JNCIS-SEC)",
        "decription": "Designed for experienced networking professionals with intermediate knowledge of the Juniper Networks Junos OS for SRX Series devices, this written exam verifies the candidate’s understanding of security technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-333",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 15.1"
        ],
        "prerequisites": [
            "JNCIA-Junos"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-SEC"
    },
    {
        "title": "ASSOCIATE Automation and DevOps, Associate (JNCIA-DevOps)",
        "decription": "This certification is designed for networking professionals with introductory-level knowledge of automation tools and best practices. The written exam for the certification verifies the candidate’s understanding of DevOps and automation concepts as they pertain to Juniper devices and solutions.",
        "examDetails": [
            "Exam code: JN0-220",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Scoring and pass/fail status is available immediately",
            "Junos Software Release: 18.1",
            "Python 2.7, and 3.4+",
            "Ansible 2.4"
        ],
        "prerequisites": [
            "None"
        ],
        "company": "Juniper",
        "acronym": "JNCIA-DevOps"
    },
    {
        "title": "ASSOCIATE Design, Associate (JNCDA)",
        "decription": "Designed for networking professionals and designers with beginner knowledge of network design, theory, and best practices, this written exam verifies the candidate's understanding of network design fundamentals.",
        "examDetails": [
            "Exam code: JN0-1100",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "None"
        ],
        "company": "Juniper",
        "acronym": "JNCDA"
    },
    {
        "title": "EXPERT Security, Expert (JNCIE-SEC)",
        "decription": "At the pinnacle of the Junos Security certification track is the 1-day JNCIE-SEC practical exam. This exam is designed to validate the networking professionals’ ability to deploy, configure, manage and troubleshoot JUNOS-based security platforms. Throughout this 8-hour practical exam, candidates will build a secure enterprise network consisting of multiple firewall devices interconnected via IPsec VPNs. Successful candidates will perform system configuration on all devices, configure secure management capabilities, install complex policies and attack prevention features, HA capabilities, IPS features.",
        "examDetails": [
            "Exam code: JPR-932",
            "Lab Exam (Hands-on Lab)",
            "Held at selected Juniper Networks testing centers",
            "Exam length: 8 hours",
            "Junos Software Release: 12.1"
        ],
        "prerequisites": [
            "JNCIP-SEC"
        ],
        "company": "Juniper",
        "acronym": "JNCIE-SEC"
    },
    {
        "title": "SPECIALIST Data Center Design, Specialist (JNCDS-DC)",
        "decription": "Designed for networking professionals and designers with intermediate knowledge of data center network design, theory, and best practices, this written exam verifies the candidate’s understanding of data center network design principles.",
        "examDetails": [
            "Exam code: JN0-1301",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "JNCDA"
        ],
        "company": "Juniper",
        "acronym": "JNCDS-DC"
    },
    {
        "title": "EXPERT Service Provider Routing and Switching, Expert (JNCIE-SP)",
        "decription": "At the pinnacle of the Service Provider Routing and Switching certification track is the 1-day JNCIE-SP Lab Exam. This exam is designed to validate the networking professionals’ ability to implement, troubleshoot and maintain Juniper Networks service provider networks. The 8-hour format of this exam requires that candidates build a service provider network consisting of multiple MX series routers. Successful candidates will perform system configuration on all devices, implement various protocols, policies and VPNs, HA capabilities, and Class of Services.",
        "examDetails": [
            "Exam code: JPR-960",
            "Lab Exam (Hands-on Lab)",
            "Held at selected Juniper Networks testing centers",
            "Exam length: 8 hours",
            "Junos Software Release: 12.3"
        ],
        "prerequisites": [
            "JNCIP-SP"
        ],
        "company": "Juniper",
        "acronym": "JNCIE-SP"
    },
    {
        "title": "EXPERT Enterprise Routing and Switching, Expert (JNCIE-ENT)",
        "decription": "At the pinnacle of the Enterprise Routing and Switching certification track is the 1-day JNCIE-ENT practical exam. This exam is designed to validate the networking professionals’ ability to deploy, configure, manage and troubleshoot Junos-based enterprise routing and switching platforms. Throughout this 8-hour practical exam, candidates will build an enterprise network infrastructure consisting of multiple routers and switching devices. Successful candidates will perform system configuration on all devices, configure protocols and features like IPV6, OSPF V2, OSPF V3, BGP, MSDP, PIM, SSM, RSTP, LLDP, 802.1x, CoS, routing policies.",
        "examDetails": [
            "Exam code: JPR-943",
            "Lab Exam (Hands-on Lab)",
            "Held at selected Juniper Networks testing centers",
            "Exam length: 8 hours",
            "Junos Software Release: 11.4"
        ],
        "prerequisites": [
            "JNCIP-ENT"
        ],
        "company": "Juniper",
        "acronym": "JNCIE-ENT"
    },
    {
        "title": "SPECIALIST Security Design, Specialist (JNCDS-SEC)",
        "decription": "Designed for networking professionals and designers with intermediate knowledge of network security, theory, and best practices, this written exam verifies the candidate’s understanding of designing secure networks.",
        "examDetails": [
            "Exam code: JN0-1330",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "JNCDA"
        ],
        "company": "Juniper",
        "acronym": "JNCDS-SEC"
    },
    {
        "title": "ASSOCIATE Junos, Associate (JNCIA-Junos)",
        "decription": "Designed for networking professionals with beginner-intermediate knowledge of networking, this written exam verifies the candidate’s understanding of networking fundamentals, as well as core functionality of the Juniper Networks Junos OS.",
        "examDetails": [
            "Exam code: JN0-102",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Scoring and pass/fail status is available immediately",
            "Junos Software Release: 15.1"
        ],
        "prerequisites": [
            "None"
        ],
        "company": "Juniper",
        "acronym": "JNCIA-Junos"
    },
    {
        "title": "EXPERT Data Center, Expert (JNCIE-DC)",
        "decription": "At the pinnacle of the Data Center certification track is the 1-day JNCIE-DC practical exam. This exam is designed to validate the networking professionals’ ability to deploy, configure, manage, and troubleshoot Junos-based platforms. Throughout this 8-hour practical exam, candidates will build a data center network consisting of multiple MX Series and QFX Series devices. Successful candidates will perform system configuration on all devices including management capabilities, a Clos IP fabric, EVPN/VXVLAN, DCI, and CoS features.",
        "examDetails": [
            "Exam code: JPR-980",
            "Lab Exam (Hands-on Lab)",
            "Held at selected Juniper Networks testing centers",
            "Exam length: 8 hours",
            "Junos Software Release:"
        ],
        "prerequisites": [
            "JNCIP-DC"
        ],
        "company": "Juniper",
        "acronym": "JNCIE-DC"
    },
    {
        "title": "ASSOCIATE Cloud, Associate (JNCIA-Cloud)",
        "decription": "Designed for networking professionals with introductory-level knowledge of Juniper Networks cloud-based networking architectures, theory, and best practices, this written exam verifies the candidate’s understanding of cloud-based networking principles and technologies.",
        "examDetails": [
            "Exam code: JN0-210",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "None"
        ],
        "company": "Juniper",
        "acronym": "JNCIA-Cloud"
    },
    {
        "title": "SPECIALIST Service Provider Routing and Switching, Specialist (JNCIS-SP)",
        "decription": "Designed for experienced networking professionals with beginner to intermediate knowledge of routing and switching implementations in Junos, this written exam verifies the candidate’s basic understanding of routing and switching technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-361",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 17.3"
        ],
        "prerequisites": [
            "JNCIA-Junos"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-SP"
    }
]