import {
    GET_JOB_SKILLS_SCORE_REQUEST,
    GET_JOB_SKILLS_SCORE_SUCCESS,
    FETCH_JOB_DATA_BY_ID_REQUEST,
    FETCH_JOB_DATA_BY_ID_REQUEST_SUCCESS,
    FETCH_JOB_DATA_BY_ID_REQUEST_FAILURE
} from "./type"

const initialState = {
    skillsScoreRequesting: false,
    skillsScore: [],
    jobInformationById: {}
}


const jobDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_JOB_SKILLS_SCORE_REQUEST:
        return {
          ...state,
          skillsScoreRequesting: true
        }

      case GET_JOB_SKILLS_SCORE_SUCCESS:
        return {
          ...state,
          skillsScore: action.data,
          skillsScoreRequesting: false
        }

      case FETCH_JOB_DATA_BY_ID_REQUEST:
        return {
          ...state,
          requesting: true,
          jobInformation: null
        }
      case FETCH_JOB_DATA_BY_ID_REQUEST_SUCCESS:
        return {
          ...state,
          requesting: false,
          jobInformation: action.data?.[0]
        }
      case FETCH_JOB_DATA_BY_ID_REQUEST_FAILURE:
        return {
          ...state,
          requesting: false,
          error: action.data,
          jobInformation: null
        }

      default:
        return state
    }
}

export default jobDetailsReducer
