/*

From https://www.icagile.com/agile-certification using:
Object.values(document.getElementsByClassName('flex flex-col items-center text-center justify-self-center')).map((item) => {
    return {
        "title": item.getElementsByClassName('text-sm')[0].getElementsByTagName('a')[0].text.trim(),
        "id": item.getElementsByTagName('a')[0].getElementsByTagName('img')[0].src.replace('https://www.icagile.com/media/badges/', '').replace('.svg', '').toUpperCase()
    }
})

*/

export const ICAGILE_CERTIFICATIONS = [
    {
        "title": "Agile Fundamentals",
        "id": "ICP",
        "acronym": "ICP"
    },
    {
        "title": "Business Agility Foundations",
        "id": "ICP-BAF",
        "acronym": "ICP-BAF"
    },
    {
        "title": "Foundations of AI",
        "id": "ICP-FAI",
        "acronym": "ICP-FAI"
    },
    {
        "title": "Leading with Agility",
        "id": "ICP-LEA",
        "acronym": "ICP-LEA"
    },
    {
        "title": "People Development",
        "id": "ICP-PDV",
        "acronym": "ICP-PDV"
    },
    {
        "title": "Enterprise Agile Coaching",
        "id": "ICP-ENT",
        "acronym": "ICP-ENT"
    },
    {
        "title": "Coaching Agile Transformations",
        "id": "ICP-CAT",
        "acronym": "ICP-CAT"
    },
    {
        "title": "Expert in Enterprise Coaching",
        "id": "ICE-EC",
        "acronym": "ICE-EC"
    },
    {
        "title": "Agile Team Facilitation",
        "id": "ICP-ATF",
        "acronym": "ICP-ATF"
    },
    {
        "title": "Agile Coaching",
        "id": "ICP-ACC",
        "acronym": "ICP-ACC"
    },
    {
        "title": "Expert in Agile Coaching",
        "id": "ICE-AC",
        "acronym": "ICE-AC"
    },
    {
        "title": "Agile Product Ownership",
        "id": "ICP-APO",
        "acronym": "ICP-APO"
    },
    {
        "title": "Product Management",
        "id": "ICP-PDM",
        "acronym": "ICP-PDM"
    },
    {
        "title": "Agile Project and Delivery Management",
        "id": "ICP-APM",
        "acronym": "ICP-APM"
    },
    {
        "title": "Delivery at Scale",
        "id": "ICP-DAS",
        "acronym": "ICP-DAS"
    },
    {
        "title": "Agile Programming",
        "id": "ICP-PRG",
        "acronym": "ICP-PRG"
    },
    {
        "title": "Agile Software Design",
        "id": "ICP-ASD",
        "acronym": "ICP-ASD"
    },
    {
        "title": "Foundations of DevOps",
        "id": "ICP-FDO",
        "acronym": "ICP-FDO"
    },
    {
        "title": "Implementing DevOps",
        "id": "ICP-IDO",
        "acronym": "ICP-IDO"
    },
    {
        "title": "Agile Testing",
        "id": "ICP-TST",
        "acronym": "ICP-TST"
    },
    {
        "title": "Agile Test Automation",
        "id": "ICP-ATA",
        "acronym": "ICP-ATA"
    },
    {
        "title": "Agility in HR",
        "id": "ICP-AHR",
        "acronym": "ICP-AHR"
    },
    {
        "title": "Adaptive Org Design",
        "id": "ICP-ORG",
        "acronym": "ICP-ORG"
    },
    {
        "title": "Agility in Finance",
        "id": "ICP-FIN",
        "acronym": "ICP-FIN"
    },
    {
        "title": "Lean Portfolio Management",
        "id": "ICP-LPM",
        "acronym": "ICP-LPM"
    },
    {
        "title": "Agility in Marketing",
        "id": "ICP-MKG",
        "acronym": "ICP-MKG"
    },
    {
        "title": "Systems Coaching",
        "id": "ICP-SYS",
        "acronym": "ICP-SYS"
    }
]