import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { connect } from "react-redux"
import { getJobDataByIdRequest } from "../JobDetails/redux/action"
import CreateRole from '../CreateRole'

const EditRole = ({ onChange, ...props }) => {
  const {
    getJobData,
    jobDetails
  } = props

  const history = useHistory()
  const { location } = history
  const [selectedJobDetails, setSelectedJobDetails] = useState({})
  const { jobId } = useParams()
  useEffect(() => {
    const shouldResetJobInformation = (jobId !== selectedJobDetails?._id)
    if (shouldResetJobInformation) {
      setSelectedJobDetails({})
    }
    if (jobId) {
      getJobData({jobId})
      setSelectedJobDetails(jobDetails)
    }
  }, [jobId, location?.state])

  useEffect(() => {
    const shouldSetJobDetails = jobDetails?._id && jobDetails?._id === jobId
    if (shouldSetJobDetails) {
      setSelectedJobDetails(jobDetails)
    } else {
      setSelectedJobDetails({})
    }
  }, [jobDetails])

  return (
    <>
        {selectedJobDetails?.title && (
        <CreateRole
        {...props}
        jobDetails={selectedJobDetails}
        ></CreateRole>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  jobDetails: state.jobDetails.jobInformation
})

const mapDispatchToProps = dispatch => ({
  getJobData: data => dispatch(getJobDataByIdRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditRole)
