import { connect } from "react-redux"
import AddIcon from "@mui/icons-material/Add"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import NotInterestedIcon from "@mui/icons-material/NotInterested"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from "dayjs"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import {
  getAllJobsRequest,
  getMyJobsRequest,
  closeJobRequest,
  deleteJobRequest
} from "./redux/action"
import DataTable from "../../../components/DataTable"
import IconButton from "../../../components/IconButton"
import USearch from "../../../components/USearch"

import "./style.scss"
import DeleteConfirmModal from "../../../components/Modals/DeleteConfirmModal"
import { getFormattedLocation, isAdmin } from "../../../utils/helper"
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const RecruiterDashboard = props => {
  const {
    requesting,
    getMyJobs,
    getAllJobs,
    jobsData,
    closeJobDispatch,
    deleteJobDispatch,
    closeRequesting,
    deleteRequesting,
    userType
  } = props
  const { myJobs, allJobs } = jobsData || {}
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    action: ""
  })
  const history = useHistory()
  const { location } = history
  const pageInfo = useRef(window.history.state?.pageInfo?? {})

  const [jobToDelete, setJobToDelete] = useState({})
  const [searchRequesting, setSearchRequesting] = useState(false)
  const [searchParam, setSearchParam] = useState(pageInfo.current.searchTerm?? '')

  const jobTypes = [
    { label: "All Jobs", key: "all_jobs" },
    { label: "My Jobs", key: "my_jobs" }
  ]
  let { jobSectionType } = useParams()
  jobSectionType = jobSectionType.split('?')[0] // TODO maybe set this up in Router.js directly

  const getActiveSectionFromLocation = (location) => {
    if (location?.state?.activeSection) {
      return location?.state?.activeSection
    }
    const parsedQueryParams = new URLSearchParams(location?.search)
    const section = parsedQueryParams.get('section')?.replace(`${userType}-`, "")?? 'posted-jobs'

    const activeState = {
      activeElement: `admin/jobs/${jobSectionType}`,
      section: section ? `${userType}-posted-jobs` : ''
    }
    return activeState
  }

  const activeMenuItem = getActiveSectionFromLocation(location)

  const setPageInfoFromQueryParams = (pageInfo) => {
    const parsedQueryParams = new URLSearchParams(location?.search)
    pageInfo.current = {
      page: parseInt(parsedQueryParams.get('page')?? 1),
      limit: parseInt(parsedQueryParams.get('limit')?? 10),
      sortBy: parsedQueryParams.get('sortBy'),
      sortDir: parsedQueryParams.get('sortDir')?? 'asc',
      jobType: jobSectionType,
      section: activeMenuItem?.section?.replace(`${userType}-`, ""),
      searchTerm: parsedQueryParams.get('searchTerm')
    }
  }

  // TODO Fix loading my_jobs from url with location search info. We need to set the default section if not provided
  // TODO Cache calls to /jobs, /saved-jobs and /posted-jobs (also maybe merge endpoints for Memoization)

  setPageInfoFromQueryParams(pageInfo)

  useEffect(() => {
    !requesting && setSearchRequesting(false)
  }, [requesting])

  useEffect(() => {
    setPageInfoFromQueryParams(pageInfo)
  }, [location?.search])


  useEffect(() => {
    if (!userType) {
      return
    }
    if (jobSectionType === "my_jobs" && !activeMenuItem?.section) {
      const parsedQueryParams = new URLSearchParams(location?.search)
      const sectionFromSearchParams = parsedQueryParams.get('section')
      const sectionName = activeMenuItem?.section ? `${userType}-${activeMenuItem?.section}` : sectionFromSearchParams ? `${userType}-${sectionFromSearchParams}` : `${userType}-posted-jobs`

      history.replace(`/${userType}/jobs/my_jobs${location?.search?? ''}`, {
        activeSection: {
          section: sectionName,
          activeElement: `${userType}/jobs/my_jobs`
        }
      })
    } else if (jobSectionType === "all_jobs" && activeMenuItem?.section) {
      history.replace(`/${userType}/jobs/all_jobs${location?.search?? ''}`, {
        activeSection: {
          section: "",
          activeElement: `/${userType}/jobs/all_jobs`
        }
      })
    }
  }, [jobSectionType, activeMenuItem, history, userType])

  const updateQueryParamsInUrl = (pageInfo) => {
    const queryParams = new URLSearchParams(window.location.search)
    pageInfo.current.page ? queryParams.set('page', pageInfo.current.page) : queryParams.delete('page')
    pageInfo.current.limit ? queryParams.set('limit', pageInfo.current.limit) : queryParams.delete('limit')
    pageInfo.current.sortBy ? queryParams.set('sortBy', pageInfo.current.sortBy) : queryParams.delete('sortBy')
    pageInfo.current.sortDir ? queryParams.set('sortDir', pageInfo.current.sortDir) : queryParams.delete('sortDir')
    pageInfo.current.searchTerm ? queryParams.set('searchTerm', pageInfo.current.searchTerm) : queryParams.delete('searchTerm')
    pageInfo.current.section ? queryParams.set('section', pageInfo.current.section) : queryParams.delete('section')
    window.history.replaceState(null, null, "?"+queryParams.toString())
  }

  const getMyJobsData = useCallback(
    ({ page, limit, sortBy, sortDir, searchTerm }) => {
      pageInfo.current = {
        page,
        limit,
        sortBy,
        sortDir,
        jobType: "my_jobs",
        section: activeMenuItem?.section?.replace(`${userType}-`, ""),
        searchTerm
      }
      getMyJobs({
        page,
        limit,
        sortBy,
        sortDir,
        section: activeMenuItem?.section?.replace(`${userType}-`, ""),
        searchParam: searchTerm
      })
      updateQueryParamsInUrl(pageInfo)
    },
    [getMyJobs, activeMenuItem?.section]
  )

  const getAllJobsData = useCallback(
    ({ page, limit, sortBy, sortDir, searchTerm }) => {
      pageInfo.current = {
        page,
        limit,
        sortBy,
        sortDir,
        jobType: "all_jobs",
        searchTerm
      }
      updateQueryParamsInUrl(pageInfo)
      getAllJobs({
        page,
        limit,
        sortBy,
        sortDir,
        searchParam: searchTerm
      })
    },
    [getAllJobs, activeMenuItem?.section]
  )

  useEffect(() => {
    if (!closeRequesting && !deleteRequesting && jobToDelete?.id) {
      setDeleteModal({ show: false, action: "" })
      setJobToDelete({})
      let { page, limit, sortBy, sortDir, jobType, searchTerm, section } =
        pageInfo.current
      let reloadMethod
      let jobsData
      if (jobType === "my_jobs") {
        reloadMethod = getMyJobsData
        jobsData = myJobs
      } else if (jobType === "all_jobs") {
        jobsData = allJobs
        reloadMethod = getAllJobsData
      }

      if (jobsData?.data.length <= 1 && !jobsData?.page?.has_next) {
        page = page > 0 ? page - 1 : 1
      }

      reloadMethod({ page, limit, sortBy, sortDir, searchParam: searchTerm, section })
    }
  }, [
    closeRequesting,
    deleteRequesting,
    getMyJobsData,
    getAllJobsData,
    myJobs,
    allJobs
  ])

  const canViewCandidates = () => {
    return (isAdmin(userType) || activeMenuItem?.section === `${userType}-posted-jobs` ||
      (jobSectionType === "my_jobs" && !activeMenuItem?.section))
  }


  const goToCandidateView = jobData => () => {
    if (canViewCandidates(jobData)) {
      const path = jobData?.id ? `/${userType}/candidates/${jobData?.id}` : `/${userType}/all-applications`
      history.push(path, { jobData })
    }
  }

  const columns = [
    {
      id: "title",
      label: "Job Title",
      width: "25%",
      sortable: true,
      renderColumn: rowData => {
        return (
          <span
            onClick={goToCandidateView(rowData)}
            className={`${
              isAdmin(userType) || canViewCandidates(rowData)
                ? "job-title-label"
                : ""
            }`}
          >
            {rowData?.title}
          </span>
        )
      }
    },
    {
      id: "client_company",
      label: "Job Reference",
      width: "20%",
      sortable: true
    },
    {
      id: "start_date",
      label: "Open",
      width: "10%",
      sortable: true,
      renderColumn: rowData => {
        const startDate = rowData["start_date"] && dayjs(rowData["start_date"])
        const relativeTimePars =
          rowData["start_date"] && startDate.fromNow().split(" ")

        return (
          relativeTimePars?.length &&
          (isNaN(relativeTimePars[0])
            ? relativeTimePars.join(" ")
            : relativeTimePars.splice(0, 2).join(" "))
        )
      }
    },
    {
      id: "locations",
      label: "Location",
      width: "20%",
      sortable: true,
      renderColumn: rowData => {
        return rowData ? getFormattedLocation(rowData?.locations) : ''
      }
    },
    {
      id: "total_applicants",
      label: "Applicant Count",
      width: "15%",
      sortable: true,
      renderColumn: rowData => {
        return rowData["total_applicants"] || 0
      }
    }
  ]

  const myJobsColumns = [
    ...columns,
    {
      id: "action",
      label: "Action",
      width: "10%",
      renderColumn: rowData => {
        return (
          <JobTableRow
            userType={userType}
            jobData={rowData}
            activeMenuItem={activeMenuItem}
            history={history}
            setDeleteModal={setDeleteModal}
            setJobToDelete={setJobToDelete}
          />
        )
      }
    }
  ]

  const setJobSectionType = jobSection => () => {
    const path = `/${userType}/jobs/${jobSection}${location?.search?? ''}`
    const activeElement = `/${userType}/jobs/${jobSection}`
    history.push(path, {
      activeSection: { activeElement, section: activeMenuItem?.section }
    })
  }

  const goToCreateJob = () => {
    history.push(`/${userType}/jobs/create`)
  }

  const closeDeleteModal = () => {
    setDeleteModal({ show: false, action: "" })
    setJobToDelete({})
  }

  const closeJob = () => {
    deleteModal?.action === "delete"
      ? deleteJobDispatch(jobToDelete)
      : closeJobDispatch(jobToDelete)
  }

  const handleSearch = searchTerm => {
    setSearchParam(searchTerm)
    setSearchRequesting(searchTerm ? true : false)
  }

  return (
    <>
      <div className="RecruiterDashboard-section">
        <div className="header">
          <div className="heading-div">
            <h3>Jobs</h3>
            <div className="button-wrapper">
              <IconButton
                label="Add Job"
                icon={<AddIcon />}
                className="primary-button add-new-job"
                onClick={goToCreateJob}
              />
            </div>
          </div>
          <USearch
            placeholder="Search job title, keywords, or company name..."
            onClick={handleSearch}
            searchRequesting={searchRequesting}
            initialValue={searchParam}
          />
          <div className="jobs-tab-section">
            {jobTypes.map(item => (
              <div
                className="job-type-tab"
                onClick={setJobSectionType(item?.key)}
                key={item?.key}
              >
                <span
                  className={`text ${
                    jobSectionType === item?.key ? "active" : ""
                  }`}
                >
                  {item?.label}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="jobs-tab-content">
          <div className="jobs-card-section">
            {jobSectionType === "my_jobs" && (
              <div className="jobs-card">
                <DataTable
                  columns={myJobsColumns}
                  data={requesting ? [] : myJobs?.data}
                  totalPages={myJobs?.page?.total_page}
                  getTableData={getMyJobsData}
                  loading={requesting}
                  key={activeMenuItem?.section}
                  searchTerm={searchParam}
                  setSearchTerm={setSearchParam}
                  pageInfoInitialValue={pageInfo.current}
                />
              </div>
            )}
          </div>
          <div className="jobs-card-section">
            {jobSectionType === "all_jobs" && (
              <div className="jobs-card">
                <DataTable
                  columns={isAdmin(userType) ? myJobsColumns : columns}
                  data={requesting ? [] : allJobs?.data}
                  totalPages={allJobs?.page?.total_page}
                  getTableData={getAllJobsData}
                  loading={requesting}
                  key="all-jobs-table"
                  searchTerm={searchParam}
                  setSearchTerm={setSearchParam}
                  pageInfoInitialValue={pageInfo.current}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmModal
        showModal={deleteModal?.show}
        handleOnClose={closeDeleteModal}
        onCancel={closeDeleteModal}
        onConfirm={closeJob}
        confirmText={`Are you sure you want to ${deleteModal?.action} this job?`}
        itemName={`"${jobToDelete?.title}"`}
        loading={closeRequesting || deleteRequesting}
        confirmLabel={deleteModal?.action === "delete" ? "Delete" : "Close"}
      />
    </>
  )
}

const JobTableRow = props => {
  const { activeMenuItem, jobData, userType, history, setDeleteModal, setJobToDelete } = props

  const goToJobDetailsPage = () => {
    history.push(`/${userType}/job-details/${jobData?.id}`, { jobData })
  }

  const handleJobClose = () => {
    setDeleteModal({ show: true, action: "close" })
    setJobToDelete(jobData)
  }

  const handleJobDelete = () => {
    setDeleteModal({ show: true, action: "delete" })
    setJobToDelete(jobData)
  }

  const handleJobEdit = () => {
    history.push(`/${userType}/jobs/edit/${jobData._id}`, { selectedJobDetails: jobData })
  }

  return (
    <>
      <div className="job-actions">
        <VisibilityIcon
          onClick={goToJobDetailsPage}
          className="action-edit"
        />
        <EditOutlinedIcon
          onClick={handleJobEdit}
          className="action-edit"
        />
        {activeMenuItem?.section === `${userType}-posted-jobs` && (
          <NotInterestedIcon
            onClick={handleJobClose}
            className="action-delete"
          />
        )}
        {isAdmin(userType) && (
          <DeleteOutlinedIcon
            onClick={handleJobDelete}
            className="action-delete"
          />
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  jobsData: state.recruiterJobs.jobsData,
  requesting: state.recruiterJobs.requesting,
  closeRequesting: state.recruiterJobs.closeRequesting,
  deleteRequesting: state.recruiterJobs.deleteRequesting,
  userType: state.login.userType
})
const mapDispatchToProps = dispatch => ({
  getMyJobs: data => dispatch(getMyJobsRequest(data)),
  getAllJobs: data => dispatch(getAllJobsRequest(data)),
  closeJobDispatch: data => dispatch(closeJobRequest(data)),
  deleteJobDispatch: data => dispatch(deleteJobRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterDashboard)
